import { Checkbox, Grid, IconButton, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { SkeletonCom } from '../../Skeleton/Skeleton';
import styles from './CategoriesTebal.module.scss';
import { Button, Table } from 'react-bootstrap';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getUser } from '../../../../../_helpers';
interface HeadCell {
    disablePadding: boolean;
    id: any;
    label: string;
    numeric: boolean;
}
const headCells: readonly HeadCell[] = [
    {
        id: 'Name',
        numeric: false,
        disablePadding: true,
        label: 'Name',
    },
    {
        id: 'Createdat',
        numeric: true,
        disablePadding: false,
        label: 'Created at',
    },
    {
        id: 'Updatedat',
        numeric: true,
        disablePadding: false,
        label: 'Updated at',
    },
    {
        id: 'Createdby',
        numeric: true,
        disablePadding: false,
        label: 'Created by',
    },
    {
        id: 'info',
        numeric: true,
        disablePadding: false,
        label: '',
    }

];
const CategoriesTebal = ({ datatabal, loading, rejectSubmit, setPagelist, pageneshn, totalCount, handleClick_datat,setSelected,selected,setRenderData,remove,setRemovedata }: any) => {
    const [selectData, setSelecetData] = useState([])
    // const [remove, setRemovedata] = React.useState(10);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [page, setPage] = React.useState(0);
    console.log(totalCount,remove, "totalCount");

    const navigate = useNavigate();
    const user = getUser()?.data ?? null;

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.checked, "eventevent123");

        if (event.target.checked) {
            const newSelected: any = []
            for (let index = 0; index < datatabal.length; index++) {
                const element = datatabal[index];
              
                    newSelected.push(element.monite_tag_id)
                
            }
            setSelected(newSelected);
            return;
        } else {
            setSelected([]);
        }

    };
    const handleClick = (event: React.MouseEvent<unknown>, name: any) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };
    const isSelected = (name: string) => selected.indexOf(name) !== -1;
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - datatabal.length) : 0;
    return (
        <>

            <Grid item md={12} xs={12}>
                <SkeletonCom loader={loading}
                    element={
                        <>
                            <Paper sx={{ width: '100%', mb: 2, marginTop: "20px", boxShadow: "0px 2px 14px 2px rgba(174, 174, 174, 0.25)" }}>
                                <TableContainer>
                                    <Table
                                        className='contenar_tebal_liner'
                                        // sx={{ minWidth: 750 }}
                                        aria-labelledby="tableTitle"
                                    // size={dense ? 'small' : 'medium'}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        icon={<img src="/Checkbox.svg" />}
                                                        checkedIcon={<img src="/Checkbox_two.svg" />}
                                                        indeterminate={selected.length > 0 && selected.length < datatabal.length}
                                                        checked={datatabal.length > 0 && selected.length === datatabal.length}
                                                        onChange={handleSelectAllClick}
                                                        inputProps={{
                                                            'aria-label': 'select all desserts',
                                                        }}
                                                    />
                                                </TableCell>
                                                {headCells.map((headCell) => (
                                                    <TableCell style={headCell.label == "Description" ? { textAlign: "center" } : {}} >
                                                        {headCell.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {datatabal && datatabal.length > 0 ?
                                                datatabal.map((data: any, index: any) => {
                                                    const isItemSelected = isSelected(data.monite_tag_id);
                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                    console.log(data, 'datadata', index);

                                                    return (
                                                        <TableRow

                                                            // hover
                                                            // onClick={(event) => handleClick(event, row.id)}
                                                            role="checkbox"
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            key={index}
                                                            selected={isItemSelected}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    icon={<img src="/Checkbox.svg" />}
                                                                    disabled={data.status == "paid" ? true : false}
                                                                    checkedIcon={<img src="/Checkbox_two.svg" />}
                                                                    onClick={(event) => handleClick(event, data.monite_tag_id)}
                                                                    color="primary"
                                                                    checked={isItemSelected}
                                                                    inputProps={{
                                                                        'aria-labelledby': labelId,
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell style={{ cursor: "pointer" }} >
                                                                {data.name}
                                                            </TableCell>
                                                            <TableCell>
                                                                {moment(data.created_at).format(
                                                                    'DD-MMM-YYYY'
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {moment(data.updated_at).format(
                                                                    'DD-MMM-YYYY'
                                                                )}
                                                            </TableCell>
                                                            <TableCell >
                                                                {user?.name}
                                                            </TableCell>

                                                            <TableCell align="right">
                                                                <IconButton onClick={(e: any) => {
                                                                    // if (data.status != "paid") {
                                                                    //     setIdeditdata(data.id)
                                                                    // }

                                                                    // if (data.status == "new") {
                                                                    //     setDelete_data(data.payable_id)
                                                                    // }
                                                                    handleClick_datat(e,data.name,data.monite_tag_id)
                                                                }}>
                                                                    <MoreVertIcon />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                }) : null}
                                            {emptyRows > 0 && (
                                                <TableRow
                                                    style={{
                                                        height: (dense ? 33 : 53) * emptyRows,
                                                    }}
                                                >
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                            <div style={{ paddingTop: '10px', justifyContent: "space-between" }} className="addnewbtnclass">
                                <Button
                                    disabled={selected.length > 0 ? false : true}
                                    onClick={() => rejectSubmit()}
                                    color="#F87168"
                                    style={{ padding: "10px 15px" }}
                                    className='white_btn_two'
                                >
                                    Delete
                                </Button>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div className="inpagenum">
                                        <p>
                                            {remove - 9}-{remove}
                                        </p>
                                    </div>
                                    <div className="btnmaindivin">
                                        {pageneshn == 1 ? (
                                            <button disabled className="nextntmistish">
                                                <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                                            </button>
                                        ) : (
                                            <button
                                                className="nextntmistish"
                                                onClick={() => {
                                                    setRenderData(true)
                                                    setPagelist(pageneshn - 1);
                                                    setRemovedata(remove - 10);
                                                    //   getBalanceList(pageneshn - 1);
                                                }}
                                            >
                                                <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                                            </button>
                                        )}
                                        {remove >= totalCount ? (
                                            <button disabled className="nextntmistish">
                                                <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                                            </button>
                                        ) : (
                                            <button
                                                className="nextntmistish"
                                                onClick={() => {
                                                    setRenderData(true)
                                                    setPagelist(pageneshn + 1);
                                                    setRemovedata(remove + 10);

                                                    //   getBalanceList(pageneshn + 1);
                                                }}
                                            >
                                                <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    }></SkeletonCom>

            </Grid>
        </>
    )
}
export default CategoriesTebal;