import React, { useEffect } from 'react';

const FeedbackModal = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if ((window as any).hbspt) {
        (window as any).hbspt.forms.create({
          region: 'eu1',
          portalId: '26625235',
          formId: '246c44f1-17b6-407e-9f89-ce725ecac110',
          target: '#hubspotForm',
        });
      }
    });

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div id="hubspotForm" />;
};

export default FeedbackModal;
