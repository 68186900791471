import { alertActions } from '.'
import { dashboardService } from "../_services/dashboard.service";
import { dashboardConstants } from "../_constants/dashboard.constants";
// import toast from 'react-hot-toast';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const dashboardActions = {
    getBalance,
    getBills,
    getChartData,
    documentverification,
    getBalance_currency_rate,
    getRecent,
    getBudgetdetails,
    getUpdatedetails,
    getCategoryExpenses,
    getPayeeExpenses,
    getPaymentExpenses,
    getBilldetels,
    getPayableToApprove,
    getPayableToReject,
    getPayableToPending,
    getPayableToUpdate,
    getPayableToCancel,
    getPayableToPay,
    getPayins,
    getPaymentMethodPayins,
    getInvoicedetels,
    updateUserTourCompleted,
    getDashboardCurrencyData,
    // getDashboardSelectedCurrencyData,
    getBankDetailData
}

function getBalance(Link: any, setBalanceRender: any, setBalanceloader: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        setBalanceloader(true)
        dashboardService.getBalance(Link)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                    setBalanceRender(true)
                    setTimeout(() => {
                        setBalanceloader(false)
                    }, 2000)

                } else {
                    setBalanceRender(false)
                    dispatch(failure(response.message));
                    setTimeout(() => {
                        setBalanceloader(false)
                    }, 2000)
                }

            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: dashboardConstants.GETDASHBOARD_REQUEST } }
    function success(data: any) { return { type: dashboardConstants.GET_DASHBOARD_BALANCE_SUCCESS, data } }
    function failure(s: string) { return { type: dashboardConstants.GETDASHBOARD_FAILURE } }
}
function getBalance_currency_rate(data: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dashboardService.getBalance_currency_rate(data)
            .then(data => {
                return data.json()
            })
            .then(response => {
                dispatch(success(response.data))
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: dashboardConstants.GET_CURRENCY_RATE_REQUEST } }
    function success(data: any) { return { type: dashboardConstants.GET_CURRENCY_RATE_SUCCESS, data } }
    function failure(s: string) { return { type: dashboardConstants.GET_CURRENCY_RATE_FAILURE } }
}
function getBills(data: any, currency: any, setBillRender: any, setBillsloader: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        setBillsloader(true)
        dashboardService.getBills(data, currency)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                    setBillRender(true)
                    setTimeout(() => {
                        setBillsloader(false)
                    }, 2000)

                } else {
                    dispatch(failure(response.message));
                    setTimeout(() => {
                        setBillsloader(false)
                    }, 2000)
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: dashboardConstants.GETDASHBOARD_REQUEST } }
    function success(data: any) { return { type: dashboardConstants.GET_DASHBOARD_BILL_SUCCESS, data } }
    function failure(error: string) { return { type: dashboardConstants.GETDASHBOARD_FAILURE, error } }
}
function getRecent(selectCurrencyPopup: any, setRecentRender: any, setRecentloader: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        setRecentloader(true)
        dashboardService.getRecent(selectCurrencyPopup)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                    setRecentRender(true)
                    setTimeout(() => {
                        setRecentloader(false)
                    }, 2000)

                } else {
                    dispatch(failure(response.message));
                    setTimeout(() => {
                        setRecentloader(false)
                    }, 2000)
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: dashboardConstants.GET_DASHBOARD_RECENT_REQUEST } }
    function success(data: any) { return { type: dashboardConstants.GET_DASHBOARD_RECENT_SUCCESS, data } }
    function failure(error: string) { return { type: dashboardConstants.GET_DASHBOARD_RECENT_FAILURE, error } }
}
function getBudgetdetails() {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dashboardService.getBudgetdetails()
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                } else {
                    dispatch(failure(response.message));
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: dashboardConstants.GET_DASHBOARD_BUDGETDETAILS_REQUEST } }
    function success(data: any) { return { type: dashboardConstants.GET_DASHBOARD_BUDGETDETAILS_SUCCESS, data } }
    function failure(error: string) { return { type: dashboardConstants.GET_DASHBOARD_BUDGETDETAILS_FAILURE, error } }
}
function getUpdatedetails(setPaymentMethodPayinsRedusar: any, setUpdatedetailsloader: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        setUpdatedetailsloader(true)
        dashboardService.getUpdatedetails()
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                    setPaymentMethodPayinsRedusar(true)
                    setTimeout(() => {
                        setUpdatedetailsloader(false)
                    }, 2000)

                } else {
                    dispatch(failure(response.message));
                    setTimeout(() => {
                        setUpdatedetailsloader(false)
                    }, 2000)
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: dashboardConstants.GET_DASHBOARD_UPDATEDETAILS_REQUEST } }
    function success(data: any) { return { type: dashboardConstants.GET_DASHBOARD_UPDATEDETAILS_SUCCESS, data } }
    function failure(error: string) { return { type: dashboardConstants.GET_DASHBOARD_UPDATEDETAILS_FAILURE, error } }
}
function getBilldetels(data: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dashboardService.getBilldetels(data)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response))
                } else {
                    toast.error(response.message);
                    dispatch(failure(response.message));
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: dashboardConstants.GET_PAYABLE_BY_ID_REQUEST } }
    function success(data: any) { return { type: dashboardConstants.GET_PAYABLE_BY_ID_SUCCESS, data } }
    function failure(error: string) { return { type: dashboardConstants.GET_PAYABLE_BY_ID_FAILURE, error } }
}
function getInvoicedetels(data: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dashboardService.getInvoicedetels(data)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response))
                } else {
                    toast.error(response.message);
                    dispatch(failure(response.message));
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: dashboardConstants.GET_INVOICE_BY_ID_REQUEST } }
    function success(data: any) { return { type: dashboardConstants.GET_INVOICE_BY_ID_SUCCESS, data } }
    function failure(error: string) { return { type: dashboardConstants.GET_INVOICE_BY_ID_FAILURE, error } }
}
function getPayableToApprove(data: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dashboardService.getPayableToApprove(data)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response))
                    toast.success(response.message);
                    window.location.reload()

                } else {
                    dispatch(failure(response.message));
                    toast.error(response.message);
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: dashboardConstants.GET_PAYABLE_TO_APPROVE_REQUEST } }
    function success(data: any) { return { type: dashboardConstants.GET_PAYABLE_TO_APPROVE_SUCCESS, data } }
    function failure(error: string) { return { type: dashboardConstants.GET_PAYABLE_TO_APPROVE_FAILURE, error } }
}
function getPayableToReject(data: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dashboardService.getPayableToReject(data)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response))
                    toast.success(response.message);
                    window.location.reload()
                } else {
                    dispatch(failure(response.message));
                    toast.error(response.message);
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: dashboardConstants.GET_PAYABLE_TO_REJECT_REQUEST } }
    function success(data: any) { return { type: dashboardConstants.GET_PAYABLE_TO_REJECT_SUCCESS, data } }
    function failure(error: string) { return { type: dashboardConstants.GET_PAYABLE_TO_REJECT_FAILURE, error } }
}
function getPayableToPending(data: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dashboardService.getPayableToPending(data)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response))
                    toast.success(response.message);
                    window.location.reload()

                } else {
                    dispatch(failure(response.message));
                    toast.error(response.message);
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: dashboardConstants.GET_PAYABLE_TO_PENDING_REQUEST } }
    function success(data: any) { return { type: dashboardConstants.GET_PAYABLE_TO_PENDING_SUCCESS, data } }
    function failure(error: string) { return { type: dashboardConstants.GET_PAYABLE_TO_PENDING_FAILURE, error } }
}
function getPayableToPay(data: any, Redayrecat: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dashboardService.getPayableToPay(data)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response))
                    toast.success(response.message);
                    Redayrecat()
                } else {
                    dispatch(failure(response.message));
                    toast.error(response.message);
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: dashboardConstants.GET_PAYABLE_TO_PAY_REQUEST } }
    function success(data: any) { return { type: dashboardConstants.GET_PAYABLE_TO_PAY_SUCCESS, data } }
    function failure(error: string) { return { type: dashboardConstants.GET_PAYABLE_TO_PAY_FAILURE, error } }
}
function getPayableToCancel(data: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dashboardService.getPayableToCancel(data)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response))
                    toast.success(response.message);
                    window.location.reload()

                } else {
                    dispatch(failure(response.message));
                    toast.error(response.message);
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: dashboardConstants.GET_PAYABLE_TO_CANCEL_REQUEST } }
    function success(data: any) { return { type: dashboardConstants.GET_PAYABLE_TO_CANCEL_SUCCESS, data } }
    function failure(error: string) { return { type: dashboardConstants.GET_PAYABLE_TO_CANCEL_FAILURE, error } }
}
function getPayableToUpdate(id: any, data: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dashboardService.getPayableToUpdate(id, data)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response))
                    toast.success(response.message);
                    window.location.reload()
                } else {
                    dispatch(failure(response.message));
                    toast.error(response.message);
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: dashboardConstants.GET_PAYABLE_TO_UPDATE_REQUEST } }
    function success(data: any) { return { type: dashboardConstants.GET_PAYABLE_TO_UPDATE_SUCCESS, data } }
    function failure(error: string) { return { type: dashboardConstants.GET_PAYABLE_TO_UPDATE_FAILURE, error } }
}
function updateUserTourCompleted() {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dashboardService.updateUserTourCompleted()
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response))
                    // toast.success(response.message);
                    // window.location.reload()
                } else {
                    dispatch(failure(response.message));
                    // toast.error(response.message);
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: dashboardConstants.UPDATE_USER_TOUR_COMPLETED_REQUEST } }
    function success(data: any) { return { type: dashboardConstants.UPDATE_USER_TOUR_COMPLETED_SUCCESS, data } }
    function failure(error: string) { return { type: dashboardConstants.UPDATE_USER_TOUR_COMPLETED_FAILURE, error } }
}
function getCategoryExpenses(data: any, currency: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dashboardService.getCategoryExpenses(data, currency)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                } else {
                    dispatch(failure(response.message));
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: dashboardConstants.GET_DASHBOARD_CATEGRORY_EXPENSES_REQUEST } }
    function success(data: any) { return { type: dashboardConstants.GET_DASHBOARD_CATEGRORY_EXPENSES_SUCCESS, data } }
    function failure(error: string) { return { type: dashboardConstants.GET_DASHBOARD_CATEGRORY_EXPENSES_FAILURE, error } }
}
function getPayins(data: any, currency: any, setPayinsRedusar: any, setPayinsloader: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        setPayinsloader(true)
        dashboardService.getPayins(data, currency)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                    setPayinsRedusar(true)
                    setTimeout(() => {
                        setPayinsloader(false)
                    }, 2000)

                } else {
                    dispatch(failure(response.message));
                    setTimeout(() => {
                        setPayinsloader(false)
                    }, 2000)
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: dashboardConstants.GET_PAYINS_REQUEST } }
    function success(data: any) { return { type: dashboardConstants.GET_PAYINS_SUCCESS, data } }
    function failure(error: string) { return { type: dashboardConstants.GET_PAYINS_FAILURE, error } }
}
function getPaymentMethodPayins(data: any, currency: any, setPaymentMethodPayinsRedusar: any, setPaymentMethodPayinsloader: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        setPaymentMethodPayinsloader(true)
        dashboardService.getPaymentMethodPayins(data, currency)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                    setPaymentMethodPayinsRedusar(true)
                    setTimeout(() => {
                        setPaymentMethodPayinsloader(false)
                    }, 2000)

                } else {
                    dispatch(failure(response.message));
                    setTimeout(() => {
                        setPaymentMethodPayinsloader(false)
                    }, 2000)
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: dashboardConstants.GET_PAYMENT_METHOD_PAYINS_REQUEST } }
    function success(data: any) { return { type: dashboardConstants.GET_PAYMENT_METHOD_PAYINS_SUCCESS, data } }
    function failure(error: string) { return { type: dashboardConstants.GET_PAYMENT_METHOD_PAYINS_FAILURE, error } }
}
function getPayeeExpenses(data: any, currency: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dashboardService.getPayeeExpenses(data, currency)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                } else {
                    dispatch(failure(response.message));
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: dashboardConstants.GET_DASHBOARD_PAYEE_EXPENSES_REQUEST } }
    function success(data: any) { return { type: dashboardConstants.GET_DASHBOARD_PAYEE_EXPENSES_SUCCESS, data } }
    function failure(error: string) { return { type: dashboardConstants.GET_DASHBOARD_PAYEE_EXPENSES_FAILURE, error } }
}
function getPaymentExpenses(data: any, currency: any, setPaymentRender: any, setPaymentExpenses: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        setPaymentExpenses(true)
        dashboardService.getPaymentExpenses(data, currency)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                    setPaymentRender(true)
                    setTimeout(() => {
                        setPaymentExpenses(false)
                    }, 2000)

                } else {
                    dispatch(failure(response.message));
                    setTimeout(() => {
                        setPaymentExpenses(false)
                    }, 2000)
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: dashboardConstants.GET_DASHBOARD_PAYMENT_EXPENSES_REQUEST } }
    function success(data: any) { return { type: dashboardConstants.GET_DASHBOARD_PAYMENT_EXPENSES_SUCCESS, data } }
    function failure(error: string) { return { type: dashboardConstants.GET_DASHBOARD_PAYMENT_EXPENSES_FAILURE, error } }
}
function getChartData(startdate: any, enddate: any, currency: any, setChartrender: any, setChartDataloader: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        setChartDataloader(true)
        dashboardService.getChartData(startdate, enddate, currency)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                    setChartrender(true)
                    setTimeout(() => {
                        setChartDataloader(false)
                    }, 2000)

                } else {
                    dispatch(failure(response.message));
                    setTimeout(() => {
                        setChartDataloader(false)
                    }, 2000)
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: dashboardConstants.GET_DASHBOARD_CHART_REQUEST } }
    function success(data: any) { return { type: dashboardConstants.GET_DASHBOARD_CHART_SUCCESS, data } }
    function failure(error: string) { return { type: dashboardConstants.GET_DASHBOARD_CHART_FAILURE, error } }
}
function documentverification() {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dashboardService.documentverification()
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response))
                } else {
                    dispatch(failure(response.message));
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: dashboardConstants.DOCUMENT_VERIFICATION_REQUEST } }
    function success(data: any) { return { type: dashboardConstants.DOCUMENT_VERIFICATION_SUCCESS, data } }
    function failure(error: string) { return { type: dashboardConstants.DOCUMENT_VERIFICATION_FAILURE, error } }
}

// get dashboard-currency function
function getDashboardCurrencyData() {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dashboardService.getDashboardCurrencyData()
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                } else {
                    dispatch(failure(response.message));
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: dashboardConstants.GET_DASHBOARD_CURRENCY_DATA_REQUEST } }
    function success(data: any) { return { type: dashboardConstants.GET_DASHBOARD_CURRENCY_DATA_SUCCESS, data } }
    function failure(error: string) { return { type: dashboardConstants.GET_DASHBOARD_CURRENCY_DATA_FAILURE, error } }
}

// get dashboard selected-currency function
// function getDashboardSelectedCurrencyData() {
//     return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
//         dashboardService.getDashboardSelectedCurrencyData()
//             .then(data => {                
//                 return data.json()
//             })
//             .then(response => {
//                 if (response.success) {
//                     dispatch(success(response.data))                    
//                 } else {
//                     dispatch(failure(response.message));
//                 }
//             },
//                 error => {
//                     dispatch(failure(error.toString()))
//                     dispatch(alertActions.error(error.message))
//                 }
//             )
//     }

//     function request() { return { type: dashboardConstants.GET_DASHBOARD_SELECTED_CURRENCY_DATA_REQUEST } }
//     function success(data: any) { return { type: dashboardConstants.GET_DASHBOARD_SELECTED_CURRENCY_DATA_SUCCESS, data } }
//     function failure(error: string) { return { type: dashboardConstants.GET_DASHBOARD_SELECTED_CURRENCY_DATA_FAILURE, error } }
// }

// get bank-details function
function getBankDetailData(currency: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dashboardService.getBankDetailData(currency)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                } else {
                    dispatch(failure(response.message));
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: dashboardConstants.GET_BANK_DETAIL_DATA_REQUEST } }
    function success(data: any) { return { type: dashboardConstants.GET_BANK_DETAIL_DATA_SUCCESS, data } }
    function failure(error: string) { return { type: dashboardConstants.GET_BANK_DETAIL_DATA_FAILURE, error } }
}