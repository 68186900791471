import { Box, createTheme, MenuItem, Select, ThemeProvider } from "@mui/material";
import { orange } from "@mui/material/colors";

const SelectBox = ({ value, onChange, countryList }: any) => {
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        className="selectBox"
        style={{ marginRight: "12px", marginTop: "10px" }}
        value={value}
        onChange={onChange}

      >
        {Array.isArray(countryList) && countryList.map((item: any, index) => {
          return (
            <MenuItem
              disabled={item.total_balance == 0 ? true : false}
              value={item.currency}
              key={index}>
              <Box className="MenuItemBox" >
                <img
                  loading="lazy"
                  width="25"
                  src={item.flag}
                  alt=""
                />
                <p>
                  {item.currency}
                </p>
              </Box>
            </MenuItem>

          )
        })}
      </Select>
    </ThemeProvider>
  )
}
export default SelectBox;