import CreateProductsForm from './components/createProducts/createProducts';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { authHeader } from "../../../../_helpers";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';


const CreateProducts = () => {
    const location = useLocation();
    const [utilsData, setUtilsData] = useState();
    const [singleProductData, setSingleProductData] = useState();
    const [productID, setProductID] = useState<string | null>(null);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const productId = params.get('id');
        setProductID(productId); 
    }, [location]);

    const theme = createTheme({
        status: {
            danger: "#025041",
        },
    });

    useEffect(() => {
        const fetchData = async () => {
            await getProductUtils();
            if (productID) {
                await getSingleProduct();
            }
        };
        fetchData();
    }, [productID]);

    const getProductUtils = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-measure-units`, {
                headers: { 'Content-Type': 'application/json', ...authHeader() },
            });
            
            if (response.data.success) {
                setUtilsData(response.data.data);
            } else {
                console.error('Error fetching measure units');
            }
        } catch (error) {
            console.error('Error fetching measure units:', error);
        }
    };

    const getSingleProduct = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/get-product/${productID}`, {
                headers: { 'Content-Type': 'application/json', ...authHeader() },
            });
            
            if (response.data.success) {
                setSingleProductData(response.data.data);
            } else {
                console.error('Error fetching product data');
            }
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    };

    return (
        <>     
            <div className="balance">
                <div className="balance__header">
                    <div className="balance__header__name">Products</div>
                </div>
                <ThemeProvider theme={theme}>
                    <CreateProductsForm utilsData={utilsData} singleProductData={singleProductData} />
                </ThemeProvider>
            </div>
        </>
    );
};

export default CreateProducts;
