import styles from './CreateCustomer.module.scss';
import { Dropdown, DropdownMenuItem } from '@team-monite/ui-kit-react';
import { Col, Form, Button, InputGroup } from 'react-bootstrap';
import { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from 'react-country-region-selector';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PhoneInput from 'react-phone-input-2';
import { Formik, Field, ErrorMessage } from 'formik';
import { paymentJourneyActions } from '../../../../../../_actions/paymentJourney.actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProperties,
  getUser,
  isUserAuthenticated,
} from '../../../../../../_helpers';
import React from 'react';
import { selectPaymentJourneyData } from '../../../../../../_reducers/paymentJourney.reducer';
import { counterpartsActions } from '../../../../../../_actions/counterparts.actions';
import { useSearchParams } from 'react-router-dom';
import { selectAllCounterpartsListData } from '../../../../../../_reducers/counterparts.reducer';
import Checkbox from '@mui/material/Checkbox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import * as Yup from 'yup';
import posthog from 'posthog-js';
import amplitude from 'amplitude-js';
const CreateCustomer_sub = () => {
    const [fild_data, setFild_data] = useState({
        First_name: '',
        Last_name: '',
        Type: 'individual',
        Select_fild: true,
        Phone_number: '',
        Email: '',
        Street_address: '',
        Country: 'KE',
        typePayment: 'M-Pesa',
        mpesa_type: '',
        Send_Money: '',
        paybill_number: '',
        account_number: '',
        till_number: '',
      });
      const [bank_data, setBank_data] = useState({
        bank_name: '',
        branch_name: '',
        account_name: '',
        account_number: '',
      });
      const properties = getProperties();
      const [dataaccodiyan, setDataccodiyan] = useState<boolean>(false);
      const [paymentacodiyan, setPaymentacodiyan] = useState<boolean>(false);
      const [submitData, setSubmitdata] = useState(false);
      console.log(fild_data, 'fild_datafild_data');
      const [render, setRender] = useState(true);
      const [render_two, setRender_two] = useState(true);
    
      const [bankListRender, setBankListRender] = useState<any>(true);
      const [bankListRender_two, setBankListRender_two] = useState<any>(true);
      const [data_submit, setDataSubmit] = useState(false);
      const [bankList, setBankList] = useState<any>([]);
      const dispatch = useDispatch();
      const dataList = useSelector((state) => selectPaymentJourneyData(state));
      const counterpartData = useSelector((state) =>
        selectAllCounterpartsListData(state)
      );
      const [searchParams, setSearchParams] = useSearchParams();
      const [datadisplay, setDatadisplay] = useState('');
    
      React.useEffect(() => {
        const popup = searchParams.get('id');
        if (!!popup) {
          setDatadisplay(popup);
          // setPaymentGrid(true);
          // setShowBalanceGrid(false);
          // setTopup(false);
        } else {
          setDatadisplay('');
        }
      }, [searchParams]);
      React.useEffect(() => {
        if (isUserAuthenticated() && render_two && !!datadisplay) {
          dispatch(counterpartsActions.getCustomerById(datadisplay) as any);
          setRender_two(false);
        }
      }, [render_two, isUserAuthenticated, datadisplay]);
      React.useEffect(() => {
        if (
          bankListRender_two &&
          counterpartData?.GET_CUSTOMER_BY_ID_SUCCESS !== undefined &&
          !!datadisplay
        ) {
          setBankListRender_two(false);
          console.log(
            counterpartData?.GET_CUSTOMER_BY_ID_SUCCESS,
            'counterpartData?.GETUSERCOUNTERPART_EDIT_SUCCESS'
          );
          const datalist = counterpartData?.GET_CUSTOMER_BY_ID_SUCCESS;
          setFild_data({
            First_name:
              datalist.customer_type == 'individual'
                ? datalist.contact_first_name
                : datalist.name,
            Last_name: datalist.contact_last_name,
            Type: datalist.customer_type,
            Select_fild: datalist.is_customer,
            Phone_number: datalist.contact_phone,
            Email: datalist.contact_email,
            Street_address: datalist.contact_line1,
            Country: datalist.contact_country,
            typePayment: 'M-Pesa',
            mpesa_type: '',
            Send_Money: !!datalist.account ? datalist.account.phone_number : '',
            paybill_number: !!datalist.account
              ? datalist.account.paybill_number
              : '',
            account_number: !!datalist.account
              ? datalist.account.account_number
              : '',
            till_number: !!datalist.account ? datalist.account.till_number : '',
          });
          setBank_data({
            bank_name: !!datalist.account ? datalist.account.bank_name : '',
            branch_name: !!datalist.account ? datalist.account.bank_branch : '',
            account_name: !!datalist.account ? datalist.account.account_name : '',
            account_number: !!datalist.account
              ? datalist.account.bank_account_number
              : '',
          });
          // setBankList(dataList.GETPESABANKLIST_SUCCESS);
        }
      }, [counterpartData, bankListRender]);
      const theme = createTheme({
        status: {
          danger: orange[500],
        },
      });
      const haredData = () => {
        setSubmitdata(true);
    
        if (
          !!fild_data.First_name &&
          !!fild_data.Last_name &&
          fild_data.Type == 'individual'
        ) {
          var body = {
            type: fild_data.Type,
            first_name: fild_data.First_name,
            last_name: fild_data.Last_name,
    
            phone: !!fild_data.Phone_number
              ? fild_data.Phone_number
              : '254123456789',
            email: !!fild_data.Email ? fild_data.Email : 'hello@niobi.co',
            country: !!fild_data.Country ? fild_data.Country : 'KE',
            city: 'city',
            postal_code: 'postal_code',
            line1: !!fild_data.Street_address
              ? fild_data.Street_address
              : '123 Main Street',
            // is_customer: fild_data.Select_fild == true ? '1' : '0',
          };
          console.log(body, 'bodybody');
          setDataSubmit(true);
          if (!!datadisplay) {
            dispatch(
              counterpartsActions.EditCustomerById(body,datadisplay) as any
            );
    
            // add update payee
          } else {
            dispatch(counterpartsActions.CustomersCreate(body) as any);
    
            // add create payee event
            const event = 'Payee Created';
            const eventProperties = {
              userId:
                !!properties && !!properties.user_id ? properties.user_id : null,
              userName: !!properties && !!properties.name ? properties.name : null,
              email: !!properties && !!properties.email ? properties.email : null,
              pageUrl: window.location.href,
            };
            amplitude.getInstance().logEvent(event, eventProperties);
            posthog.capture(event, eventProperties);
          }
        } else if (!!fild_data.First_name && fild_data.Type == 'organization') {
          var findresults: any = '';
          if (!!bank_data.bank_name) {
            findresults = bankList.find(
              (entry: any) => entry.bank_name === bank_data.bank_name
            );
          }
          var body_two: any = {
            type: fild_data.Type,
            legal_name: fild_data.First_name,
            // "last_name": fild_data.Last_name,
    
            "phone": !!fild_data.Phone_number ? fild_data.Phone_number : "254123456789",
            "email": !!fild_data.Email ? fild_data.Email : "hello@niobi.co",
            "country": !!fild_data.Country ? fild_data.Country : "KE",
            "city": 'city',
            "postal_code": 'postal_code',
            "line1": !!fild_data.Street_address ? fild_data.Street_address : "123 Main Street",
            'is_customer': fild_data.Select_fild == true ? '1' : '0',
          }
          console.log(body_two, 'bodybody');
          setDataSubmit(true)
          if (!!datadisplay) {
            dispatch(counterpartsActions.EditCustomerById(body_two,datadisplay) as any)
          } else {
            dispatch(counterpartsActions.CustomersCreate(body_two) as any)
          }
        }
    
      }
    return (
        <>
    <div style={{ paddingTop: "30px" }} className='main_contenar_listts'>
        <Button id="button_back" style={{ color: '#025141' }}
          onClick={() => {
            window.location.href = '/customers'

          }} className="payment-journey-container__detailbox__white-btn">
          <ThemeProvider theme={theme}>
            <ArrowBackIcon style={{ fontSize: "19px", marginRight: "10px" }} />
          </ThemeProvider>
          Go Back
        </Button>  <div className='box_contean_data_contenar'>
          <p className="p_tage_cerete_data">
            {!!datadisplay ? "Update" : "Create"}
          </p>
          <div className='main_fast_and_last'>
            <div className='fast_name_inpurt_div'>
              <div className="input-form-wrapper dot">
                <label>{fild_data.Type == "individual" ? "First name" : "Organization name"}</label>
                <InputGroup style={{ fontSize: '12px' }} className="doc-input-wrapper">
                  <Form.Control
                    className={
                      'doc-form-input test valid'}
                    placeholder={fild_data.Type == "individual" ? "First name" : "Organization name"}
                    value={fild_data.First_name}
                    name='name'
                    type="text"
                    onChange={(e: any) => {
                      setFild_data({
                        First_name: e.target.value,
                        Last_name: fild_data.Last_name,
                        Type: fild_data.Type,
                        Select_fild: fild_data.Select_fild,
                        Street_address: fild_data.Street_address,
                        Email: fild_data.Email,
                        Phone_number: fild_data.Phone_number,
                        Country: fild_data.Country,

                        typePayment: fild_data.typePayment,
                        mpesa_type: fild_data.mpesa_type,
                        Send_Money: fild_data.Send_Money,
                        paybill_number: fild_data.paybill_number,
                        account_number: fild_data.account_number,
                        till_number: fild_data.till_number,
                      });
                    }}
                  />
                </InputGroup>
                {!fild_data.First_name && submitData == true ? (
                  <p className="error-message">
                    Please enter the{' '}
                    {fild_data.Type == 'individual'
                      ? 'first name'
                      : 'organization name'}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="fast_name_inpurt_div">
              {fild_data.Type == 'organization' ? (
                ''
              ) : (
                <div className="input-form-wrapper dot">
                  <label>Last name</label>
                  <InputGroup className="doc-input-wrapper">
                    <Form.Control
                      disabled={fild_data.Type == 'individual' ? false : true}
                      className={'doc-form-input test valid'}
                      placeholder="Last name"
                      value={fild_data.Last_name}
                      name="name"
                      type="text"
                      onChange={(e: any) => {
                        setFild_data({
                          First_name: fild_data.First_name,
                          Last_name: e.target.value,
                          Type: fild_data.Type,
                          Select_fild: fild_data.Select_fild,
                          Street_address: fild_data.Street_address,
                          Email: fild_data.Email,
                          Phone_number: fild_data.Phone_number,
                          Country: fild_data.Country,

                          typePayment: fild_data.typePayment,
                          mpesa_type: fild_data.mpesa_type,
                          Send_Money: fild_data.Send_Money,
                          paybill_number: fild_data.paybill_number,
                          account_number: fild_data.account_number,
                          till_number: fild_data.till_number,
                        });
                      }}
                    />
                  </InputGroup>
                  {!fild_data.Last_name &&
                    fild_data.Type !== 'organization' &&
                    submitData == true ? (
                    <p className="error-message">Please enter the last name</p>
                  ) : (
                    ''
                  )}
                </div>
              )}
            </div>
          </div>
          <div>
            <ThemeProvider theme={theme}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                className="maib_redio_button_contenar"
                value={fild_data.Type}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFild_data({
                    First_name: fild_data.First_name,
                    Last_name: fild_data.Last_name,
                    Type: (event.target as HTMLInputElement).value,
                    Select_fild: fild_data.Select_fild,
                    Street_address: fild_data.Street_address,
                    Email: fild_data.Email,
                    Phone_number: fild_data.Phone_number,
                    Country: fild_data.Country,

                    typePayment: fild_data.typePayment,
                    mpesa_type: fild_data.mpesa_type,
                    Send_Money: fild_data.Send_Money,
                    paybill_number: fild_data.paybill_number,
                    account_number: fild_data.account_number,
                    till_number: fild_data.till_number,
                  });
                }}
              >
                <FormControlLabel
                  className="main_redio_buttton"
                  value="individual"
                  control={
                    <Radio
                      disabled={!!datadisplay ? true : false}
                      icon={<img src="/Checkbox.svg" />}
                      checkedIcon={<img src="/Checkbox_two.svg" />}
                    />
                  }
                  label="Individual"
                />
                <FormControlLabel
                  className="main_redio_buttton"
                  value="organization"
                  control={
                    <Radio
                      disabled={!!datadisplay ? true : false}
                      icon={<img src="/Checkbox.svg" />}
                      checkedIcon={<img src="/Checkbox_two.svg" />}
                    />
                  }
                  label="Organization"
                />
              </RadioGroup>
            </ThemeProvider>
          </div>
          {/* <div>
            <ThemeProvider theme={theme}>
              <div
                style={{ paddingBottom: '10px', paddingTop: '10px' }}
                className="amont_text_p_chackbox"
              >
                <Checkbox
                  checked={fild_data.Select_fild}
                  style={{ padding: '0px 10px 0px 0px' }}
                  icon={<img src="/Checkbox.svg" />}
                  checkedIcon={<img src="/Checkbox_two.svg" />}
                  color="primary"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFild_data({
                      First_name: fild_data.First_name,
                      Last_name: fild_data.Last_name,
                      Type: fild_data.Type,
                      Select_fild: event.target.checked,
                      Street_address: fild_data.Street_address,
                      Email: fild_data.Email,
                      Phone_number: fild_data.Phone_number,
                      Country: fild_data.Country,
                      typePayment: fild_data.typePayment,
                      mpesa_type: fild_data.mpesa_type,
                      Send_Money: fild_data.Send_Money,
                      paybill_number: fild_data.paybill_number,
                      account_number: fild_data.account_number,
                      till_number: fild_data.till_number,
                    });
                  }}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                />
                <p className="classname_customer">
                  Set this payee as a customer
                </p>
              </div>
            </ThemeProvider>
          </div> */}
          <div className="calss_accordion_contenar">
            <ThemeProvider theme={theme}>
              <div
                className="maian_adaat_listtts"
                onClick={() => {
                  if (dataaccodiyan == false) {
                    setDataccodiyan(true);
                    setPaymentacodiyan(false);
                  } else {
                    setDataccodiyan(false);
                  }
                }}
              >
                <p className="accordion_p_data_contean">Contact details</p>
                {dataaccodiyan == false ? (
                  <ExpandMoreIcon style={{ color: '#1D1D1D' }} />
                ) : (
                  <ExpandLessIcon style={{ color: '#1D1D1D' }} />
                )}
              </div>
              {dataaccodiyan == true ? (
                <div className="accordion_details_contean">
                  <div className="input-form-wrapper">
                    <label>Email</label>
                    <InputGroup className="doc-input-wrapper">
                      <Form.Control
                        className={'doc-form-input test valid'}
                        placeholder="hello@niobi.co"
                        value={fild_data.Email}
                        name="name"
                        type="email"
                        onChange={(e: any) => {
                          setFild_data({
                            First_name: fild_data.First_name,
                            Last_name: fild_data.Last_name,
                            Type: fild_data.Type,
                            Select_fild: fild_data.Select_fild,
                            Email: e.target.value,
                            Street_address: fild_data.Street_address,
                            Phone_number: fild_data.Phone_number,
                            Country: fild_data.Country,

                            typePayment: fild_data.typePayment,
                            mpesa_type: fild_data.mpesa_type,
                            Send_Money: fild_data.Send_Money,
                            paybill_number: fild_data.paybill_number,
                            account_number: fild_data.account_number,
                            till_number: fild_data.till_number,
                          });
                        }}
                      />
                    </InputGroup>
                  </div>
                  <div className="input-form-wrapper">
                    <label>Phone number</label>
                    <InputGroup className="doc-input-wrapper">
                      <Form.Control
                        className={'doc-form-input test valid'}
                        placeholder="254123456789"
                        value={fild_data.Phone_number}
                        name="name"
                        type="number"
                        onChange={(e: any) => {
                          setFild_data({
                            First_name: fild_data.First_name,
                            Last_name: fild_data.Last_name,
                            Type: fild_data.Type,
                            Select_fild: fild_data.Select_fild,
                            Email: fild_data.Email,
                            Phone_number: e.target.value,
                            Street_address: fild_data.Street_address,
                            Country: fild_data.Country,

                            typePayment: fild_data.typePayment,
                            mpesa_type: fild_data.mpesa_type,
                            Send_Money: fild_data.Send_Money,
                            paybill_number: fild_data.paybill_number,
                            account_number: fild_data.account_number,
                            till_number: fild_data.till_number,
                          });
                        }}
                      />
                    </InputGroup>
                  </div>
                  <div className="input-form-wrapper">
                    <label>Street address</label>
                    <InputGroup className="doc-input-wrapper">
                      <Form.Control
                        className={'doc-form-input test valid'}
                        placeholder="123 Main Street"
                        value={fild_data.Street_address}
                        name="name"
                        type="text"
                        onChange={(e: any) => {
                          setFild_data({
                            First_name: fild_data.First_name,
                            Last_name: fild_data.Last_name,
                            Type: fild_data.Type,
                            Select_fild: fild_data.Select_fild,
                            Email: fild_data.Email,
                            Phone_number: fild_data.Phone_number,
                            Street_address: e.target.value,
                            Country: fild_data.Country,

                            typePayment: fild_data.typePayment,
                            mpesa_type: fild_data.mpesa_type,
                            Send_Money: fild_data.Send_Money,
                            paybill_number: fild_data.paybill_number,
                            account_number: fild_data.account_number,
                            till_number: fild_data.till_number,
                          });
                        }}
                      />
                    </InputGroup>
                  </div>
                  <div className="input-form-wrapper">
                    <label>Country</label>
                    <CountryDropdown
                      value={fild_data.Country}
                      valueType="short"
                      onChange={(e: any, val) => {
                        console.log(e, val, ' e.target.value e.target.value');

                        setFild_data({
                          First_name: fild_data.First_name,
                          Last_name: fild_data.Last_name,
                          Type: fild_data.Type,
                          Select_fild: fild_data.Select_fild,
                          Email: fild_data.Email,
                          Phone_number: fild_data.Phone_number,
                          Street_address: fild_data.Street_address,
                          Country: e,

                          typePayment: fild_data.typePayment,
                          mpesa_type: fild_data.mpesa_type,
                          Send_Money: fild_data.Send_Money,
                          paybill_number: fild_data.paybill_number,
                          account_number: fild_data.account_number,
                          till_number: fild_data.till_number,
                        });
                      }}
                      name="country"
                      classes={'doc-form-input test valid'}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
            </ThemeProvider>
          </div>
     
          <div className="main_buuton_line_logg">
            <Button type="submit" onClick={() => { haredData() }}
              className='mt-3 complete-profile__btn2 text-capitalize'
              disabled={data_submit == true ? true : false}
              style={{ background: '#025041 !important', margin: "20px 0px" }}>
              {data_submit == true ? <span className="spinner-border spinner-border-sm mr-1"></span> : ""}
              {!!datadisplay ? "Update Customer" : "Create Customer"}
            </Button>
          </div>
        </div>
      </div >
        </>
    )
}
export default CreateCustomer_sub;