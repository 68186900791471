import React, { useState, useEffect, useMemo } from "react";
import { Button } from "react-bootstrap";
import "./PaymentInitiated.scss";
import { getProperties, getUser } from "../../../../../../_helpers";
import { payablesActions } from "../../../../../../_actions/payables.actions";
import { paymentJourneyActions } from "../../../../../../_actions/paymentJourney.actions";
import { useSearchParams } from "react-router-dom";
import { selectPaymentJourneyData } from "../../../../../../_reducers/paymentJourney.reducer";
import { useDispatch, useSelector } from "react-redux";
import amplitude from "amplitude-js";
import posthog from "posthog-js";

export const PaymentInitiated = (props: any) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [datadisplay, setDatadisplay] = useState("");
  const dataList = useSelector((state) => selectPaymentJourneyData(state));
  const [feesRender, setFeesRender] = useState(true);
  const [datarenader, setDatarenader] = useState(true);
  const [redusardata, setRedusardata] = useState(false);
  const [free_data, setFree_Data] = useState<any>([]);
  const [Recawestbody, setRecewestbody] = useState<any>(props);
  const [dataDisebal, setDisebal] = useState(false);
  const [renderapi, setRenderApi] = useState(false);
  const [Loader, serLoader] = useState(false);
  const [counterpartUpdated, setcounterpartUpdated] = useState<any>(
    props.counterpart
  );
  const user = getUser()?.data ?? null;
  const [databank, setDatabank] = useState<any>();
  const [validateData, setValidateData] = useState<any>();

  // console.log(!!Recawestbody?.getFee, props, "Recawestbody");

  useEffect(() => {
    setRecewestbody(props);
    setcounterpartUpdated(props.counterpart);
  }, [props]);

  const properties = getProperties();
  const eventProperties = {
    userId: !!properties && !!properties.user_id ? properties.user_id : null,
    userName: !!properties && !!properties.name ? properties.name : null,
    email: !!properties && !!properties.email ? properties.email : null,
    pageUrl: window.location.href,
  };

  useEffect(() => {
    const popup: any = searchParams.get("currency");
    if (!!popup) {
      setDatadisplay(popup);
    } else {
      setDatadisplay("");
    }
  }, [searchParams]);
  useEffect(() => {
    if (feesRender && dataList?.GETNEWFEES_SUCCESS !== undefined) {
      setFree_Data(dataList.GETNEWFEES_SUCCESS);
      setFeesRender(false);
    }
    // } else if (
    //   feesRender &&
    //   dataList?.GETNEWFEES_SUCCESS !== undefined &&
    //   (datadisplay == "Express Payment KES" || !datadisplay) &&
    //   (props?.type == "till number" ||
    //     props?.type == "pay bill" ||
    //     props?.type == "send money" ||
    //     props?.type == "Bank")
    // ) {
    //   setFeesRender(false);

    //   setFree_Data(dataList.GETNEWFEES_SUCCESS);
    // }
  }, [dataList, feesRender]);
  useEffect(() => {
    if (Recawestbody.restartPayment == "reset" && datarenader) {
      const data = Recawestbody.bankdetels.bank_code;
      dispatch(paymentJourneyActions.Bankdetails(data) as any);
      setDatarenader(false);
      setTimeout(() => {
        setRedusardata(true);
        setDisebal(true);
      }, 7000);
    }
  }, [datarenader]);
  useEffect(() => {
    if (
      redusardata &&
      Recawestbody.restartPayment == "reset" &&
      dataList?.BANK_DETELS_SUCCESS !== undefined
    ) {
      setDatabank(dataList?.BANK_DETELS_SUCCESS);
      setRedusardata(false);
    }
  }, [redusardata, dataList]);
  const onSave = () => {
    props.onSave();
  };

  const onCancel = () => {
    props.onCancel();
  };
  console.log(
    Recawestbody.bankdetels,
    props?.getFee,
    Recawestbody,
    "Recawestbody.bankdetelsRecawestbody.bankdetels"
  );

  const onSubmitted = () => {
    if (Recawestbody.type == "Bank" && datadisplay == "") {
      const body = Recawestbody.bankdetels;
      if (Recawestbody.restartPayment == "reset") {
        body.type = Recawestbody.type;
      }
      const event = "New Payment Initiated via Bank Transfer";
      amplitude.getInstance().logEvent(event, eventProperties);
      posthog.capture(event, eventProperties);
      dispatch(paymentJourneyActions.AddBankDisbursement(body) as any);
      onSave();
    } else if (Recawestbody.type == "Bank" && datadisplay == "KES") {
      const body = Recawestbody.bankdetels;
      const amount_list = body.amount * 100;
      body.amount = amount_list;
      body.vat = Recawestbody.bankdetels.vat.toString();
      body.currency = 'KES';
      body.country_id = 1;
      if (Recawestbody.restartPayment == "reset") {
        body.type = Recawestbody.type;
      }
      const event = "New Express Payment Initiated via Bank Transfer";
      amplitude.getInstance().logEvent(event, eventProperties);
      posthog.capture(event, eventProperties);
      dispatch(paymentJourneyActions.AddBankDisbursement_experes(body) as any);
      onSave();
    } else if (
      (datadisplay == "KES" && Recawestbody.type == "till number") ||
      (datadisplay == "KES" && props.type == "pay bill") ||
      (datadisplay == "KES" && props.type == "send money")
    ) {
      const body = Recawestbody.responss_datatta;
      const amount_list = body.amount * 100;
      body.amount = amount_list;
      body.currency = 'KES';
      body.country_id = 1;
      body.vat = Recawestbody.responss_datatta.vat.toString();
      if (props.type == "pay bill" || Recawestbody.type == "till number") {
        body.verified_account_name = props?.AccountName;
      }

      if (Recawestbody.restartPayment == "reset") {
        const updatedBankDetails = {
          ...Recawestbody.responss_datatta,
          amount: amount_list,
          type: Recawestbody.type,
          counterpart: {
            ...Recawestbody.responss_datatta.counterpart,
            paybill_number: databank?.paybill_number,
          },
        };
        if (!!databank) {
          dispatch(
            paymentJourneyActions.Express_Payment_actions(
              updatedBankDetails
            ) as any
          );
          onSave();
        }

        // body.type = Recawestbody.type
        // body.counterpart.paybill_number = databank?.paybill_number
      } else {
        dispatch(paymentJourneyActions.Express_Payment_actions(body) as any);
        onSave();
      }
      const event =
        Recawestbody.type == "till number"
          ? "New Express Payment Initiated via MPesa Till Number"
          : Recawestbody.type == "send money"
            ? "New Express Payment Initiated via MPesa SendMoney"
            : "New Express Payment Initiated via MPesa PayBill ";
      amplitude.getInstance().logEvent(event, eventProperties);
      posthog.capture(event, eventProperties);
    } else if (
      (Recawestbody.type == "till number" && datadisplay == "") ||
      (props.type == "pay bill" && datadisplay == "") ||
      (datadisplay == "" && props.type == "send money")
    ) {
      var request: any = {
        payment_details: Recawestbody.paymentDeatils,
        counterpart: Recawestbody.counterpart,
        type: Recawestbody.type,
      };
      if (props.type == "pay bill" || Recawestbody.type == "till number") {
        request.verified_account_name = props?.AccountName;
      }
      if (
        Recawestbody.restartPayment == "reset" &&
        request.counterpart.paybill_number != databank?.paybill_number
      ) {
        const requestData = {
          payment_details: Recawestbody.paymentDeatils,
          type: Recawestbody.type,
          counterpart: {
            ...Recawestbody.counterpart,
            paybill_number: databank?.paybill_number,
          },
        };
        const event =
          props.type == "till number"
            ? "New Payment Initiated via MPesa Till Number"
            : props.type == "send money"
              ? "New Payment Initiated via MPesa SendMoney"
              : "New Payment Initiated via MPesa PayBill";
        amplitude.getInstance().logEvent(event, eventProperties);
        if (!!databank) {
          dispatch(payablesActions.initiatePayment(requestData) as any);
          onSave();
        }
      } else {
        const event =
          props.type == "till number"
            ? "New Payment Initiated via MPesa Till Number"
            : props.type == "send money"
              ? "New Payment Initiated via MPesa SendMoney"
              : "New Payment Initiated via MPesa PayBill";
        amplitude.getInstance().logEvent(event, eventProperties);
        dispatch(payablesActions.initiatePayment(request) as any);
        onSave();
      }
    } else if (
      (datadisplay == "USD" && props.type == "RTGS") ||
      (datadisplay == "USD" && props.type == "EFT") ||
      (datadisplay == "USD" && props.type == "SWIFT") ||
      (datadisplay == "KES" && props.type == "RTGS") ||
      (datadisplay == "KES" && props.type == "EFT") ||
      (datadisplay == "USD" && props.type == "Digital Currency")
    ) {
      const body = Recawestbody.bankdetels;
      const amount_list = body.amount * 100;
      body.amount = amount_list;
      body.vat = Recawestbody.bankdetels.vat.toString();
      body.fees = props?.getFee?.fees / 100;
      const event = "New Express Payment Initiated via Bank Transfer";
      amplitude.getInstance().logEvent(event, eventProperties);
      posthog.capture(event, eventProperties);
      dispatch(
        paymentJourneyActions.AddBankExperesUsd(
          "/bank/save-payment-details-by-currency",
          body
        ) as any
      );
      onSave();
    } else if (
      (datadisplay == "" && props.type == "RTGS") ||
      (datadisplay == "" && props.type == "EFT")
    ) {
      var body = Recawestbody.bankdetels;
      const amount_list = body.amount * 100;
      body.type = Recawestbody.type;
      body.fees = props?.getFee?.fees / 100;
      // delete request.payment_details.counterpart
      console.log(body, Recawestbody, "requestrequest65222");

      dispatch(
        paymentJourneyActions.AddBankExperesUsd(
          "/bank/save-payment-details-by-currency-from-bill",
          body
        ) as any
      );
      onSave();
    } else if (!!datadisplay && datadisplay !== "KES" && datadisplay !== "USD" && (props.type == "RTGS" || props.type == "EFT" || props.type == "SWIFT")) {
      const body = Recawestbody.bankdetels;
      const amount_list = body.amount * 100;
      body.amount = amount_list;
      body.vat = Recawestbody.bankdetels.vat.toString();
      body.country_id = Recawestbody.coutryidset
      body.currency = datadisplay
      body.fees = props?.getFee?.fees / 100;
      console.log(body, Recawestbody, "dsfgvddsfsf");

      const event = "New Express Payment Initiated via Bank Transfer";
      amplitude.getInstance().logEvent(event, eventProperties);
      posthog.capture(event, eventProperties);
      dispatch(
        paymentJourneyActions.AddBankExperesUsd(
          "/bank/save-payment-details-by-currency",
          body
        ) as any
      );
      onSave();
    } else if (!!datadisplay &&
      datadisplay !== "KES" &&
      datadisplay !== "USD" &&
      (props.type != "RTGS" || props.type != "EFT" || props.type != "SWIFT")) {
      const amount_list = Recawestbody?.responss_datatta?.amount * 100;

      const updatedBankDetails = {
        ...Recawestbody.responss_datatta,
        amount: amount_list,
        type: Recawestbody.type,
        country_id: Recawestbody.coutryidset,
        currency: datadisplay,
        counterpart: {
          ...Recawestbody.responss_datatta.counterpart,
          phone_number: Recawestbody?.phone_numberOnther,
        },
      };
      // var body = Recawestbody.responss_datatta;
      // body.amount = amount_list;
      // body.type = Recawestbody.type;
      // body.counterpart.phone_number = Recawestbody?.phone_numberOnther;
      // body.country_id = Recawestbody.coutryidset
      // body.currency = datadisplay
      console.log(updatedBankDetails, Recawestbody, "dsfgvddsfsf");

      dispatch(
        paymentJourneyActions.Express_Payment_v3_actions(updatedBankDetails) as any
      );
      onSave();
      console.log(updatedBankDetails,
        Recawestbody, "ADFSZFFFFFFFF");
    }
  };
  // useEffect(() => {
  //   if (renderapi && dataList?.NIOBI_SIGNATURE_SUCCESS !== undefined) {
  //     console.log(
  //       dataList?.NIOBI_SIGNATURE_SUCCESS,
  //       "dataList?.NIOBI_SIGNATURE_SUCCESS"
  //     );
  //     var body = dataList?.NIOBI_SIGNATURE_SUCCESS?.payload;
  //     dispatch(paymentJourneyActions.Express_Payment_v3_actions(body) as any);
  //     onSave();
  //     serLoader(false);
  //     setRenderApi(false);
  //   }
  // }, [renderapi, dataList?.NIOBI_SIGNATURE_SUCCESS]);
  console.log(
    Recawestbody?.bankdetels?.payment_details?.currency,
    "requestrequest7888"
  );
  console.log(free_data, "free_datafree_data");
  const type =
    props?.type == "send money"
      ? "M-Pesa Send Money"
      : props?.type == "pay bill"
        ? "M-Pesa Paybill"
        : props?.type == "till number"
          ? "M-Pesa Till"
          : props?.type == "Bank"
            ? "Pesalink"
            : props?.type;


const memoizedValidateData = useMemo(() => {
  return dataList?.GETVALIDATE_ACCOUNT_SUCCESS;
}, [dataList?.GETVALIDATE_ACCOUNT_SUCCESS]);

useEffect(() => {
  if (memoizedValidateData) {
    setValidateData(memoizedValidateData);
  }
}, [memoizedValidateData]);


  console.log("validateData =>", validateData);
  

  return (
    <div className="pay-initiated-container">
      <div className="pay-initiated-container__logo">
        <TimeLogo />
      </div>
      <div className="pay-initiated-container__heading">Confirm Details</div>
      <div className="pay-initiated-container__desc">
        Please confirm the payment details before you proceed.
      </div>

      <div className="pay-initiated-container__table">
        {!!props?.type ? (
          <div className="dflex b-bot">
            <div className="left">Payment Channel</div>
            <div className="right" style={{ textTransform: "uppercase" }}>{type}</div>
          </div>
        ) : (
          ""
        )}
        {!!props?.paymentDeatils?.amount &&
          !!datadisplay &&
          (props.type == "till number" ||
            props.type == "pay bill" ||
            props.type == "send money") ? (
          <div className="dflex b-bot">
            <div className="left">Transaction Amount</div>
            <div className="right">
              {datadisplay}{" "}
              {!!props?.paymentDeatils?.amount
                ? Number(
                  parseFloat(props?.paymentDeatils?.amount).toFixed(2)
                ).toLocaleString("en", { minimumFractionDigits: 2 })
                : 0}{" "}
            </div>
          </div>
        ) : (
          <div className="dflex b-bot">
            <div className="left">Transaction Amount</div>
            <div className="right" >
              {datadisplay}{" "}
              {!!props?.paymentDeatils?.amount
                ? Number(
                  parseFloat(props?.paymentDeatils?.amount).toFixed(2)
                ).toLocaleString("en", { minimumFractionDigits: 2 })
                : ""}
            </div>
          </div>
        )}
        {!!props?.counterpart && !!datadisplay ? (
          <div className="dflex b-bot">
            <div className="left">Payee Name</div>
            <div className="right">
              {props?.paymentDeatils &&
                (props?.paymentDeatils?.counterpart?.counterpart_type ==
                  "individual"
                  ? props?.paymentDeatils?.counterpart.contact_first_name +
                  " " +
                  props?.paymentDeatils?.counterpart?.contact_last_name
                  : props?.paymentDeatils?.counterpart?.name)}
            </div>
          </div>
        ) : (
          ""
        )}
        {!props?.counterpart ? (
          <div className="dflex b-bot">
            <div className="left">Payee Name</div>
            <div className="right">
              {props?.paymentDeatils &&
                (!!props?.paymentDeatils?.counterpart?.legal_name
                  ? props?.paymentDeatils?.counterpart.legal_name
                  : props?.paymentDeatils?.counterpart.first_name +
                  " " +
                  props?.paymentDeatils?.counterpart.last_name)}
            </div>
          </div>
        ) : (
          ""
        )}
        {!!props?.type &&
          (props?.type == "RTGS" || props?.type == "EFT") &&
          props?.bankdetels && (
            <>
              <div className="dflex b-bot">
                <div className="left">Bank Name</div>
                <div className="right">{props?.bankdetels?.bank_name}</div>
              </div>
              <div className="dflex b-bot">
                <div className="left">Account Name</div>
                <div className="right">
                  {props?.bankdetels?.account_name ?? "N/A"}
                </div>
              </div>
              <div className="dflex b-bot">
                <div className="left">Account Number</div>
                <div className="right">
                  {props?.bankdetels?.account_number ?? "N/A"}
                </div>
              </div>
            </>
          )}
        {!!props?.type &&
          props?.type == "Digital Currency" &&
          props?.bankdetels && (
            <>
              <div className="dflex b-bot">
                <div className="left">Digital currency</div>
                <div className="right">
                  {props?.bankdetels?.digital_currency}
                </div>
              </div>
              {/* <div className="dflex b-bot">
                <div className="left">Amount you want to purchase</div>
                <div className="right">
                  {props?.bankdetels?.amount_to_purchase}
                </div>
              </div> */}
              <div className="dflex b-bot">
                <div className="left">Wallet address</div>
                <div className="right">{props?.bankdetels?.wallet_address}</div>
              </div>
              <div className="dflex b-bot">
                <div className="left">Wallet network</div>
                <div className="right">{props?.bankdetels?.wallet_network}</div>
              </div>
            </>
          )}
        {!!props?.type && props?.type == "SWIFT" && props?.bankdetels && (
          <>
            <div className="dflex b-bot">
              <div className="left">Account name</div>
              <div className="right">{props?.bankdetels?.account_name}</div>
            </div>
            <div className="dflex b-bot">
              <div className="left">Account number / IBAN</div>
              <div className="right">{props?.bankdetels?.account_number}</div>
            </div>
            <div className="dflex b-bot">
              <div className="left">Beneficiary bank name</div>
              <div className="right">
                {props?.bankdetels?.beneficiary_bank_name}
              </div>
            </div>
            <div className="dflex b-bot">
              <div className="left">Beneficiary bank SWIFT code</div>
              <div className="right">
                {props?.bankdetels?.beneficiary_bank_swift_code}
              </div>
            </div>
            <div className="dflex b-bot">
              <div className="left">Beneficiary bank address</div>
              <div className="right">
                {props?.bankdetels?.beneficiary_bank_address}
              </div>
            </div>
            {!!props?.bankdetels?.correspondent_bank_name ? (
              <div className="dflex b-bot">
                <div className="left">Correspondent bank name</div>
                <div className="right">
                  {props?.bankdetels?.correspondent_bank_name}
                </div>
              </div>
            ) : (
              ""
            )}
            {!!props?.bankdetels?.correspondent_bank_swift_code ? (
              <div className="dflex b-bot">
                <div className="left">Correspondent bank SWIFT code</div>
                <div className="right">
                  {props?.bankdetels?.correspondent_bank_swift_code}
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        )}
        {!!props?.type && props?.type == "Bank" && (
          <>
            <div className="dflex b-bot">
              <div className="left">Bank Name</div>
              <div className="right">{props?.bankdetels.bank_name}</div>
            </div>
            <div className="dflex b-bot">
              <div className="left">Account Name</div>
              <div className="right">
                {props?.bankdetels?.account_name ?? "N/A"}
              </div>
            </div>
            <div className="dflex b-bot">
              <div className="left">Account Number</div>
              <div className="right">
                {props?.bankdetels?.bank_account_number ?? "N/A"}
              </div>
            </div>
          </>
        )}
        {validateData?.success &&  
        <>
        {props?.type == "till number" ? (
          <div className="dflex b-bot">
            <div className="left">Till Name</div>
            {/* <div className="right">{props?.AccountName ?? "N/A"}</div> */}
            <div className="right">{validateData?.data?.name}</div>
          </div>
        ) : (
          ""
        )}
        </>  
       } 

      {validateData?.success &&  
        <>
        {props?.type == "pay bill" ? (
          <div className="dflex b-bot">
            <div className="left">Paybill Name</div>
            {/* <div className="right">{props?.AccountName ?? "N/A"}</div> */}
            <div className="right">{validateData?.data?.name}</div>
          </div>
        ) : (
          ""
        )}
          </>  
       } 


        {datadisplay == "KES" &&
          props?.restartPayment == "" &&
          (props?.type == "till number" ||
            props?.type == "pay bill" ||
            props?.type == "send money") ? (
          <>
            {!!props?.responss_datatta?.type &&
              props?.responss_datatta?.type == "pay bill" &&
              !!props?.responss_datatta?.counterpart?.paybill_number ? (
              <>
                <div className="dflex b-bot">
                  <div className="left">M-Pesa Paybill No.</div>
                  <div className="right">
                    {props?.responss_datatta?.counterpart?.paybill_number}
                  </div>
                </div>

                <div className="dflex b-bot">
                  <div className="left">Account No.</div>
                  <div className="right">
                    {props?.responss_datatta?.counterpart?.account_number ??
                      "N/A"}
                  </div>
                </div>
              </>
            ) : !!props?.responss_datatta?.type &&
              props?.responss_datatta?.type == "till number" &&
              !!props?.responss_datatta?.counterpart?.till_number ? (
              <>
               
                <div className="dflex b-bot">
                  <div className="left">M-Pesa Till Number</div>
                  {datadisplay == "KES" ? (
                    <div className="right">
                      {props?.responss_datatta?.counterpart?.till_number}
                    </div>
                  ) : (
                    <div className="right">
                      {props?.counterpart?.till_number ?? "N/A"}
                    </div>
                  )}
                </div>
              </>
            ) : !!props?.responss_datatta?.type &&
              props?.responss_datatta?.type == "send money" &&
              !!props?.responss_datatta?.counterpart?.phone_number ? (
              <>
              
                <div className="dflex b-bot">
                  <div className="left">M-Pesa Mobile No.</div>
                  <div className="right">
                    {props?.responss_datatta?.counterpart?.phone_number}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </>
        ) : datadisplay == "KES" &&
          props?.restartPayment == "reset" &&
          (props?.type == "till number" ||
            props?.type == "pay bill" ||
            props?.type == "send money") ? (
          <>
            {props?.type == "pay bill" &&
              props?.counterpart?.paybill_number &&
              Recawestbody.restartPayment == "reset" ? (
              <>
                <div className="dflex b-bot">
                  <div className="left">M-Pesa Paybill No.</div>
                  <div className="right">
                    {databank?.paybill_number ? databank?.paybill_number : "-"}
                  </div>
                </div>
                <div className="dflex b-bot">
                  <div className="left">Account No.</div>
                  <div className="right">
                    {props?.counterpart?.account_number ?? "N/A"}
                  </div>
                </div>
              </>
            ) : props?.type == "pay bill" &&
              props?.counterpart?.paybill_number &&
              Recawestbody.restartPayment == "" ? (
              <>
                <div className="dflex b-bot">
                  <div className="left">M-Pesa Paybill No.</div>
                  <div className="right">
                    {props?.counterpart?.paybill_number}
                  </div>
                </div>
                <div className="dflex b-bot">
                  <div className="left">Account No.</div>
                  <div className="right">
                    {props?.counterpart?.account_number ?? "N/A"}
                  </div>
                </div>
              </>
            ) : !!props?.type &&
              props?.type == "till number" &&
              !!props?.counterpart?.till_number ? (
              <>
                <div className="dflex b-bot">
                  <div className="left">M-Pesa Till Number</div>
                  {datadisplay == "KES" ? (
                    <div className="right">
                      {props?.counterpart?.till_number}
                    </div>
                  ) : (
                    <div className="right">
                      {props?.counterpart?.till_number ?? "N/A"}
                    </div>
                  )}
                </div>
              </>
            ) : !!props?.type &&
              props?.type == "send money" &&
              !!props?.counterpart?.phone_number ? (
              <>
                <div className="dflex b-bot">
                  <div className="left">M-Pesa Mobile No.</div>
                  <div className="right">
                    {props?.counterpart?.phone_number}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            {!!props?.type &&
              props?.type == "pay bill" &&
              !!props?.counterpart?.paybill_number &&
              Recawestbody.restartPayment == "reset" ? (
              <>
                <div className="dflex b-bot">
                  <div className="left">M-Pesa Paybill No.</div>
                  <div className="right">
                    {databank?.paybill_number ? databank?.paybill_number : "-"}
                  </div>
                </div>
                <div className="dflex b-bot">
                  <div className="left">Account No.</div>
                  <div className="right">
                    {props?.counterpart?.account_number ?? "N/A"}
                  </div>
                </div>
              </>
            ) : !!props?.type &&
              props?.type == "pay bill" &&
              !!props?.counterpart?.paybill_number &&
              Recawestbody.restartPayment == "" ? (
              <>
                <div className="dflex b-bot">
                  <div className="left">M-Pesa Paybill No.</div>
                  <div className="right">
                    {props?.counterpart?.paybill_number}
                  </div>
                </div>
                <div className="dflex b-bot">
                  <div className="left">Account No.</div>
                  <div className="right">
                    {props?.counterpart?.account_number ?? "N/A"}
                  </div>
                </div>
              </>
            ) : !!props?.type &&
              props?.type == "till number" &&
              !!props?.counterpart?.till_number ? (
              <>
                <div className="dflex b-bot">
                  <div className="left">M-Pesa Till Number</div>
                  {datadisplay == "KES" ? (
                    <div className="right">
                      {props?.responss_datatta.counterpart?.till_number}
                    </div>
                  ) : (
                    <div className="right">
                      {props?.counterpart?.till_number ?? "N/A"}
                    </div>
                  )}
                </div>
              </>
            ) : !!props.counterpart &&
              !!props?.type &&
              props?.type == "send money" &&
              !!props?.counterpart.phone_number ? (
              <>
                <div className="dflex b-bot">
                  <div className="left">M-Pesa Mobile No.</div>
                  <div className="right">
                    {props?.counterpart?.phone_number}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </>
        )}
        {
          !!props?.phone_numberOnther ? (
            <>
              <div className="dflex b-bot">
                <div className="left">Mobile No.</div>
                <div className="right">{props?.phone_numberOnther}</div>
              </div>
            </>
          ) : (
            <></>
          )}
        {!!props?.paymentDeatils?.document_id ? (
          <div className="dflex b-bot">
            <div className="left">Invoice Number</div>
            <div className="right">{props?.paymentDeatils?.document_id}</div>
          </div>
        ) : (
          ""
        )}
        {!!props?.getFee ?
          <div className="dflex b-bot">
            <div className="left">Transaction Fees</div>
            <div className="right">
              {!!datadisplay?datadisplay:'KES'} {!!props?.getFee?.fees ? props?.getFee?.fees / 100 : 0}
            </div>
          </div> : ""}
        {/* {!!props?.fees && props?.type == "till number" ? (
          <div className="dflex b-bot">
            <div className="left">Transaction Fees</div>
            <div className="right">
              {datadisplay} {!!free_data ? free_data["MPESA B2B TILL"] : 0}
            </div>
          </div>
        ) : (
          ""
        )}
        {!!props?.fees &&
          props?.type == "pay bill" &&
          props?.FreeDisplay == true ? (
          <div className="dflex b-bot">
            <div className="left">Transaction Fees</div>
            <div className="right">
              {datadisplay} {!!free_data ? free_data["MPESA B2B PAYBILL"] : 0}
            </div>
          </div>
        ) : !!props?.fees &&
          props?.type == "pay bill" &&
          props?.FreeDisplay == false ? (
          <div className="dflex b-bot">
            <div className="left">Transaction Fees</div>
            <div className="right">{datadisplay} 0</div>
          </div>
        ) : (
          ""
        )}
        {!!props?.fees && props?.type == "send money" ? (
          <div className="dflex b-bot">
            <div className="left">Transaction Fees</div>
            <div className="right">
              KES {!!free_data ? free_data["MPESA B2C DARAJA"] : 0}
            </div>
          </div>
        ) : (
          ""
        )}
        {!!props?.fees &&
          props?.type == "Bank" &&
          datadisplay == "KES" &&
          props?.FreeDisplay == true ? (
          <div className="dflex b-bot">
            <div className="left">Transaction Fees</div>
            <div className="right">
              {datadisplay} {!!free_data ? free_data["pesalink"] : 0}
            </div>
          </div>
        ) : !!props?.fees &&
          props?.type == "Bank" &&
          datadisplay == "KES" &&
          props?.FreeDisplay == false ? (
          <div className="dflex b-bot">
            <div className="left">Transaction Fees</div>
            <div className="right">KES 0</div>
          </div>
        ) : (
          ""
        )} */}
        {/* {(datadisplay=="KES"||
            datadisplay=="USD")&&(!!props?.type && props?.type == "Digital Currency") ||
          (datadisplay=="USD"&&props?.type == "SWIFT") ||
          (datadisplay=="USD"&&props?.type == "RTGS") ||
          (datadisplay=="USD"&&props?.type == "EFT"||datadisplay=="KES"||&&props?.type == "EFT")
          ? (
          <div className="dflex b-bot">
            <div className="left">Transaction Fees</div>
            <div className="right">
              {datadisplay}{" "}
              {props?.type == "SWIFT" && !!free_data && datadisplay == "USD"
                ? free_data["usd_swift"]
                : props?.type == "RTGS" && !!free_data && datadisplay == "USD"
                  ? free_data["usd_rtgs"]
                  : props?.type == "EFT" && !!free_data && datadisplay == "USD"
                    ? free_data["usd_eft"]
                    : props?.type == "SWIFT" && !!free_data && datadisplay == "KES"
                      ? free_data["swift"]
                      : props?.type == "RTGS" && !!free_data && datadisplay == "KES"
                        ? free_data["rtgs"]
                        : props?.type == "EFT" && !!free_data && datadisplay == "KES"
                          ? free_data["eft"]
                          : props?.type == "Digital Currency" &&
                            !!free_data &&
                            datadisplay == "USD"
                            ? free_data["digital"]
                            : props?.type == "RTGS" &&
                              Recawestbody?.bankdetels?.payment_details?.currency ==
                              "KES" &&
                              !datadisplay
                              ? free_data["rtgs"]
                              : props?.type == "EFT" &&
                                Recawestbody?.bankdetels?.payment_details?.currency ==
                                "KES" &&
                                !datadisplay
                                ? free_data["eft"]
                                : props?.type == "RTGS" &&
                                  Recawestbody?.bankdetels?.payment_details?.currency ==
                                  "USD" &&
                                  !datadisplay
                                  ? free_data["usd_rtgs"]
                                  : props?.type == "EFT" &&
                                    Recawestbody?.bankdetels?.payment_details?.currency ==
                                    "USD" &&
                                    !datadisplay
                                    ? free_data["usd_eft"]
                                    : 0}
            </div>
          </div>
        ) : (
          ""
        )} */}
      </div>

      <div className="pay-initiated-container__btn-row">
        <Button
          className="auth-trans-container__btn-row__white"
          onClick={() => onCancel()}
        >
          Go Back
        </Button>

        <Button
          className="pay-initiated-container__btn-row__green"
          onClick={() => onSubmitted()}
        >
          Pay
        </Button>
      </div>
    </div>
  );
};

const TimeLogo = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.0003 0.666504C15.3743 0.666504 11.8298 1.74174 8.81488 3.75623C5.79998 5.77072 3.45015 8.63399 2.06254 11.984C0.674936 15.334 0.311875 19.0202 1.01927 22.5765C1.72667 26.1328 3.47275 29.3995 6.03671 31.9635C8.60067 34.5274 11.8674 36.2735 15.4237 36.9809C18.98 37.6883 22.6662 37.3252 26.0162 35.9376C29.3662 34.55 32.2294 32.2002 34.2439 29.1853C36.2584 26.1704 37.3337 22.6258 37.3337 18.9998C37.3337 16.5923 36.8595 14.2083 35.9381 11.984C35.0168 9.75967 33.6664 7.73862 31.964 6.03621C30.2615 4.33381 28.2405 2.98338 26.0162 2.06205C23.7919 1.14071 21.4079 0.666504 19.0003 0.666504ZM19.0003 33.6665C16.0995 33.6665 13.2639 32.8063 10.852 31.1947C8.44005 29.5831 6.56019 27.2925 5.4501 24.6125C4.34002 21.9325 4.04957 18.9836 4.61548 16.1385C5.1814 13.2935 6.57826 10.6801 8.62943 8.62894C10.6806 6.57777 13.294 5.1809 16.139 4.61499C18.9841 4.04907 21.933 4.33952 24.613 5.4496C27.293 6.55969 29.5836 8.43955 31.1952 10.8515C32.8068 13.2634 33.667 16.099 33.667 18.9998C33.667 22.8897 32.1218 26.6202 29.3712 29.3707C26.6207 32.1213 22.8902 33.6665 19.0003 33.6665ZM24.6837 20.1548L20.8337 17.9365V9.83317C20.8337 9.34694 20.6405 8.88062 20.2967 8.53681C19.9529 8.19299 19.4866 7.99984 19.0003 7.99984C18.5141 7.99984 18.0478 8.19299 17.704 8.53681C17.3602 8.88062 17.167 9.34694 17.167 9.83317V18.9998C17.167 18.9998 17.167 19.1465 17.167 19.2198C17.1779 19.3462 17.2088 19.4699 17.2587 19.5865C17.2964 19.6953 17.3456 19.7997 17.4053 19.8982C17.4555 20.0024 17.517 20.1007 17.5887 20.1915L17.882 20.4298L18.047 20.5948L22.8137 23.3448C23.0931 23.5032 23.4092 23.5854 23.7303 23.5832C24.1363 23.586 24.5317 23.454 24.8545 23.208C25.1774 22.9619 25.4094 22.6156 25.5143 22.2234C25.6191 21.8313 25.5909 21.4154 25.4339 21.041C25.2769 20.6667 25.0002 20.355 24.647 20.1548H24.6837Z"
      fill="#257D6B"
    />
  </svg>
);
