import { alertActions } from '.';
import { entityService } from '../_services/entity.service';
import { entityConstants } from '../_constants/entity.constants';
// import toast, { Toaster } from 'react-hot-toast';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Unauthorized } from '../_constants/ErrorMessage';

export const entityActions = {
  getEntityTypes,
  getEntityActivities,
  createEntity,
  updateEntity,
  getEntities,
  getBilldata,
  getBusinessDetails,
  setCurrentStepAction,
  getCurrentStepAction,
  getDetailsForNewAPIAction,
  saveBusinessDetailsAction,
  SaveEntity,
  updetDataAction
};

function getEntityTypes() {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    entityService
      .getEntityTypes()
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          dispatch(success(response.data));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: entityConstants.GETENTITYITEMS_REQUEST };
  }
  function success(data: any) {
    return { type: entityConstants.GETENTITYITEMS_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: entityConstants.GETENTITYITEMS_FAILURE };
  }
}

function getEntityActivities() {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    entityService
      .getEntityActivities()
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          dispatch(success(response.data));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: entityConstants.GETENTITYITEMS_REQUEST };
  }
  function success(data: any) {
    return { type: entityConstants.GET_ENTITY_ACTIVITY_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: entityConstants.GETENTITYITEMS_FAILURE };
  }
}

function createEntity(entity: any, path: any, api: any, Newigesan: any) {
  return (dispatch: (arg0: any) => void) => {
    dispatch(request(entity));

    entityService
      .createEntity(entity, api)
      .then((response) => {
        return response.json();
      })
      .then(
        (entity) => {
          if (entity.success) {
            dispatch(success(entity?.success));
            dispatch(alertActions.success('Registration successful'));
            console.log(entity, api, 'fdgdfggggg');

            if (api == '/v2/update-entity') {
              // updateStep(5)
              // setSetpNumberAuth(5)
              // window.location.href = path;
              Newigesan();
            } else {
              
              localStorage.removeItem('user');
              localStorage.removeItem('token');

              localStorage.setItem('user', JSON.stringify(entity));
              localStorage.setItem('token', entity.data.token);
              // window.location.href = path;
              Newigesan();

            }
          } else {
            dispatch(failure(entity.success));
            dispatch(alertActions.error(entity.message));

            if (entity.message == 'Validation errors') {
              var fieldarray = [
                'registration_number',
                'business_name',
                'company_type',
                'business_activity',
                'zip_code',
                'city',
                'street_address',
                'tax_identification_number',
                'state',
                'country',
              ];
              fieldarray.map((field: any) => {
                if (!!entity.data[field]) {
                  entity.data[field].map((i: string) => {
                    toast.error(i);
                  });
                }
              });
            }
          }
        },
        (error) => {
          alert(error.toString());
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(entity: any) {
    return { type: entityConstants.ADDTOENTITY_REQUEST, entity };
  }
  function success(entity: any) {
    return { type: entityConstants.ADDTOENTITY_SUCCESS, entity };
  }
  function failure(error: any) {
    return { type: entityConstants.ADDTOENTITY_FAILURE, error };
  }
}
function SaveEntity(entity: any, api: any, createBusinessAccount: any) {
  return (dispatch: (arg0: any) => void) => {
    dispatch(request(entity));

    entityService
      .SaveEntity(entity, api)
      .then((response) => {
        return response.json();
      })
      .then(
        (entity) => {
          console.log(entity, 'entity______________');

          if (entity.success == true) {
            console.log(entity, 'entity');

            dispatch(success(entity?.success));
            dispatch(alertActions.success('Registration successful'));
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            // if (typeof afterCall == 'function') {
            //   afterCall(path)
            // }
            localStorage.setItem('user', JSON.stringify(entity));
            localStorage.setItem('token', entity.data.token);
            // navigateCallback();
            createBusinessAccount();
            // setTimeout
            // if (typeof afterCall == 'function') {

            // } else {
            //   window.location.href = path;
            // }
          } else {
            dispatch(failure(entity.success));
            dispatch(alertActions.error(entity.message));

            if (entity.message == 'Validation errors') {
              var fieldarray = [
                'registration_number',
                'business_name',
                'company_type',
                'business_activity',
                'zip_code',
                'city',
                'street_address',
                'tax_identification_number',
                'state',
                'country',
              ];
              console.log(entity, 'entity______________');

              fieldarray.map((field: any) => {
                if (!!entity.data[field]) {
                  entity.data[field].map((i: string) => {
                    toast.error(i);
                  });
                }
              });
            }
          }
        },
        (error) => {
          alert(error.toString());
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(entity: any) {
    return { type: entityConstants.SAVE_ENTITY_REQUEST, entity };
  }
  function success(entity: any) {
    return { type: entityConstants.SAVE_ENTITY_SUCCESS, entity };
  }
  function failure(error: any) {
    return { type: entityConstants.SAVE_ENTITY_FAILURE, error };
  }
}
function updateEntity(entity: any) {
  return (dispatch: (arg0: any) => void) => {
    dispatch(request(entity));

    entityService
      .updateEntity(entity)
      .then((response) => {
        return response.json();
      })
      .then(
        (entity) => {
          if (entity.success) {
            dispatch(success(entity));
            dispatch(alertActions.success('Update entity successful'));
            toast.success(entity.message);
            window.location.reload();
          } else {
            dispatch(failure(entity.message));
            dispatch(alertActions.error(entity.message));
            if (entity.message == 'Validation errors') {
              var fieldarray = [
                'entity_id',
                'street_address',
                'country',
                'state',
                'city',
                'zip_code',
              ];
              fieldarray.map((field: any) => {
                if (!!entity.data[field]) {
                  entity.data[field].map((i: string) => {
                    toast.error(i);
                  });
                }
              });
            }
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(entity: any) {
    return { type: entityConstants.UPDATEENTITY_REQUEST, entity };
  }
  function success(entity: any) {
    return { type: entityConstants.UPDATEENTITY_SUCCESS, entity };
  }
  function failure(error: any) {
    return { type: entityConstants.UPDATEENTITY_FAILURE, error };
  }
}
function getBilldata() {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    entityService
      .getBilldata()
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            var datalist = new Date();
            dispatch(success(response.data));
            var user: any = localStorage.getItem('user');
            var newUser = JSON.parse(user);
            newUser.data.monite_access_token = response.data;
            localStorage.removeItem('user');
            localStorage.removeItem('tokentwo');
            localStorage.setItem('user', JSON.stringify(newUser));
            localStorage.setItem('tokentwo', JSON.stringify(datalist));
            window.location.reload();
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: entityConstants.BILLLIST_REQUEST };
  }
  function success(data: any) {
    return { type: entityConstants.BILLLIST_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: entityConstants.BILLLIST_FAILURE };
  }
}
function getEntities(req: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    entityService
      .getEntities(req)
      .then((data) => {
        return data.json();
      })
      .then(
        (transaction) => {
          if (transaction.success) {
            dispatch(success(transaction));
            dispatch(alertActions.success('Transactions Success'));
          } else {
            dispatch(failure(transaction.message));
            dispatch(alertActions.error(transaction.message));
            toast.error(transaction.message);
          }
        },
        (error) => {
          console.log('Transactions error main: ', error);
          toast.error(Unauthorized);

          //alert(error.toString());
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() {
    return { type: entityConstants.GETENTITY_REQUEST };
  }
  function success(data: any) {
    return { type: entityConstants.GETENTITY_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: entityConstants.GETENTITY_FAILURE };
  }
}

function getBusinessDetails(req: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    entityService
      .getBusinessDetails(req)
      .then((data) => {
        return data.json();
      })
      .then(
        (transaction) => {
          if (transaction.success) {
            dispatch(success(transaction));
            dispatch(alertActions.success('Transactions Success'));
          } else {
            dispatch(failure(transaction.message));
            dispatch(alertActions.error(transaction.message));
            toast.error(transaction.message);
          }
        },
        (error) => {
          console.log('Transactions error main: ', error);
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() {
    return { type: entityConstants.BUSINESS_DETAILS_REQUEST };
  }
  function success(data: any) {
    return { type: entityConstants.BUSINESS_DETAILS_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: entityConstants.BUSINESS_DETAILS_FAILURE };
  }
}
function updetDataAction(req: any, refresh?: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(req));

    entityService
      .updetDataAction(req)
      .then((data) => {
        return data.json();
      })
      .then(
        (transaction) => {
          if (transaction.success) {
            dispatch(success(transaction));
            dispatch(alertActions.success('Transactions Success'));
            // if (typeof refresh == 'function') {
            refresh()
            // }
          } else {
            dispatch(failure(transaction.message));
            dispatch(alertActions.error(transaction.message));
            toast.error(transaction.message);
          }
        },
        (error) => {
          console.log('Transactions error main: ', error);
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(data: any) {
    return { type: entityConstants.UPDET_DATA_REQUEST };
  }
  function success(data: any) {
    return { type: entityConstants.UPDET_DATA_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: entityConstants.UPDET_DATA_FAILURE, error };
  }
}
function setCurrentStepAction(req: any, forwardata: any, setEnvironmentLoader: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(req));

    entityService
      .setCurrentStep(req)
      .then((data) => {
        return data.json();
      })
      .then(
        (transaction) => {
          if (transaction.success) {
            dispatch(success(transaction));
            dispatch(alertActions.success('Transactions Success'));


            if (forwardata == false) {
              setTimeout(() => {
                window.location.reload()

              }, 3000)
              setTimeout(() => {
                setEnvironmentLoader(false)
              }, 8000)

            } else {
              setTimeout(()=>{
                setEnvironmentLoader(false)
              },2500)
              
            }
            // if (typeof refresh == 'function') {
            // refresh()
            // }
          } else {
            dispatch(failure(transaction.message));
            dispatch(alertActions.error(transaction.message));
            toast.error(transaction.message);
            // setEnvironmentLoader(false)

          }
        },
        (error) => {
          console.log('Transactions error main: ', error);
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(data: any) {
    return { type: entityConstants.SET_CURRENT_STEP_REQUEST };
  }
  function success(data: any) {
    return { type: entityConstants.SET_CURRENT_STEP_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: entityConstants.SET_CURRENT_STEP_FAILURE, error };
  }
}

function getCurrentStepAction(setRenderGet: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    entityService
      .getCurrentStep()
      .then((data) => {
        return data.json();
      })
      .then(
        (transaction) => {
          if (transaction.success) {
            dispatch(success(transaction));
            setRenderGet(true)
            dispatch(alertActions.success('Transactions Success'));
          } else {
            var localStorageUser: any = localStorage.getItem("user")
            console.log(transaction.message ==
              'Your session has expired. Kindly log in again.' &&
              JSON.parse(localStorageUser)?.data?.entity?.id, "localStorageUser");

            if (
              transaction.message ==
              'Your session has expired. Kindly log in again.' &&
              JSON.parse(localStorageUser)?.data
            ) {
              localStorage.removeItem('user');
              localStorage.removeItem('Bulkdata');
              localStorage.removeItem('Bulkdatatwo');
              localStorage.removeItem('Bulk_Payment');
              localStorage.removeItem('token');
              localStorage.removeItem('tokentwo');
              localStorage.removeItem('user_verification');
              window.location.href = '/login';
              toast.error(transaction.message);
            }
            if (
              transaction.message !==
              'Your session has expired. Kindly log in again.'
            ) {
              dispatch(failure(transaction.message));
              dispatch(alertActions.error(transaction.message));
              toast.error(transaction.message);

            }
            else {
              //  window.location.href='/login'
            }
          }
        },
        (error) => {
          console.log('Transactions error main: ', error);
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() {
    return { type: entityConstants.GET_CURRENT_STEP_REQUEST };
  }
  function success(data: any) {
    return { type: entityConstants.GET_CURRENT_STEP_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: entityConstants.GET_CURRENT_STEP_FAILURE };
  }
}

function getDetailsForNewAPIAction(req: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(req));

    entityService
      .getDetailsForNewApi(req)
      .then((data) => {
        return data.json();
      })
      .then(
        (transaction) => {
          if (transaction.data) {
            dispatch(success(transaction));
            dispatch(alertActions.success('Transactions Success'));
          } else {
            dispatch(failure(transaction.message));
            dispatch(alertActions.error(transaction.message));
            toast.error(transaction.message);
          }
        },
        (error) => {
          console.log('Transactions error main: ', error);
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(data: any) {
    return { type: entityConstants.GET_DETAILS_FOR_NEW_API_REQUEST };
  }
  function success(data: any) {
    return { type: entityConstants.GET_DETAILS_FOR_NEW_API_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: entityConstants.GET_DETAILS_FOR_NEW_API_FAILURE };
  }
}

function saveBusinessDetailsAction(req: any,Entitysave:any,payload:boolean) {
  return (dispatch: (arg0: any) => void) => {
    dispatch(request(req));

    entityService
      .saveBusinessDetailsApi(req,payload)
      .then((response) => {
        return response.json();
      })
      .then(
        (entity) => {
          if (entity.success) {
            dispatch(success(entity?.success));
            dispatch(alertActions.success('Save Business details!'));
            Entitysave()
          } else {
            toast.error(entity?.data)
            dispatch(failure(entity.success));
            dispatch(alertActions.error(entity.message));
          }
        },
        (error) => {
          alert(error.toString());
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(entity: any) {
    return { type: entityConstants.SAVE_BUSINESS_DETAILS_REQUEST, entity };
  }
  function success(entity: any) {
    return { type: entityConstants.SAVE_BUSINESS_DETAILS_SUCCESS, entity };
  }
  function failure(error: any) {
    return { type: entityConstants.SAVE_BUSINESS_DETAILS_FAILURE, error };
  }
}
