import React, { FC, useEffect, useState } from 'react';
import { Box, Stepper, Step, StepLabel } from '@mui/material';
import PartnershipStepOne from './PartnershipStepOne';
import PartnershipStepTwo from './PartnershipStepTwo';
import '../Settings.scss';
import { Formik, FormikConfig, FormikHelpers, FormikValues } from 'formik';
import { getBase64 } from '../../../../../../../../../_helpers/common';
import { consumers } from 'stream';
import { useDispatch } from 'react-redux';
import { choiceActions } from '../../../../../../../../../_actions';
import { use } from 'i18next';
import { settingActions } from '../../../../../../../../../_actions/setting.actions';
// import { toast } from '@team-monite/ui-widgets-react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { error } from 'console';
import { authHeader } from '../../../../../../../../../_helpers';

interface Props extends FormikConfig<FormikValues> {
  children: React.ReactNode;
  stepOneInfo: any;
  stepTwoInfo: any;
  businessDocs: any;
  setSetpNumberAuth: any;
  updateStep: any;
  api: any;
  LoaderApi: any;
  entityType:any;
}

const PartnershipForm: FC<Props> = ({
  children,
  initialValues,
  stepOneInfo,
  stepTwoInfo,
  businessDocs,
  onSubmit,
  setSetpNumberAuth,
  updateStep,
  api,
  LoaderApi,
  entityType
}) => {
  const [stepNumber, setStepNumber] = useState(0);

  const [snapshot, setSnapshot] = useState(initialValues);
  const steps = React.Children.toArray(children) as React.ReactElement[];
  console.log(initialValues, snapshot, 'initialValues');

  const step = steps[stepNumber];
  const totalSteps = steps.length;

  const dispatch = useDispatch();
  const isLastStep = stepNumber === totalSteps - 1;

  const certificateLabel = ' Registration certificate';
  const partnershipLabel = ' Partnership deed';
  const kraPinLabel = 'Tax identification document';
  const mandateLabel = ' A clear mandate on the mode of operations';
  const [certificateFile, setCertificateFile] = useState(certificateLabel);
  const [partnershipFile, setPaternshipFile] = useState(partnershipLabel);
  const [mandateFile, setMandateFile] = useState(mandateLabel);
  const selfieLabel = 'Passport photo';
  const idFront = 'National ID- frontside';
  const idBackLabel = 'National ID- backside';
  const passportLabel = 'Passport';
  const kraLabel = 'Tax certificate';
  const [selfieFile, setSelfieFile] = useState(selfieLabel);
  const [idFrontFile, setIdFrontFile] = useState(idFront);
  const [idBackFile, setIdBackFile] = useState(idBackLabel);
  const [passportFile, setPassportFile] = useState(passportLabel);
  const [kraFile, setKraFile] = useState(kraLabel);
  const [kraPin, setKraPin] = useState(kraPinLabel);
  const [loader, setLoader] = useState(false);
  const [fileSize, setFileSize] = useState<any>('');
  const [invoiceImage, setinvoiceImage] = useState<any>('');
  const [shareholderLenthes, setShareHolderLenthes] = useState(false)
  const [setpsubmit, setSetpsubmit] = useState(false)
  const licenseLabel = 'License';
  const [licenseFile, setLicenseFile] = useState(licenseLabel);
  const shareHolderDocLabel: any = {
    selfieLabel: 'Passport photo',
    idFrontLabel: 'National ID- frontside',
    idBackLabel: 'National ID- backside',
    passportLabel: 'Passport',
    kraPinLabel: 'Tax identification document',
  };
  const navigate = useNavigate();

  const [shareHolderLabels, setShareHolderLabels] = useState<any>([
    { ...shareHolderDocLabel },
    { ...shareHolderDocLabel },
  ]);
  console.log(window?.location?.search, 'window.location.pathname');

  // const user = getUser()?.data ?? '';

  useEffect(() => {
    if (window?.location?.search.includes('step-3')) {
      setStepNumber(1);
    } else {
      setStepNumber(0);
    }
  }, [window?.location?.search]);
  useEffect(() => {
    if (!!businessDocs) {
      setKraFile(
        !!businessDocs.data?.data?.businessKraPinCertificate?.file_name
          ? businessDocs.data?.data?.businessKraPinCertificate?.file_name
          : kraLabel
      );

      setLicenseFile(
        !!businessDocs.data?.data?.license?.file_name
          ? businessDocs.data?.data?.license?.file_name
          : licenseLabel
      );
      setCertificateFile(
        !!businessDocs.data?.data?.businessCertificate
          ? businessDocs.data?.data?.businessCertificate?.file_name
          : certificateLabel
      );
      setPaternshipFile(
        !!businessDocs.data?.data?.partnershipDeed?.file_name
          ? businessDocs.data?.data?.partnershipDeed?.file_name
          : partnershipLabel
      );
      setMandateFile(
        !!businessDocs.data?.mandate ? businessDocs.data?.mandate : mandateLabel
      );

      console.log('businessDocs >>>> partnership', businessDocs);
      console.log(
        'businessDocs >>>> partnership',
        businessDocs.data?.data?.businessKraPinCertificate?.file_name
      );
      setSnapshot({
        ...initialValues,
        businessCertificate: businessDocs.data?.data?.businessCertificate?.url,
        cr: businessDocs.data?.data?.cr?.url,
        memorandum: businessDocs.data?.data?.memorandum?.url,
        resolution: businessDocs.data?.data?.resolution?.url,
        businessKraPinCertificate:
          businessDocs.data?.data?.businessKraPinCertificate?.url,
        partnershipDeed: businessDocs.data?.data?.partnershipDeed?.url,
      });
    }
  }, [businessDocs]);

  useEffect(() => {
    console.log('kraFile >>>>', kraFile);
  }, [kraFile]);

  console.log('******** formik values', initialValues);

  const handleAddNewPartner = () => {
    setShareHolderLabels([...shareHolderLabels, shareHolderDocLabel]);
  };

  const handleUpdateLabel = (index: any, fieldName: any, label: any) => {
    console.log('handleUpdateLabel >>>>', index, fieldName, label);
    console.log('labels before update >>>>', shareHolderLabels);
    const updatedLabels = [...shareHolderLabels];
    updatedLabels[index][fieldName + 'Label'] = label;

    console.log('updatedLabels >>>>', updatedLabels);
    setShareHolderLabels(updatedLabels);
  };

  const resetLabel = (index: any, fieldName: any) => {
    // Make a copy of the shareHolderLabels array
    const updatedLabels = [...shareHolderLabels];

    // Reset the label for the specified field name at the specified index
    updatedLabels[index][fieldName + 'Label'] =
      shareHolderDocLabel[fieldName + 'Label'];

    // Update the state with the modified labels
    setShareHolderLabels(updatedLabels);
  };

  const handleLoader = (value: boolean) => {
    setLoader(value);
  };
  const handleImage = (e: any, formik?: any, index?: any) => {
    if (e.target.files[0] !== null && e.target.files[0] !== undefined && e.target.files[0].size <= 1024 * 1024) {
      console.log('hitted');
      let field = e.target.name;
      let extension = e.target.files[0].name.split('.').pop();
      extension = extension.toLowerCase();
      if (e.target.files[0] !== null && e.target.files[0] !== undefined) {
        setFileSize(e.target.files[0].size);
        let title: any = null;
        let mediaType: any = null;
        if (field === 'businessCertificate') {
          mediaType = 'KYCF00016';
          setCertificateFile(e.target.files[0].name);
          title = field;
        } else if (field === 'partnershipDeed') {
          console.log('Getting in this file');
          mediaType = 'KYCF00017';
          setPaternshipFile(e.target.files[0].name);
          title = field;
        } else if (field === 'mandate') {
          mediaType = 'KYCF00014';
          setMandateFile(e.target.files[0].name);
          title = field;
        } else if (field.includes('selfieFile')) {
          handleUpdateLabel(index, 'selfie', e.target.files[0].name);
          title = field;
          mediaType = 'KYCF00006';
        } else if (field === 'license') {
          setLicenseFile(e.target.files[0].name);
          title = field;
          mediaType = 'NIOBI00009';
        } else if (field.includes('idFrontSideFile')) {
          handleUpdateLabel(index, 'idFront', e.target.files[0].name);
          title = field;
          mediaType = 'KYCF00001';
        } else if (field.includes('passportFile')) {
          console.log('here is passportFile');
          handleUpdateLabel(index, 'passport', e.target.files[0].name);
          title = field;
          mediaType = 'KYCF00001';
        } else if (field.includes('idBackSideFile')) {
          handleUpdateLabel(index, 'idBack', e.target.files[0].name);
          title = field;
          mediaType = 'KYCF00002';
        } else if (field.includes('businessKraPinCertificate')) {
          mediaType = 'KYCF00013';
          setKraFile(e.target.files[0].name);
          title = field;
        } else if (field.includes('kraPinFile')) {
          setKraPin(e.target.files[0].name);
          handleUpdateLabel(index, 'kraPin', e.target.files[0].name);
          title = field;
          mediaType = 'KYCF00009';
        }
        getBase64(e.target.files[0], (result: any) => {
          setinvoiceImage(result);
          if (typeof formik !== 'undefined') {
            formik.setFieldValue(field, result);
          }
          const payload = {
            title: title,
            document_type: '2',
            mediaBase64: result,
            type: 'business_documents',
            mediaType,
            contentType: extension === 'pdf' ? 'pdf' : 'image',
          };
          console.log('the payload is:', payload);
          dispatch(choiceActions.uploadMedia(payload, handleLoader) as any);

          if (field.includes('passportFile')) {
            const payload = {
              title: title,
              document_type: '1',
              mediaBase64: result,
              type: 'business_documents',
              mediaType: 'KYCF00002',
              contentType: extension === 'pdf' ? 'pdf' : 'image',
            };
            console.log('the payload is:', payload);
            dispatch(
              choiceActions.uploadMedia(payload, handleLoader, true) as any
            );
          }
        });
      }
    } else {
      toast.error("File size is too large. Please upload a file with max size of 1 mb.")
    }
  };

  const removeImage = (id: any) => {
    console.log('*******id*****', id);
    dispatch(settingActions.deleteDocuments(id) as any);
    setLoader(true);
    setTimeout(() => {
      dispatch(settingActions.getEntityDocuments() as any);
      setTimeout(() => {
        // setRenderEntity(true);
        setLoader(false);
      }, 2000);
    }, 4500);
  };

  const handleRemoveImage = (e: any, fileName: string, formik: any, index?: any) => {
    if (fileName !== null && fileName !== undefined) {
      console.log(fileName, 'fileName');

      switch (fileName) {
        case 'businessCertificate':
          setCertificateFile(certificateLabel);
          setinvoiceImage('');
          if (formik) formik.setFieldValue('businessCertificate', '');
          break;
        case 'license':
          setLicenseFile(licenseLabel);
          if (formik) formik.setFieldValue('license', '');
          setinvoiceImage('');
          break;
        case 'businessKraPinCertificate':
          setKraFile(kraLabel);
          setinvoiceImage('');
          if (formik) formik.setFieldValue('businessKraPinCertificate', '');
          break;
        case 'partnershipDeed':
          setPaternshipFile(partnershipLabel);
          setinvoiceImage('');
          if (formik) formik.setFieldValue('partnershipDeed', '');
          break;
        case 'mandate':
          setMandateFile(mandateLabel);
          setinvoiceImage('');
          if (formik) formik.setFieldValue('mandate', '');
          break;
        case 'selfie':
          resetLabel(index, 'selfie');
          formik.setFieldValue(`shareholders[${index}].selfieFile`, '');
          formik.setFieldValue(`shareholders[${index}].selfieFileName`, '');
          console.log(index, 'indexindex');

          setSelfieFile(selfieLabel);
          setinvoiceImage('');
          break;
        case 'idFront':
          resetLabel(index, 'idFront');
          if (formik)
            formik.setFieldValue(`shareholders[${index}].idFrontSideFile`, '');
          formik.setFieldValue(
            `shareholders[${index}].idFrontSideFileName`,
            ''
          );
          setIdFrontFile(idFront);
          setinvoiceImage('');
          break;
        case 'passport':
          resetLabel(index, 'passport');
          if (formik)
            formik.setFieldValue(`shareholders[${index}].passportFile`, '');
          formik.setFieldValue(`shareholders[${index}].passportFileName`, '');
          setPassportFile(passportLabel);
          setinvoiceImage('');
          break;
        case 'idBack':
          resetLabel(index, 'idBack');
          if (formik)
            formik.setFieldValue(`shareholders[${index}].idBackSideFile`, '');
          formik.setFieldValue(`shareholders[${index}].idBackSideFileName`, '');
          setIdBackFile(idBackLabel);
          setinvoiceImage('');
          break;
        case 'kra':
          resetLabel(index, 'kraPin');
          // if (formik)
          formik.setFieldValue(`shareholders[${index}].kraFile`, '');
          formik.setFieldValue(`shareholders[${index}].kraPinFileName`, '');
          console.log(index, 'indexindex');
          setKraFile(kraLabel);
          setinvoiceImage('');
          break;
        default:
          break;
      }
    }
    if (formik && e) {
      let field = e.target.name;
      formik.setFieldValue(field, '');
    }
  };
  const handleNext = (values: FormikValues) => {
    console.log('VVVVV values =========>', values);
    setSnapshot(values);
    setStepNumber((prevStepNumber) => prevStepNumber + 1);
  };

  const handleBack = (values: FormikValues) => {
    setSnapshot(values);
    setStepNumber((prevStepNumber) => prevStepNumber - 1);
  };

  const handleSubmit = async (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit;
    }
    if (isLastStep) {
      console.log('VVVVV values =========>', isLastStep, values);
      onSubmit(values, actions);
    } else {
      console.log('VVVVV values =========>', isLastStep, values);
      const saveNaw = () => {
        handleNext(values);
        if (stepNumber === 0 && api == '/v2/create-entity') {
          localStorage.setItem('registerStep', '3');
          setSetpNumberAuth(2);
          updateStep(2, true, navigate('/v2/document-upload?step-3'));
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      };

      // delete values.businessCertificate
      // delete values.cr
      // delete values.kraPinCertificate
      // delete values.memorandum
      // delete values.resolution
      // delete values.businessKraPinCertificate
      // delete values.partnershipDeed
      values.shareholders = []
      dispatch(
        choiceActions.saveBusinessDetails({ ...values }, () => {
          saveNaw();
          toast.success('Business details saved ');
        },3) as any
      );
      // actions.setTouched({});
    }
  };

  // const isSaveDisabled = () => {
  //   if (stepNumber === 0 && Object.values(stepOneInfo).some((x) => x === '')) {
  //     return true;
  //   }
  //   return false;
  // };

  // const isSubmitDisabled = () => {
  //   if (!isLastStep || Object.values(stepTwoInfo).some((x) => x === '')) {
  //     return true;
  //   }
  //   return false;
  // };

  // const handleChange = (event: any) => {
  //   setFormData({
  //     ...formData,
  //     [event.target.name]: event.target.value,
  //   });
  // };
  const uploadchaoic = (e: any, formik?: any, index?: any) => {
    if (e.target.files[0] !== null && e.target.files[0] !== undefined && e.target.files[0].size <= 1024 * 1024) {
      getBase64(e.target.files[0], (result: any) => {
        setinvoiceImage(result);

        let field = e.target.name;
        // let extension = e.target.files[0].name.split('.').pop();
        // extension = extension.toLowerCase();
        var data = { file: result }
        // console.log('media data in choice service', mediaData);
        const requestOptions: any = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', ...authHeader() },
          body: JSON.stringify(data),
        };
        // return response.json();
        handleLoader(true);
        fetch(`${process.env.REACT_APP_API_URL}/upload-file`,
          requestOptions
        ).then((res) => {
          return res.json();
        })
          .then((data) => {
            console.log(data, "ASDADaDdasddddsadd");
            handleLoader(false);
            if (data.success === true) {
              if (index == index && field.includes('selfieFile')) {
                handleUpdateLabel(index, 'selfie', e.target.files[0].name);
                if (formik)
                  formik.setFieldValue(
                    `shareholders[${index}].selfieFile`,
                    data?.file_id
                  );
                formik.setFieldValue(
                  `shareholders[${index}].selfieFileName`,
                  e.target.files[0].name
                );
              } else if (index == index && field.includes('idFrontSideFile')) {
                handleUpdateLabel(index, 'idFront', e.target.files[0].name);
                if (formik)
                  formik.setFieldValue(
                    `shareholders[${index}].idFrontSideFile`,
                    data?.file_id
                  );
                formik.setFieldValue(
                  `shareholders[${index}].idFrontSideFileName`,
                  e.target.files[0].name
                );
              } else if (index == index && field.includes('passportFile')) {
                console.log('here is passportFile');
                handleUpdateLabel(index, 'passport', e.target.files[0].name);
                if (formik)
                  formik.setFieldValue(
                    `shareholders[${index}].passportFile`,
                    data?.file_id
                  );
                formik.setFieldValue(
                  `shareholders[${index}].passportFileName`,
                  e.target.files[0].name
                );
              } else if (index == index && field.includes('idBackSideFile')) {
                handleUpdateLabel(index, 'idBack', e.target.files[0].name);
                if (formik)
                  formik.setFieldValue(
                    `shareholders[${index}].idBackSideFile`,
                    data?.file_id
                  );
                formik.setFieldValue(
                  `shareholders[${index}].idBackSideFileName`,
                  e.target.files[0].name
                );
              } else if (field.includes('kraPinFile')) {
                if (formik)
                  formik.setFieldValue(
                    `shareholders[${index}].kraPinFile`,
                    data?.file_id
                  );
                formik.setFieldValue(
                  `shareholders[${index}].kraPinFileName`,
                  e.target.files[0].name
                );
                handleUpdateLabel(index, 'kraPin', e.target.files[0].name);
              }
              toast.success('Upload successful');
            } else {
              toast.error(data.message);
            }
            // }
            console.log(index, "NewArrayNewArray");

          });
        // console.log(response,'sdffsffsfffff');

        // }
        // );
      })
    } else {
      toast.error("File size is too large. Please upload a file with max size of 1 mb.")
    }
  };
  return (
    <>
      {/* <button
        onClick={handleBack}
        className="back_btn"
        disabled={stepNumber === 0}
      >
        Back
      </button> */}
      <div className="complete-profile__heading">
        Please provide the following documents
      </div>
      <Formik
        initialValues={snapshot}
        onSubmit={handleSubmit}
        validationSchema={step.props.validationSchema}
        enableReinitialize
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            {stepNumber === 0 && (
              <>
                {console.log(formik, "formikformik211")
                }
                <PartnershipStepOne
                  formik={formik}
                  certificateFile={certificateFile}
                  certificateLabel={certificateLabel}
                  mandateFile={mandateFile}
                  mandateLabel={mandateLabel}
                  partnershipFile={partnershipFile}
                  partnershipLabel={partnershipLabel}
                  kraFile={kraFile}
                  kraLabel={kraLabel}
                  licenseFile={licenseFile}
                  licenseLabel={licenseLabel}
                  handleImage={handleImage}
                  handleRemoveImage={handleRemoveImage}
                  loader={loader}
                />
              </>
            )}
            {stepNumber === 1 && (
              <>
                {console.log(formik, 'formikformik211')}
                <PartnershipStepTwo
                  formik={formik}
                  idBackFile={idBackFile}
                  idBackLabel={idBackLabel}
                  idFrontFile={idFrontFile}
                  idFrontLabel={idFront}
                  kraFile={kraFile}
                  kraLabel={kraLabel}
                  selfieFile={selfieFile}
                  selfieLabel={selfieLabel}
                  passportFile={passportFile}
                  passportLabel={passportLabel}
                  handleImage={handleImage}
                  handleRemoveImage={handleRemoveImage}
                  shareHolderLabels={shareHolderLabels}
                  handleAddNewPartner={handleAddNewPartner}
                  loader={loader}
                  uploadchaoic={uploadchaoic}
                  setpsubmit={setpsubmit}
                />
              </>
            )}
            {/* <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={stepNumber === steps.length - 1}
          >
            {stepNumber === steps.length - 1 ? 'Submit' : 'Next'}
          </Button>
          {stepNumber > 0 && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleBack}
              sx={{ marginLeft: 8 }}
            >
              Back
            </Button>
          )}
        </Grid> */}
            <Box style={isLastStep == true ? { justifyContent: "center" } : {}} className="complete-profile__boxbtndisplay">
              {/* {stepNumber > 0 && (
                <button
                  className="complete-profile__goback"
                  onClick={() => handleBack(formik.values)}
                >
                  Back
                </button>
              )} */}
              {stepNumber === 0 && (
                <button type="submit" className="complete-profile__goback">
                  Save
                </button>
              )}
              <button
                type="submit"
                // onClick={async () => {
                //   const isValid = await formik.validateForm();
                //   console.log('isValid >>>>', isValid);
                //   if (!isValid) {
                //     handleSubmit(formik.values, formik);
                //     return;
                //   }

                // }}
                onClick={() => {
                  if (formik.values.shareholders.length > 0) {
                    setShareHolderLenthes(true);
                  } else {
                    setShareHolderLenthes(false);
                  }
                  if (isLastStep) {
                  setSetpsubmit(true)
                  }
                }}
                // style={{ width: "50%", fontSize: "14px" }}
                style={{ display: 'flex', alignItems: 'center', justifyContent: "center",width: "50%" }}
                className="complete-profile__submitbtnlist"
              >
                {LoaderApi == true && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                {isLastStep ? 'Proceed' : 'Proceed'}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};
export default PartnershipForm;
