import { OrderEnum } from "@team-monite/sdk-api";
import { Dropdown, DropdownMenuItem } from "@team-monite/ui-kit-react";
import { PayablesTable, PayableDetails } from '@team-monite/ui-widgets-react';
import React, { useCallback, useState, useEffect } from "react";
import { Button, Col, Collapse, Nav, Row, Tab, Tabs } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
// import { TopupModal } from "../Balance/components/topup-modal/Topup";
import PageHeader from "../PageHeader";
import "./Bill.scss";
import { DocumentForm } from "./components/document/Document";
import { PayDetailsModal } from "./components/PayDetails/PayDetailsModal";
import { useNavigate } from "react-router-dom";
import { order } from "../../../../_reducers/cart.reducer";
import Csvdata from './components/csvfile/csvlist';
import ApproveTable from "./components/approve/approveTable";

import { dashboardActions } from "../../../../_actions";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { entityActions } from "../../../../_actions/entity.actions";
import { getUser, isUserAuthenticated } from "../../../../_helpers";
import { selectAllCounterpartsListData } from "../../../../_reducers/counterparts.reducer";
import { counterpartsActions } from "../../../../_actions/counterparts.actions";
import { payablesActions } from "../../../../_actions/payables.actions";
import { selectAllPayablesListData } from "../../../../_reducers/payables.reducer";
export class HttpClient {
  static get() {
    return Promise.resolve(`buyPrice,buy_qty,sellPrice,sell_qty,enter,
          3.37,1,3.41,1,0
          3.33,1,3.37,1,0
          3.55,1,3.7,1,1
          3.23,1,3.27,1,0
          3.21,1,3.25,1,0`);
  }
}
export const BillsApproval = () => {
  const [isOpen, setIsOpen] = useState(false);
  var localUser: any = localStorage.getItem("user");

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/payment-journey`;
    navigate(path);
  };
  // console.log(props.data, 'props.data');

  const OptionalFields = {
    invoiceDate: true,
    suggestedPaymentDate: false,
    tags: true,
    iban: false,
    bic: false,
  }

  // const monite = new MoniteApp({
  //   apiUrl: "https://api.sandbox.monite.com/v1", // Or 'https://api.monite.com/v1' to use Production
  //   entityId: JSON.parse(localUser).data.hasEntity
  //     ? JSON.parse(localUser).data.entity.monite_id
  //     : "c2818ff7-1a9e-4d1e-9edd-86a7ca32c1a1", // Entity whose data you want to access
  //   token:
  //   JSON.parse(localUser).data.monite_access_token, // Access token (of an entity user or a partner)
  // });

  const PAYABLE_ID = "id";
  const datenew = new Date();
  // var datemitadd = (datenew + (30 * 60 * 1000))
  const [searchParams, setSearchParams] = useSearchParams();
  const [isApiData, setIsAPIData] = useState<any>(false);
  const [isPayApiData, setIsPayAPIData] = useState<any>(false);
  const [dateset, setDateset] = useState(new Date)
  const [payableId, setId] = useState<string | undefined>(undefined);
  const [listpopup, setPopup] = useState<any>(false)
  const [payableType, setType] = useState<any | undefined>(
    undefined
  );
  const [dataInCSV, setDataInCSV] = React.useState("");
  const [listcvdata, setUserJSON] = React.useState([])
  const [csvname, setCsvnamedata] = React.useState('bulkadd')
  console.log(csvname, 'csvnamecsvname');

  // const theme = createTheme({
  //     status: {
  //         danger: orange[500],
  //     },
  // });

  // const handleCSVUpload = (file) => {
  //     setCsvnamedata(file.name)
  //     Papa.parse(file, {
  //         header: true,
  //         complete: (results) => {
  //             console.log(results.data, 'dfgfgfdg');
  //             // var userJSON = userJSON.find(item => item.buyPrice === '');

  //             // console.log(result,'dfrtfdtftfd');
  //             // element.buyPrice
  //             // if (results.data === '') {
  //             //     break;
  //             //   }
  //             setUserJSON(results.data)
  //             // setListcsvdsts(results.data)
  //         },
  //     });
  // };
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // React.useEffect(() => {
  //     HttpClient.get().then((res) => setDataInCSV(res));
  // }, []);
  const [render, setRender] = useState(true);
  const [payablesList, setPayablesList] = useState<any>();

  // const [payableType, setType] = useState<any | undefined>(
  //   undefined
  // );

console.log(payablesList,'payablesList');

  const dispatch = useDispatch();
  const payablesListData = useSelector((state) =>
    selectAllPayablesListData(state)
  );
  console.log(payablesListData,'payablesListData');
  
  const changeTime = () => {
    const tokentwo: any = localStorage.getItem('tokentwo')
    console.log(new Date().getTime(), new Date(JSON.parse(tokentwo)).getTime(), new Date().getTime() - new Date(JSON.parse(tokentwo)).getTime())
    if (new Date().getTime() - new Date(JSON.parse(tokentwo)).getTime() >= 1800000) {
      console.log("here 30 minutes upper")
      setRender(false)
      dispatch(entityActions.getBilldata() as any)
    } else {
      console.log("here 30 minutes lower")
    }
    setTimeout(() => changeTime(), 1830000)
  }

  useEffect(() => {
    var localdata = localStorage.getItem('user')
    console.log(localdata, 'localdata');

    var dateset = new Date()
    const tokentwo: any = localStorage.getItem('tokentwo')
    changeTime()

  }, [render, isUserAuthenticated]);
  const counterpartsListData = useSelector((state) =>
    selectAllCounterpartsListData(state)
  );
  useEffect(() => {
    // dispatch(counterpartsActions.getUserCounterparts() as any);
    // setPayablesList(
    //   !!payablesListData.GETPAYABLES_SUCCESS ? (payablesListData?.GETPAYABLES_SUCCESS).sort(
    //     (a: any,b: any)=>
    //     b.id - a.id ) : payablesListData?.GETPAYABLES_SUCCESS
      
    //   );
    const popup = searchParams.get('popup');
    const BILLSBULK: any = searchParams.get('BILLSBULK')
    console.log(BILLSBULK, 'BILLSBULK');
    setCsvnamedata(BILLSBULK)

    console.log(popup, 'popup');



    if (popup == '1') {
      // console.log();
      setIsOpen(true)
      // setType(CounterpartType.ORGANIZATION)
    } else if (popup == '1') {

      // setType(CounterpartType.INDIVIDUAL)
    }
  }, [searchParams]);
  const id = searchParams.get(PAYABLE_ID);

  const onRowClick = (id: string) => {
    searchParams.set(PAYABLE_ID, id);
    setSearchParams(searchParams);
  };
 
  // const counterpart: any = counterpartsListData?.GETUSERCOUNTERPARTS_SUCCESS?.find(
  //   (ele: any) => ele.monite_counterpart_id === id
  // );
  // console.log(counterpart, 'counterpartcounterpart');
  console.log(counterpartsListData, 'counterpartsListData');

  

  const closeModal = () => {
    searchParams.delete(PAYABLE_ID);
    setSearchParams(searchParams);
  };

  // monite.api.payable
  //   .getList(OrderEnum.DESC) // Returns Promise<Response>
  //   .then((res) => {
  //     console.log(res,'resres');

  //     console.log(`Number of payables: ${res}`);
  //   });

  return (
  <>
    {!!localUser && <div className="bill-container">
      {/* {!isOpen && ( */}
      <div className="bill-container__payables">
        <PageHeader
          title="Approve Bills"
          extra={
          <div style={{height:"46px"}}></div>}
        />
        
          <div className={'height_two'}>
            <ApproveTable />
            </div> 
        </div>
        </div>}
    </>
  );
};

const selectTab = (key: any) => { };