import { Box, CircularProgress, Grid, Menu, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { counterpartsActions } from "../../../../_actions/counterparts.actions";
import { useDispatch, useSelector } from "react-redux";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import CategoriesTebal from "./components/CategoriesTebal";
import { Button, InputGroup, Modal, Form } from "react-bootstrap";
import PageHeader from "../PageHeader";
import { getUser } from "../../../../_helpers";
import styles from './components/CategoriesTebal.module.scss';
import { settingActions } from "../../../../_actions/setting.actions";
import { selectAllCounterpartsListData } from "../../../../_reducers/counterparts.reducer";
import { SkeletonDashboardV3 } from "../Skeleton/Skeleton";

const Categories = () => {
    const [pageneshn, setPagelist] = React.useState(1);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [delete_data, setDelete_data] = React.useState('')
    const [ideditdata, setIdeditdata] = React.useState('')
    const [loading, setLoading] = useState(false)
    const [totalCount, setTotalCount] = React.useState(10);
    const [datatabal, setdatatabal] = useState(
        [

        ]
    )
    const [showModal_cerete, setShow_cerete] = React.useState(false);
    const [fild_data, setFild_data] = React.useState("")
    const [model_edit, setModel_edit] = React.useState<any>('')
    const [validesan, setValidesan] = useState(false)
    const [renderUser, setRenderUser] = React.useState(true)
    const [errorMsg, setErrorMsg] = useState("")
    const [nameEdit, setNameEdit] = useState("")
    const [renderData, setRenderData] = useState(true)
    const [dataload, setDataload] = useState(false)
    const [rowId, setRowId] = useState('')
    const [selected, setSelected] = React.useState<any>([]);
    const [loader, setLoader] = useState(false)
    const [remove, setRemovedata] = React.useState(10);

    // const [idEmail, setIdEmail] = React.useState<any>('')

    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    const counterpartsListData = useSelector((state) =>
        selectAllCounterpartsListData(state)
    );
    const user = getUser()?.data ?? null;
    const handleClick_datat = (event: React.MouseEvent<HTMLButtonElement>, name: any, id: any) => {
        setAnchorEl(event.currentTarget);
        setNameEdit(name)
        setFild_data(name)
        setRowId(id)
    };
    const handleClose = () => {
        setAnchorEl(null);
        // setDelete_data("")
        // setIdeditdata("")
    };
    const getCategoriesList = () => {

    }
    const handleShow = () => {
        dispatch(counterpartsActions.DeleteCategories(rowId, setRenderData) as any);
    }

    const rejectSubmit = () => {
        const body = {
            "ids": selected
        }
        dispatch(counterpartsActions.DeleteBalkCategories(body, setRenderData) as any);
    }
    const handleShow_cerete = () => {
        setShow_cerete(true)

    }
    const Close = () => {
        setShow_cerete(false);
        setFild_data('')
        setValidesan(false)
        setErrorMsg('')
    };
    const CereteTeam = () => {
        setValidesan(true)
        if (!!fild_data && model_edit == "cerete") {
            var reqBody = {
                "name": fild_data
            }
            dispatch(counterpartsActions.cereteCategories(reqBody, setRenderData, Close, setErrorMsg) as any)
        } else if (!!fild_data && model_edit == "edit") {
            var reqBody = {
                "name": fild_data
            }
            dispatch(counterpartsActions.updateCategories(reqBody, rowId, setRenderData, Close, setErrorMsg) as any)
        }
    }
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    useEffect(() => {
        if (!!renderData) {
            setRenderData(false)
            dispatch(counterpartsActions.getAllCategories(setDataload, setLoader, pageneshn) as any);
        }
    }, [renderData, pageneshn])
    useEffect(() => {
        if (dataload && counterpartsListData?.GET_COUNTERPARTS_SUCCESS !== undefined) {
            setDataload(false)
            setdatatabal(counterpartsListData?.GET_COUNTERPARTS_SUCCESS?.data)
            console.log(counterpartsListData?.GET_COUNTERPARTS_SUCCESS,"counterpartsListData?.GET_COUNTERPARTS_SUCCESS");
            
            // setDatatebal(response.data.data.data);
            setTotalCount(counterpartsListData?.GET_COUNTERPARTS_SUCCESS?.total);
        }
    }, [dataload])
    return (
        <>
            <ThemeProvider theme={theme}>
                {/* {loader && <Box sx={{ display: 'flex', position: 'absolute', top: 150, bottom: 0, right: 0, left: 0, justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                    <CircularProgress color="success" />
                </Box>} */}
                <Grid container >
                    <Grid item md={12} style={{width:"100%"}}>
                        <PageHeader
                            title="Categories"
                            extra={
                                user?.role_name != 'Viewer' ?


                                    <Button color="#025041" onClick={() => {
                                        // setModelOpen(true) 
                                        setModel_edit("cerete")
                                        handleShow_cerete()

                                    }}
                                        className={styles.buttom_create} >
                                        Create New
                                    </Button> :

                                    <Button color="#025041" style={{ cursor: 'not-allowed' }} className={styles.buttom_create} >
                                        Create New
                                    </Button>

                            }
                        />
                    </Grid>

                    <Grid item md={12} className={styles.tebalContenar}>
                        <SkeletonDashboardV3
                            loader={loader}
                            height={'650px'}
                            width={'100%'}
                            element={
                                <CategoriesTebal
                                    datatabal={datatabal}
                                    loading={loading}
                                    setPagelist={setPagelist}
                                    pageneshn={pageneshn}
                                    totalCount={totalCount}
                                    handleClick_datat={handleClick_datat}
                                    setSelected={setSelected}
                                    selected={selected}
                                    rejectSubmit={rejectSubmit}
                                    setRenderData={setRenderData}
                                    setRemovedata={setRemovedata}
                                    remove={remove}
                                />}>

                        </SkeletonDashboardV3>
                    </Grid>
                </Grid>
                <Modal
                    show={showModal_cerete}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby='ModalHeader'
                    centered
                ><Modal.Body>
                        <Box className={styles.box_dialog_main}>
                            <Typography className={styles.Create_Team_heding}>{model_edit == "cerete" ? "Create New Category" : `Edit tag ”${nameEdit}”`}</Typography>
                            <div id="fast_name_inpurt_div" className="input-form-wrapper dot">
                                <label>Name</label>
                                <InputGroup className="doc-input-wrapper">
                                    <Form.Control
                                        className={

                                            'doc-form-input test valid'}
                                        placeholder="Name"
                                        value={fild_data}
                                        name='name'
                                        type="text"
                                        onChange={(e: any) => {
                                            setFild_data(e.target.value)
                                        }}
                                    />
                                </InputGroup>
                                {!!errorMsg ?
                                    <p className="error-message">
                                        {/* The name has already been taken. */}
                                        {errorMsg}
                                    </p> :
                                    !fild_data && validesan == true ?
                                        <p className="error-message">Please enter the team name</p>
                                        : ""}
                            </div>

                            <Box className={styles.box_btn_cencal} sx={{ justifyContent: "space-between", display: "flex" }}>
                                <Button className={styles.cerete_user} onClick={() => { Close() }}>
                                    Cancel
                                </Button>
                                <Button className={styles.savebtn} style={{ marginLeft: "0px" }}
                                    onClick={() => {
                                        CereteTeam()
                                    }}>{model_edit == "cerete" ? "Create" : "Update"}</Button>


                            </Box>
                        </Box></Modal.Body></Modal >
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem
                        // disabled={delete_data == "" ? true : false}
                        onClick={() => {
                            setModel_edit("edit")
                            handleShow_cerete()
                        }}
                        className='menuitem'>Edit</MenuItem>
                    <MenuItem
                        // disabled={ideditdata == "" ? true : false}
                        onClick={() => {
                            handleClose()
                            // handleClickOpendelog()
                            handleShow()
                            // deleteData()
                        }} className='menuitem' style={{ color: "#F87161" }}>Delete
                    </MenuItem>
                </Menu>
            </ThemeProvider>
        </>
    )
}
export default Categories;