import {
  Box,
  Button,
  createTheme,
  Grid,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { orange } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import { selectAllData } from "../../../_reducers/entity.reducer";
import { settingSelector } from "../../../_reducers/setting.reducer";
import { dispatchStore } from "../../../Store/store";
import { settingActions } from "../../../_actions/setting.actions";
import moment from "moment";
import { userActions } from "../../../_actions";

const AccountSetupCompleted = () => {
  const [documentsDetails, setDocumentsDetails] = useState<any>();
  const [render, setRender] = useState<any>(true);
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  // const navigate = useNavigate();
  const LogoutApi = () => {
    dispatchStore(
      userActions.logout("logout", "Logged out successfully") as any
    );
  };
  useEffect(() => {
    dispatchStore(settingActions.getEntityDocuments() as any);
  }, []);
  const isData = useSelector((state) => settingSelector(state));
  useEffect(() => {
    if (isData?.GETENTITYDOCUMENT_SUCCESS && render) {
      setDocumentsDetails(isData?.GETENTITYDOCUMENT_SUCCESS.data);
      setRender(false);
    }
  }, [isData.GETENTITYDOCUMENT_SUCCESS, render]);
  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid container justifyContent={"center"}>
          {/* <Grid item md={3}></Grid> */}
          <Grid item md={6}>
            <div className={styles.account_setup_main_box}>
              <div className={styles.column_box}>
                <CheckRounded />
                <p className={styles.title_text}>Account application submitted</p>
              </div>
              <div
                className={styles.conteanDav}
                style={{ justifyContent: "center", height: "100%" }}
              >
                <div className={styles.hedingPage}>
                  <Typography>Application timeline</Typography>
                </div>
                <Box className={styles.ConteanBox}>
                  <Box className={styles.content_flex_box}>
                    <Box
                      sx={{
                        width: "60%",
                      }}
                    >
                      <p className={styles.details_text}>
                        Account opening application Submitted on{" "}
                        {moment(documentsDetails?.created_at).format(
                            'DD-MMM-YYYY'
                          )}
                        {/* {moment(
                          documentsDetails?.created_at || new Date()
                        ).format("MMMM, DD,YYYY")} */}
                      </p>
                    </Box>
                    <UnChakbox />
                  </Box>
                  <Box className={styles.content_flex_box}>
                    <Box
                      sx={{
                        width: "40%",
                      }}
                    >
                      <p className={styles.details_text}>In review</p>
                      <p className={styles.details_text}>1-2 business days</p>
                    </Box>
                    <UnChakbox />
                  </Box>
                  <Box className={styles.content_flex_box}>
                    <Box
                      sx={{
                        width: "40%",
                      }}
                    >
                      <p className={styles.details_text}>Account ready</p>
                    </Box>
                    <UnChakbox color="#DDDDDD" />
                  </Box>
                </Box>
              </div>

              <p className={styles.description_text}>
                For any queries, reach out to our support team at{" "}
                <span>support@niobi.co</span>
              </p>
              <Button
                className={styles.logout_button}
                onClick={() => {
                  LogoutApi();
                }}
              >
                Logout
              </Button>
            </div>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
};

export default AccountSetupCompleted;

const UnChakbox = ({ color }: { color?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 1.6875C4.95 1.6875 1.6875 4.95 1.6875 9C1.6875 13.05 4.95 16.3125 9 16.3125C13.05 16.3125 16.3125 13.05 16.3125 9C16.3125 8.2125 16.2067 7.42838 15.9255 6.69712L15.012 7.59375C15.1245 8.04375 15.1881 8.49375 15.1881 9C15.1881 12.4312 12.4318 15.1875 9.00056 15.1875C5.56931 15.1875 2.8125 12.4312 2.8125 9C2.8125 5.56875 5.56875 2.8125 9 2.8125C10.6875 2.8125 12.2029 3.48412 13.2716 4.55288L14.0625 3.762C12.7688 2.46825 10.9688 1.6875 9 1.6875ZM15.345 4.095L9 10.4417L6.5925 8.03419L5.7825 8.8425L8.595 11.655L9 12.0409L9.405 11.6544L16.155 4.90444L15.345 4.09444V4.095Z"
      fill={color || "#025041"}
    />
  </svg>
);

const CheckRounded = () => {
  return (
    <svg
      width="47"
      height="46"
      viewBox="0 0 47 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.5 23C0.5 16.9 2.92321 11.0499 7.23654 6.73654C11.5499 2.42321 17.4 0 23.5 0C29.6 0 35.4501 2.42321 39.7635 6.73654C44.0768 11.0499 46.5 16.9 46.5 23C46.5 29.1 44.0768 34.9501 39.7635 39.2635C35.4501 43.5768 29.6 46 23.5 46C17.4 46 11.5499 43.5768 7.23654 39.2635C2.92321 34.9501 0.5 29.1 0.5 23ZM22.1875 32.844L35.4293 16.2901L33.0373 14.3765L21.7459 28.4863L13.748 21.8224L11.7853 24.1776L22.1875 32.8471V32.844Z"
        fill="#025041"
      />
    </svg>
  );
};
