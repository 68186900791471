import { alertActions } from '.';
import { choiceBankService } from '../_services/choiceBank.service';
import { ChoiceBankConstants } from '../_constants/choiceBank.constants';
// import toast, { Toaster } from 'react-hot-toast';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const choiceActions = {
  getEntityTypes,
  getOperatingModes,
  getIndustries,
  getGender,
  createBusinessAccount,
  verifyOtp,
  resendOtp,
  uploadMedia,
  getStoreOnboardingInfo,
  setBusiness,
  saveShareholder,
  saveBusinessDetails,
  getBusinessDetails,
  submitToBank,
  uploadchaoic
};

function getEntityTypes() {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    console.log('++++++getting here in choiceBankService+++++');
    choiceBankService
      .getBusinessTypes()
      .then((data) => {
        console.log('response in choice action:', data);
        return data.json();
      })
      .then(
        (response) => {
          console.log('response in choice action****:', response.data);
          dispatch(success(response.data));
        },
        (error) => {
          console.log('error in choice action:', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_GETTYPES_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: ChoiceBankConstants.CHOICE_GETTYPES_FAILURE };
  }
}

function getOperatingModes() {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    console.log('++++++getting here in choiceBankService+++++');
    choiceBankService
      .getOperatingModes()
      .then((data) => {
        console.log('response in choice action:', data);
        return data.json();
      })
      .then(
        (response) => {
          console.log('response in choice action****:', response.data);
          dispatch(success(response.data));
        },
        (error) => {
          console.log('error in choice action:', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_GETOPERATINGMODES_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: ChoiceBankConstants.CHOICE_GETOPERATINGMODES_FAILURE };
  }
}

function getBusinessDetails(userId: string) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    console.log('++++++getting here in choiceBankService+++++');
    choiceBankService
      .getBusinessDetails(userId)
      .then((data) => {
        console.log('response in choice action:', data);
        return data.json();
      })
      .then(
        (response) => {
          console.log('response in choice action****:', response.data);
          dispatch(success(response.data));
        },
        (error) => {
          console.log('error in choice action:', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_GET_DETAILS_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: ChoiceBankConstants.CHOICE_GET_DETAILS_FAILURE };
  }
}

function getIndustries() {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    console.log('++++++getting here in choiceBankService+++++');
    choiceBankService
      .getIndustries()
      .then((data) => {
        console.log('response in choice action:', data);
        return data.json();
      })
      .then(
        (response) => {
          console.log('response in choice action****:', response.data);
          dispatch(success(response.data));
        },
        (error) => {
          console.log('error in choice action:', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_GETINDUSTRIES_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: ChoiceBankConstants.CHOICE_GETINDUSTRIES_FAILURE };
  }
}

function getGender() {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    console.log('++++++getting here in choiceBankService+++++');
    choiceBankService
      .getGenders()
      .then((data) => {
        console.log('response in choice action:', data);
        return data.json();
      })
      .then(
        (response) => {
          console.log('response in choice action****:', response.data);
          dispatch(success(response.data));
        },
        (error) => {
          console.log('error in choice action:', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_GETGENDER_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: ChoiceBankConstants.CHOICE_GETGENDER_FAILURE };
  }
}

function createBusinessAccount(
  business: any,
  navigateCallback: () => void,
  setLoaderAPi: any,
  updateStep: any
) {
  return (dispatch: (arg0: any) => void) => {
    dispatch(request(business));
    console.log('Getting here now )))))');
    setLoaderAPi(true)
    choiceBankService
      .createBusiness(business)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success === true) {
            dispatch(success(response));
            // toast.success('Account setup request was successfully sent', {
            //   duration: 8000,
            // });
            setLoaderAPi(false);
            navigateCallback();
            // if (api == '/v2/create-entity') {
            //   setSetpNumberAuth(3);
            //   updateStep(3,true);
            // }
          } else {
            console.log('response Getting in error:', response.data);
            dispatch(failure(response.message));
            setLoaderAPi(false);
            toast.error(
              response?.response.msg
                ? response?.response?.msg
                : response.message
            );

            if (response.message === 'Validation errors') {
              var fieldarray = [
                'businessName',
                'businessCerNum',
                'firstName',
                'middleName',
                'lastName',
                'gender',
                'birthday',
                'idNumber',
                'kraPin',
                'businessAddress',
                'kinFullName',
                'kinRelationship',
                'kinCountryCode',
                'kinMobile',
                ' businessIndustry',
                'specifyIndustry',
                'userId',
                'countryCode',
                'businessType',
                'operatingMode',
                'otpType',
                'mobile',
                'email',
                'shareholders',
              ];
              fieldarray.map((field: any) => {
                if (!!response.data[field]) {
                  response.data[field].map((i: string) => {
                    toast.error(i);
                  });
                }
              });
            }
          }
        },
        (error) => {
          console.log('error in choice action:', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request(business: any) {
    return {
      type: ChoiceBankConstants.CHOICE_CREATEBUSINESS_REQUEST,
      business,
    };
  }
  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_CREATEBUSINESS_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: ChoiceBankConstants.CHOICE_CREATEBUSINESS_FAILURE, error };
  }
}

function saveBusinessDetails(business: any, navigateCallback: () => void,payload:any) {
  return (dispatch: (arg0: any) => void) => {
    dispatch(request(business));
    choiceBankService
      .saveBusiness(business,payload)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success === true) {
            dispatch(success(response));
            toast.success('Business details saved successfully');
            navigateCallback();
          } else {
            console.log('response Getting in error:', response.data);
            dispatch(failure(response.message));
            if(response?.data=="Could not write to socket"){
              toast.error(response?.data)
            }
            toast.error(
              response?.response.msg
                ? response?.response?.msg
                : response.message
            );

            if (response.message === 'Validation errors') {
              var fieldarray = [
                'businessName',
                'businessCerNum',
                'firstName',
                'middleName',
                'lastName',
                'gender',
                'birthday',
                'idNumber',
                'kraPin',
                'businessAddress',
                'kinFullName',
                'kinRelationship',
                'kinCountryCode',
                'kinMobile',
                ' businessIndustry',
                'specifyIndustry',
                'userId',
                'countryCode',
                'businessType',
                'operatingMode',
                'otpType',
                'mobile',
                'email',
                'shareholders',
              ];
              fieldarray.map((field: any) => {
                if (!!response.data[field]) {
                  response.data[field].map((i: string) => {
                    toast.error(i);
                  });
                }
              });
            }
          }
        },
        (error) => {
          console.log('error in choice action:', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request(business: any) {
    return {
      type: ChoiceBankConstants.CHOICE_CREATEBUSINESS_REQUEST,
      business,
    };
  }
  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_CREATEBUSINESS_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: ChoiceBankConstants.CHOICE_CREATEBUSINESS_FAILURE, error };
  }
}

function verifyOtp(payload: any, navigateCallback: () => void,setLoader:any) {
  return (dispatch: (arg0: any) => void) => {
    dispatch(request(payload));
    choiceBankService
      .verifyOtp(payload)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success === true) {
            dispatch(success(response));
            // toast.success('Business Account Created successfully');
            navigateCallback();
            setLoader(false)
          } else {
            dispatch(failure(response.message));
            setLoader(false)
            toast.error(
              'Otp verification failed try again or request a new OTP'
            );
            if (response.message === 'Validation errors') {
              var fieldarray = ['otpCode', 'businessId', 'id'];
              fieldarray.map((field: any) => {
                if (!!response.data[field]) {
                  response.data[field].map((i: string) => {
                    toast.error(i);
                  });
                }
              });
            }
          }
        },
        (error) => {
          console.log('error in choice action:', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request(business: any) {
    return {
      type: ChoiceBankConstants.CHOICE_VERIFYOTP_REQUEST,
      business,
    };
  }
  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_VERIFYOTP_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: ChoiceBankConstants.CHOICE_VERIFYOTP_FAILURE, error };
  }
}

function uploadMediaToChoice(payload: any, navigateCallback: () => void) {
  return (dispatch: (arg0: any) => void) => {
    dispatch(request(payload));
    choiceBankService
      .verifyOtp(payload)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success === true) {
            dispatch(success(response));
            toast.success('Business Account Created successfully');
            navigateCallback();
          } else {
            dispatch(failure(response.message));
            toast.error(
              'Otp verification failed try again or request a new OTP'
            );
            if (response.message === 'Validation errors') {
              var fieldarray = ['otpCode', 'businessId', 'id'];
              fieldarray.map((field: any) => {
                if (!!response.data[field]) {
                  response.data[field].map((i: string) => {
                    toast.error(i);
                  });
                }
              });
            }
          }
        },
        (error) => {
          console.log('error in choice action:', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request(business: any) {
    return {
      type: ChoiceBankConstants.CHOICE_VERIFYOTP_REQUEST,
      business,
    };
  }
  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_VERIFYOTP_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: ChoiceBankConstants.CHOICE_VERIFYOTP_FAILURE, error };
  }
}

function saveShareholder(payload: any) {
  return (dispatch: (arg0: any) => void) => {
    dispatch(request(payload));
    choiceBankService
      .saveShareholder(payload)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success === true) {
            dispatch(success(response));
            // toast.success('Business Account Created successfully');
          } else {
            dispatch(failure(response.message));
            // toast.error(
            //   'Otp verification failed try again or request a new OTP',
            //   { duration: 8000 }
            // );
            if (response.message === 'Validation errors') {
              var fieldarray = [
                'idType',
                'firstName',
                'lastName',
                'idNumber',
                'gender',
                'countryCode',
                'mobile',
                'kraPin',
                'idFrontSideFile',
                'idFrontSideFileType',
                'idBackSideFile',
                'idBackSideFileType',
                'selfieFile',
                'selfieFileType',
                'kraPinFile',
                'kraPinFileType',
              ];
              fieldarray.map((field: any) => {
                if (!!response.data[field]) {
                  response.data[field].map((i: string) => {
                    toast.error(i);
                  });
                }
              });
            }
          }
        },
        (error) => {
          console.log('error in choice action:', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request(business: any) {
    return {
      type: ChoiceBankConstants.CHOICE_VERIFYOTP_REQUEST,
      business,
    };
  }
  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_VERIFYOTP_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: ChoiceBankConstants.CHOICE_VERIFYOTP_FAILURE, error };
  }
}

function resendOtp(payload: any, navigateCallback: () => void) {
  return (dispatch: (arg0: any) => void) => {
    dispatch(request(payload));
    choiceBankService
      .resendOtp(payload)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success === true) {
            dispatch(success(response));
            toast.success('OTP resent successfully kindly check your email');
            navigateCallback();
          } else {
            dispatch(
              failure(
                response.response ? response.response.msg : response.message
              )
            );
            toast.error(response.response.msg);
            if (response.message === 'Validation errors') {
              var fieldarray = ['otpCode', 'businessId', 'id'];
              fieldarray.map((field: any) => {
                if (!!response.data[field]) {
                  response.data[field].map((i: string) => {
                    toast.error(i);
                  });
                }
              });
            }
          }
        },
        (error) => {
          console.log('error in choice action:', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request(business: any) {
    return {
      type: ChoiceBankConstants.CHOICE_RESEND_OTP_REQUEST,
      business,
    };
  }
  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_RESEND_OTP_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: ChoiceBankConstants.CHOICE_RESEND_OTP_FAILURE, error };
  }
}

function uploadMedia(
  payload: any,
  handleLoader: (value: boolean) => void,
  isPassport?: boolean
) {
  return (dispatch: (arg0: any) => void) => {
    dispatch(request(payload));
    handleLoader(true);
    choiceBankService
      .uploadMedia(payload)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          handleLoader(false);
          if (response.success === true) {
            dispatch(success(response));
            if (!isPassport) {
              toast.success('Upload successful');
            }
          } else {
            dispatch(failure(response.message));
            toast.error(response.message);
            if (response.message === 'Validation errors') {
              var fieldarray = [
                'title',
                'document_type',
                'mediaBase64',
                'mediaType',
                'contentType',
              ];
              fieldarray.map((field: any) => {
                if (!!response.data[field]) {
                  response.data[field].map((i: string) => {
                    toast.error(i);
                  });
                }
              });
            }
          }
        },
        (error) => {
          console.log('error in choice action:', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request(business: any) {
    return {
      type: ChoiceBankConstants.CHOICE_UPLOADMEDIA_REQUEST,
      business,
    };
  }
  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_UPLOADMEDIA_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: ChoiceBankConstants.CHOICE_UPLOADMEDIA_FAILURE, error };
  }
}
function uploadchaoic(
  payload: any,
  handleLoader: (value: boolean) => void,
  isPassport?: boolean
) {
  return (dispatch: (arg0: any) => void) => {
    dispatch(request(payload));
    handleLoader(true);
    choiceBankService
      .uploadchaoic(payload)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          handleLoader(false);
          if (response.success === true) {
            dispatch(success(response));
            if (!isPassport) {
              toast.success('Upload successful');
            }
          } else {
            dispatch(failure(response.message));
            toast.error(response.message);
            if (response.message === 'Validation errors') {
              var fieldarray = [
                'title',
                'document_type',
                'mediaBase64',
                'mediaType',
                'contentType',
              ];
              fieldarray.map((field: any) => {
                if (!!response.data[field]) {
                  response.data[field].map((i: string) => {
                    toast.error(i);
                  });
                }
              });
            }
          }
        },
        (error) => {
          console.log('error in choice action:', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request(business: any) {
    return {
      type: ChoiceBankConstants.CHOICE_UPLOAD_FILE_REQUEST,
      business,
    };
  }
  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_UPLOAD_FILE_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: ChoiceBankConstants.CHOICE_UPLOAD_FILE_FAILURE, error };
  }
}
function getStoreOnboardingInfo(payload: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    console.log('++++++getting here in choiceBankService+++++');
    console.log('onboarding info data:', payload);
    choiceBankService
      .getStoreOnboardingInfo(payload)
      .then((data) => {
        console.log('response in choice action:', data);
        return data.json();
      })
      .then(
        (response) => {
          console.log('response in choice action****:', response.data);
          dispatch(success(response.data));
        },
        (error) => {
          console.log('error in choice action:', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_ONBOARDING_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: ChoiceBankConstants.CHOICE_ONBOARDING_FAILURE };
  }
}

function setBusiness(business: any) {
  return {
    type: ChoiceBankConstants.CHOICE_BUSINESS_INFO_REQUEST,
    payload: business,
  };
}

function submitToBank(navigateCallback: () => void) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    choiceBankService
      .submitToBank()
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          dispatch(success(response.data));
          navigateCallback();
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function success(data: any) {
    return { type: ChoiceBankConstants.CHOICE_SUBMIT_TO_BANK_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: ChoiceBankConstants.CHOICE_SUBMIT_TO_BANK_FAILURE };
  }
}
