import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import './Invite_users.scss';
import * as Yup from 'yup';
import { Formik, FieldArray, ErrorMessage } from 'formik';
import { Box } from '@mui/material';
import Invited_Successfully from './Invited_Successfully';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { settingActions } from '../../../../../../../_actions/setting.actions';
import { settingSelector } from '../../../../../../../_reducers/setting.reducer';
import axios from 'axios';
import RolesPopup from './RolesPopup';
import {
  getUser,
  isUserAuthenticated,
  authHeader,
  getProperties,
} from '../../../../../../../_helpers';
import amplitude from 'amplitude-js';
import posthog from 'posthog-js';

interface Props {
  onCancel: any;
}

export const Comingsoon = (props: Props) => {
  const [inputList, setInputList] = useState([
    { firstName: '', lastName: '', team: '' },
  ]);
  const [openpopup, setOpenpopup] = useState(false);
  const [addnewteab, setAddnewteb] = useState(false);
  const [render, setRender] = useState(true);
  const [renderSubmit, setRenderSubmit] = useState(true);
  const [datalist, setDatalist] = useState<any>([]);
  const [renderUser, setRenderUser] = React.useState(true);
  const [userData, setUserData] = React.useState<any>([]);
  const dataEntity: any = useSelector((state) => settingSelector(state));
  const dispatch = useDispatch();
  const user = getUser()?.data ?? '';

  const properties = getProperties();
  React.useEffect(() => {
    if (isUserAuthenticated() && render) {
      setRender(false);
      dispatch(settingActions.getTeamDocuments() as any);
      dispatch(settingActions.getRolsDocuments() as any);
    }
  }, [render, isUserAuthenticated]);
  React.useEffect(() => {
    if (renderUser && dataEntity?.TEAMDOCUMENT_SUCCESS !== undefined) {
      setUserData(dataEntity.TEAMDOCUMENT_SUCCESS);
      setRenderUser(false);
    }
  }, [dataEntity, renderUser]);
  React.useEffect(() => {
    if (renderSubmit && dataEntity?.ROLSDATA_SUCCESS !== undefined) {
      setDatalist(dataEntity?.ROLSDATA_SUCCESS);
      setRenderSubmit(false);
    }
  }, [dataEntity, renderSubmit]);
  const onCancel = () => {
    props.onCancel();
  };
  const formInitailData = {
    users: inputList,
  };
  const validationSchema = Yup.object().shape({
    users: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string()
          .email('Invalid Email Address')
          .required('*Please enter the email address')
          .test(
            'Unique Email',
            'Email already in use', // <- key, message            
            function (value) {
              return new Promise((resolve, reject) => {
                const requestOptions = {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    // ...authHeader(),
                  },
                };
                console.log(value, "casffsdfsfsfff");

                axios
                  .get(
                    `${process.env.REACT_APP_API_URL}/check-email/${value}`,
                    requestOptions
                  )
                  .then((res) => {
                    resolve(true);
                  })
                  .catch((error) => {
                    resolve(false);
                    // if (error.response.data.content === "The email has already been taken.") {
                    //     resolve(false);
                    // }
                  });
              });
            }
          ),
        // team: Yup.number().required("*Team is required"),
        lastName: Yup.string().required("Please enter the user's role"),
      })
    ),
  });
  const [age, setAge] = React.useState('');
  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };
  const ADDLISY = () => {
    setInputList([
      ...inputList,
      { firstName: '', lastName: '', team: '' },
    ]);
  };
  const [showPopup, setShowPopup] = React.useState(false);
  const setRoles = () => {
    setShowPopup(true);
  }
  const handleClose2 = () => {
    setShowPopup(false);
  }
  const handleRemoveClicktwo = (index: any) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  console.log(inputList, "usersusers123");

  return (
    <>
      {openpopup == true ? (
        <Invited_Successfully />
      ) : (
        <div className="addinvitelisttwo">
          <p className="addinvitetext">Invite Users</p>
          <p className="addtestdiscer">
            Add your teammates to current account by entering emails and
            selecting roles for each of them.
          </p>
          <Formik
            enableReinitialize
            initialValues={formInitailData}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setOpenpopup(true);
              const ReqBody: any = { 
                data: [],
                director_email:user?.email
              };
              console.log(values, "bodybodybody");

              inputList?.map((values: any) => {
                const body = {
                  email: values.firstName,
                  role_id: values.lastName,
                  team_id: Number(values.team),
                };
                ReqBody.data.push(body);
              });

              dispatch(settingActions.getInviteDocuments(ReqBody) as any);
              const event = 'New User Invited';
              const eventProperties = {
                userId:
                  !!properties && !!properties.user_id
                    ? properties.user_id
                    : null,
                userName:
                  !!properties && !!properties.name ? properties.name : null,
                email:
                  !!properties && !!properties.email ? properties.email : null,
                pageUrl: window.location.href,
              };

              amplitude.getInstance().logEvent(event, eventProperties);
              posthog.capture(event, eventProperties);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <>
                {console.log(errors, values, "errorserrors")
                }
                <Form onSubmit={handleSubmit}>
                  <FieldArray
                    name="users"
                    render={(arrayHelpers) => {
                      const users = inputList;
                      return (
                        <>
                          <Box className="box_list_maintwo">
                            <p className="emaillebal">Email address</p>
                            <p className="emaillebal">Team</p>
                            <p className="emaillebal">User’s role</p>
                          </Box>
                          {users
                            ? users.map((user: any, index: any) => {
                              console.log(user, "useruser123");

                              return (
                                <div className="mainform">
                                  <Box className="box_list_main">
                                    <Box className="box_list">
                                      <input
                                        type="text"
                                        className="text-field"
                                        name={`users.${index}.firstName`}
                                        onChange={(e: any) => {
                                          // const newdata = users;
                                          // newdata[index].firstName =
                                          //   e.target.value;
                                          // setInputList(newdata);
                                          let text =
                                            e.target.value;
                                          var newArray = [];
                                          for (
                                            let j = 0;
                                            j <
                                            inputList.length;
                                            j++
                                          ) {
                                            const element =
                                              inputList[j];
                                            if (index == j) {
                                              user.firstName = text;
                                              newArray.push(
                                                JSON.parse(
                                                  JSON.stringify(
                                                    user
                                                  )
                                                )
                                              );
                                            } else {
                                              newArray.push(
                                                JSON.parse(
                                                  JSON.stringify(
                                                    element
                                                  )
                                                )
                                              );
                                            }
                                          }
                                          setInputList(
                                            newArray
                                          );
                                        }}
                                      />
                                      <ErrorMessage
                                        name={`users.${index}.firstName`}
                                        component="div"
                                        className={'addnewdaddaa'}
                                      />
                                    </Box>
                                    <Box className="box_list">
                                      <Form.Select
                                        style={{ fontSize: '11px' }}
                                        onChange={(e: any) => {
                                          // const newdata = users;
                                          // newdata[index].team =
                                          //   e.target.value;
                                          // setInputList(newdata);
                                          let text =
                                            e.target.value;
                                          var newArray = [];
                                          for (
                                            let j = 0;
                                            j <
                                            inputList.length;
                                            j++
                                          ) {
                                            const element =
                                              inputList[j];
                                            if (index == j) {
                                              user.team = text;
                                              newArray.push(
                                                JSON.parse(
                                                  JSON.stringify(
                                                    user
                                                  )
                                                )
                                              );
                                            } else {
                                              newArray.push(
                                                JSON.parse(
                                                  JSON.stringify(
                                                    element
                                                  )
                                                )
                                              );
                                            }
                                          }
                                          setInputList(
                                            newArray
                                          );
                                        }}
                                        className={'doc-form-input'}
                                      >
                                        <option selected value="">
                                          Select team
                                        </option>
                                        {userData?.map(
                                          (
                                            arrayElement: any,
                                            index: number
                                          ) => {
                                            return (
                                              <option
                                                value={arrayElement.id}
                                                key={index + 1}
                                              >
                                                {arrayElement.name}
                                              </option>
                                            );
                                          }
                                        )}
                                      </Form.Select>
                                      {/* <ErrorMessage
                                        name={`users.${index}.team`}
                                        component="div"
                                        className={'addnewdaddaa'}
                                      /> */}
                                    </Box>
                                    <Box className="box_list">
                                      <Autocomplete
                                        autoHighlight
                                        className="addautosert"
                                        options={datalist}
                                        onChange={(
                                          event,
                                          listid,
                                          reason,
                                          details
                                        ) => {
                                          // const newdata = users;
                                          // if (listid) {
                                          //   newdata[index].lastName = listid.id;
                                          // } else {
                                          //   newdata[index].lastName = ''; // or null, depending on your default value
                                          // }

                                          // console.log('new data: ', listid);
                                          // setInputList(newdata);
                                          let text =
                                            !!listid ? listid.id : '';
                                          var newArray = [];
                                          for (
                                            let j = 0;
                                            j <
                                            inputList.length;
                                            j++
                                          ) {
                                            const element =
                                              inputList[j];
                                            if (index == j) {
                                              user.lastName = text;
                                              newArray.push(
                                                JSON.parse(
                                                  JSON.stringify(
                                                    user
                                                  )
                                                )
                                              );
                                            } else {
                                              newArray.push(
                                                JSON.parse(
                                                  JSON.stringify(
                                                    element
                                                  )
                                                )
                                              );
                                            }
                                          }
                                          setInputList(
                                            newArray
                                          );
                                        }}
                                        getOptionLabel={(option: any) =>
                                          option.name
                                        }
                                        renderOption={(props, option) => (
                                          <Box
                                            className="addnewowenwr"
                                            component="li"
                                            style={{ display: 'block' }}
                                            {...props}
                                          >
                                            <div className="newonewtext">{option.name}</div>
                                          </Box>
                                        )}
                                        renderInput={(params) => (
                                          <TextField {...params} />
                                        )}
                                      />

                                      <ErrorMessage
                                        name={`users.${index}.lastName`}
                                        component="div"
                                        className={'addnewdaddaa'}
                                      />
                                    </Box>
                                  </Box>
                                  <div className="addbtninput" style={{ display: "flex" }}>
                                    {users.length - 1 === index && (
                                      <Button
                                        id={'adduserbtn'}
                                        className="complete-profile__ADDBTNHHA"
                                        onClick={ADDLISY}
                                      >
                                        + Add Another User
                                      </Button>
                                    )}
                                    <Button
                                      id={'rolepermissions'}
                                      className="complete-profile__ADDBTNHHA"
                                      style={{ marginLeft: "50%" }}
                                      onClick={setRoles}
                                    >
                                      View roles and permissions
                                    </Button>
                                  </div>
                                </div>
                              );
                            })
                            : null}
                        </>
                      );
                    }}
                  />
                  {showPopup == true ?
                    <RolesPopup show={true} onClose={handleClose2} roles={datalist} />

                    : ''}
                  <div className="seve_and_create_button">
                    <Button
                      variant="primary"
                      id="two_list_go_bank"
                      onClick={() => {
                        window.location.href = '/settings/team';
                      }}
                      className="payDetails-modal__btn-row__white"
                    >
                      Go Back
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      id={'addinvitebtn'}
                      className="payDetails-modal__btn-row__green "
                    >
                      Invite Users
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};
interface CountryType {
  label: string;
  Name: string;
  labeltwo: string;
  labeltew: string;
  namedata2: string;
  namedata1: string;
  namedata3: string;
  namedata4: string;
  namedata5: string;
  namedata6: string;
  namedata7: string;
  namedata8: string;
  namedata9: string;
  namedata10: string;
}
