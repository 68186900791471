import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import './Topup.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllBalanceLIstData } from '../../../../../../_reducers/balance.reducer';
import { balanceActions } from '../../../../../../_actions/balance.actions';
import { getProperties, isUserAuthenticated } from '../../../../../../_helpers';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { getKESFormate } from '../../../../../../_helpers/common';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField, Box, IconButton, CircularProgress, Tooltip } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import amplitude from 'amplitude-js';
import posthog from 'posthog-js';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  closeModel: any;
  onSaveBank: any;
  selectedMethod_two: any;
  setDesFild:any;
  bankDetails?: any | null;
}
const UploadIcon = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.67773 14V16C1.67773 16.5304 1.88845 17.0391 2.26352 17.4142C2.63859 17.7893 3.1473 18 3.67773 18H15.6777C16.2082 18 16.7169 17.7893 17.0919 17.4142C17.467 17.0391 17.6777 16.5304 17.6777 16V14M4.67773 6L9.67773 1L14.6777 6M9.67773 1V13"
      stroke="#B8B8B8"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const BankVia = (props: Props) => {
  const [render, setRender] = useState(true);
  const [bankDetails, setBankDetails] = useState<any>();
  const [bankDetailsRender, setBankDetailsRender] = useState(true);
  const [bankResponse, setBankResponse] = useState<any>();
  const dispatch = useDispatch();
  const [bankType, setBankType] = useState<any>([
    {
      id: 1,
      bank_id: 1,
      key: 'Pesalink',
      description: 'Credits within 1 hour',
      created_at: null,
      updated_at: null,
    },
    {
      id: 2,
      bank_id: 1,
      key: 'RTGS',
      description: 'Credits within 2-3 hours',
      created_at: null,
      updated_at: null,
    },
    {
      id: 3,
      bank_id: 1,
      key: 'EFT',
      description: 'Credits within 2-3 business days',
      created_at: null,
      updated_at: null,
    },
    {
      id: 4,
      bank_id: 1,
      key: 'Swift',
      description: 'Credits within 2-4 business days',
      created_at: null,
      updated_at: null,
    },
  ]);
  const [entity, setEntity] = useState<any>({
    amount: '',
    payment_channel: '',
    payment_reference: '',
  });
  const [invoicefile, setinvoicefile] = useState('No document uploaded');
  const [invoiceImage, setinvoiceImage] = useState<any>('');
  const[loading,setloading]=useState(false)
  const data = useSelector((state) => selectAllBalanceLIstData(state));
  const validationSchema = Yup.object().shape({
    amount: Yup.string().required('Please enter the amount.'),
    payment_channel: Yup.string().required('Please select the payment channel'),
    payment_reference: Yup.string().required(
      'Please enter the payment reference'
    ),
    created_at: Yup.string().required('Please enter the transaction date'),
  });
  useEffect(() => {
    if (isUserAuthenticated() && render) {
      setRender(false);
      setloading(true);
      dispatch(
        balanceActions.getBankDetailsById({
          id: 1,
          type: props.selectedMethod_two,
        }) as any
      );
      setTimeout(() => {
        setBankDetailsRender(true);
      }, 4000);
      setloading(false);
    }
  }, [render, isUserAuthenticated]);

  useEffect(() => {
    if (bankDetailsRender && data?.GET_BANK_DETAILS_ID_SUCCESS !== undefined) {
      setBankDetailsRender(false);
      setBankDetails(data.GET_BANK_DETAILS_ID_SUCCESS.data);
      setBankType(data.GET_BANK_DETAILS_ID_SUCCESS.data.methods);
    }
  }, [data, bankDetailsRender, props.selectedMethod_two]);



  const closeModel = () => {
    props.closeModel();
  };

  const onSaveBank = () => {
    props.onSaveBank();
  };
  const numberMask = createNumberMask({
    prefix: '',
    suffix: '', // This will put the dollar sign at the end, with a space.
  });
  const getBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const properties = getProperties();
  const eventProperties = {
    userId: !!properties && !!properties.user_id ? properties.user_id : null,
    userName: !!properties && !!properties.name ? properties.name : null,
    email: !!properties && !!properties.email ? properties.email : null,
    pageUrl: window.location.href,
  };

  console.log(bankResponse, 'bankResponse');
  const handleSubmit = (e: any) => {
    console.log(e, 'handleSubmit');
    if (!!e && !!e.amount && !!e.payment_channel && !!e.payment_reference) {
      const req: any = {
        payment_method_id: parseInt(e.payment_channel),
        amount: parseInt(e.amount.split(',').join('')),
        payment_reference: e.payment_reference,
        transaction_date: e.created_at,
        currency: props?.selectedMethod_two,
      };
      if (!!invoiceImage) {
        req.file = invoiceImage;
        req.file_name = invoicefile;
      }
      console.log(req, 'reqBank');
      const event = 'New Top Up via Bank Request Submitted';
      amplitude.getInstance().logEvent(event, eventProperties);
      posthog.capture(event, eventProperties);
      setloading(true)
      dispatch(balanceActions.savePaymentDetails(req) as any);
      onSaveBank();
    }
  };
  const handleImage = (e: any) => {
    setinvoicefile(e.target.files[0].name);
    getBase64(e.target.files[0], (result: any) => {
      setinvoiceImage(result);
    });
  };

  const theme = createTheme({
    palette: {
      secondary: {
        main: '#025041',
      },
      primary: {
        main: '#025041',
      },
    },
    status: {
      danger: 'red',
    },
  });

console.log("bankDetails ==>", props?.bankDetails?.user_currency);


 var bankTypeOptionsOne = [
    {
      id: 2,
      bank_id: 1,
      key: 'RTGS',
      description: 'Credits within 2-3 hours',
      created_at: null,
      updated_at: null,
    },
    {
      id: 3,
      bank_id: 1,
      key: 'EFT',
      description: 'Credits within 2-3 business days',
      created_at: null,
      updated_at: null,
    },
    {
      id: 4,
      bank_id: 1,
      key: 'Swift',
      description: 'Credits within 2-4 business days',
      created_at: null,
      updated_at: null,
    },
    {
      id: 5,
      bank_id: 1,
      key: 'Internal',
      description: 'Credits within 2-3 hours',
      created_at: null,
      updated_at: null,
    },
 ]
 var bankTypeOptionsTwo = [
    {
      id: 2,
      bank_id: 1,
      key: 'RTGS',
      description: 'Credits within 2-3 hours',
      created_at: null,
      updated_at: null,
    },
    {
      id: 3,
      bank_id: 1,
      key: 'EFT',
      description: 'Credits within 2-3 business days',
      created_at: null,
      updated_at: null,
    },
    {
      id: 4,
      bank_id: 1,
      key: 'Swift',
      description: 'Credits within 2-4 business days',
      created_at: null,
      updated_at: null,
    },
    {
      id: 5,
      bank_id: 1,
      key: 'Internal',
      description: 'Credits within 2-3 hours',
      created_at: null,
      updated_at: null,
    },
 ]
  

  return (
    <>
      <ThemeProvider theme={theme}>
      <Box className="CloseContenar">
              <IconButton onClick={closeModel}>
                <CloseIcon />
              </IconButton>
            </Box>
        <div className="topup-modal text-center modal-lg">
        
          <Modal.Title className="text-center">Top Up via Bank</Modal.Title>
        </div>
        <div className="topup-modal2__description">
          <p>Top up your balance via bank using the following details:</p>
        </div>
        {loading == true && <Box sx={{ display: 'flex', position: 'absolute', top: 150, bottom: 0, right: 0, left: 0, justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
            <CircularProgress color="success" />
        </Box>}
        <div className="topup-modal2__table">
          {!!bankDetails && !!bankDetails.account_name && (
            <div className="d-flex b-bot">
              <div className="left">Account name</div>
              <div className="right">
                {props.bankDetails 
                  ? props.bankDetails.account_name
                  : ''}
              </div>
            </div>
          )}

          <div className="d-flex b-bot">
            <div className="left">Account number</div>
            <div className="right">
              {props.bankDetails
                ? props.bankDetails.account_number
                : ''}
            </div>
          </div>
          <div className="d-flex b-bot">
            <div className="left">Account currency</div>
            <div className="right">
              {props?.bankDetails
                ? props?.bankDetails?.user_currency
                : ''}
            </div>
          </div>
          <div className="d-flex b-bot">
            <div className="left">Bank name</div>
            <div className="right">
              {props.bankDetails ? props.bankDetails.name : ''}
            </div>
          </div>
          <div className="d-flex b-bot">
            <div className="left">Bank branch</div>
            <div className="right">
              {props.bankDetails
                ? props.bankDetails.branch_name
                : ''}
            </div>
          </div>
          <div className="d-flex b-bot">
            <div className="left">Bank branch code</div>
            <div className="right">
              {props.bankDetails
                ? props.bankDetails.branch_code
                : ''}
            </div>
          </div>
          <div className="d-flex b-bot">
            <div className="left">Bank code</div>
            <div className="right">
              {props.bankDetails
                ? props.bankDetails.bank_code
                : ''}
            </div>
          </div>
          <div className="d-flex b-bot">
            <div className="left">Bank Swift code</div>
            <div className="right">
              {props.bankDetails
                ? props.bankDetails.bank_swift_code
                : ''}
            </div>
          </div>
          {!!bankDetails && !!bankDetails.choice_user && bankDetails.choice_user === "true"
                  ? 
                  <div className="d-flex">
            <div className="left">Reference Number
              <Tooltip title="Please remember to add this reference number on all bank deposits">
                <Button style={{background: "#ffffff", outlineColor: "#ffffff", borderColor: "#ffffff"}}>
                  <InfoIcon></InfoIcon>
                </Button>
             </Tooltip>
            </div>  
            <div className="right">
            {!!bankDetails && !!bankDetails.choice_account_number 
                  ? bankDetails.choice_account_number
                  : ''}
            </div>
          </div>
                  : ''} 
        </div>
        <div className="topup-modal2__description">
          <p className="m-4 text-center">
            Fill in the payment details to complete transaction.
          </p>
        </div>
        <div className="topup-modal2__table">
          <Formik
            initialValues={entity}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              isSubmitting,
              handleChange,
              values,
              handleBlur,
            }) => (
              <div className="company-details-container__wrapper">
                <Form>
                  <div
                    id="aDSaDaDadDddd"
                    className="user-detail-container__wrapper topupbank"
                  >
                    <div className="first-last-name">
                      <div className="input-form-wrapper m-10 mb-5 dot">
                        <label>Amount</label>
                        <MaskedInput
                          mask={numberMask}
                          className={
                            'text-field field-enable' +
                            (errors.amount && touched.amount
                              ? ' is-invalid'
                              : '')
                          }
                          placeholder="Amount"
                          name="amount"
                          onChange={(e) => {
                            const kesValue = getKESFormate(e.target.value);
                            const obj = e;
                            console.log(kesValue, 'kesValue', values);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          aria-describedby="basic-addon3"
                        />
                        <ErrorMessage
                          name="amount"
                          component="div"
                          className="invalid-feedback red"
                        />
                      </div>
                      <div className="input-form-wrapper m-10 dot">
                        <label>Payment channel</label>

                        <Autocomplete
                          autoHighlight
                          options={props?.bankDetails?.user_currency === 'KES' ? bankType : bankTypeOptionsOne}
                          sx={{
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              border: '1px solid #025041',
                              boxShadow: '0 0 0 4px #02504133',
                            },
                          }}
                          id="autoCompaliat_styles"
                          color="primary"
                          className={
                            'autoCompaliat_styles' +
                            (errors.payment_channel && touched.payment_channel
                              ? ' is-invalid'
                              : '')
                          }
                          getOptionLabel={(option: any) => option.key}
                          onChange={(event, listid, reason, details) => {
                            values.payment_channel = listid.id;
                            props?.setDesFild(listid?.description)
                          }}
                          renderOption={(props: any, option: any) => (
                            <Box
                              className="optionsmain"
                              component="li"
                              style={{ display: 'block' }}
                              {...props}
                            >
                              <div className="">{option.key}</div>
                              <span className="description">
                                {option.description}
                              </span>
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField name="payment_channel" {...params} />
                          )}
                        />
                        <ErrorMessage
                          name="payment_channel"
                          component="div"
                          className="invalid-feedback red"
                        />
                      </div>
                    </div>
                    <div className="first-last-name">
                      <div className="input-form-wrapper m-10 dot">
                        <label>Payment reference</label>
                        <Field
                          name="payment_reference"
                          type="text"
                          placeholder="Payment reference"
                          className={
                            'text-field field-enable' +
                            (errors.payment_reference &&
                            touched.payment_reference
                              ? ' is-invalid'
                              : '')
                          }
                        />
                        <ErrorMessage
                          name="payment_reference"
                          component="div"
                          className="invalid-feedback red"
                        />
                      </div>
                      <div className="input-form-wrapper m-10">
                        <label className="complete-profile__upload mb-0">
                          <label>Upload supporting document</label>
                          <div className="complete-profile__upload__label">
                            <span className="complete-profile__upload__txt">
                              {invoicefile}
                            </span>
                            <UploadIcon />
                          </div>
                          <input
                            type="file"
                            name="invoice_record"
                            onChange={handleImage}
                            onBlur={handleBlur}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="first-last-name">
                      <div className="input-form-wrapper m-10 dot">
                        <label>Transaction date</label>
                        <Field
                          name="created_at"
                          type="date"
                          placeholder="dd/mm/yyyy"
                          className={
                            'text-field field-enable' +
                            (errors.created_at && touched.created_at
                              ? ' is-invalid'
                              : '')
                          }
                        />
                        <ErrorMessage
                          name="created_at"
                          component="div"
                          className="invalid-feedback red"
                        />
                      </div>
                    </div>
                    <div
                      id="SAdfafafasdasD"
                      style={{ marginTop: '20px' }}
                      className="setting-modal__btn-row d-flex flex-center justify-content-center align-items-center gap-10"
                    >
                      
                      <div  className="edrgrezgeff">
                        <Button
                        style={{textTransform:"capitalize"}}
                          id="adadadaddddd"
                          type="submit"
                          className="complete-profile__submitbtnlist"
                        >
                             {loading == true && (
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                        )}
                          {' '}
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </ThemeProvider>
    </>
  );
};

const InfoIcon = () => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 11 11"
      fill="none"
  >
      <path d="M5.3333 7.50039V4.90039" stroke="#025041" stroke-linecap="round" />
      <path
          d="M5.33333 3.16654C5.57265 3.16654 5.76666 3.36055 5.76666 3.59987C5.76666 3.83919 5.57265 4.0332 5.33333 4.0332C5.094 4.0332 4.89999 3.83919 4.89999 3.59987C4.89999 3.36055 5.094 3.16654 5.33333 3.16654Z"
          fill="#025041"
      />
      <path
          d="M3.16666 1.5798C3.82512 1.19886 4.57261 0.998833 5.33333 1.00001C7.72662 1.00001 9.66665 2.94004 9.66665 5.33333C9.66665 7.72663 7.72662 9.66666 5.33333 9.66666C2.94003 9.66666 1 7.72663 1 5.33333C1 4.54423 1.21103 3.80367 1.5798 3.16667"
          stroke="#025041"
          stroke-linecap="round"
      />
  </svg>
);