import { Box, Typography } from '@mui/material';
import './Document_details.scss';
import { useEffect } from 'react';
import moment from "moment";
import { SkeletonDashboardV3 } from '../../../../../../../Skeleton/Skeleton';

const Document_details = (props: any) => {
    console.log(props, "propsprops");
    useEffect(() => {
        var trans_cat = [];

        if (!!props) {
            console.log(props, "propsprops");
            if (props?.data?.payable) {

            }

            // for (let index = 0; index < element.unified_transaction_categories.length; index++) {
            // const records = element.unified_transaction_categories[index];

            // }
        }
    }, [props])
    return (
        <>
        {/* props.Loader */}
            <div className='main_dic_contenar'>
                <SkeletonDashboardV3 width={'100%'} loader={props.Loader}
                    element={
                        <>
                            <div className='box_div_conteanararaa'>
                                <div>
                                    <Typography className='Typography_Documentdetails_text'>
                                        Document details
                                    </Typography>
                                    <div className='main_div_divfooter'>
                                        <div className='row_text_conteann'>
                                            <Typography className='fast_text_lineen'>Payee</Typography>
                                            <Typography className='two_text_conteanana'>{props?.data?.payable?.bulk_payment?.counterpart_name}</Typography>
                                        </div>
                                        <div className='row_text_conteann'>
                                            <Typography className='fast_text_lineen'>Invoice Number</Typography>
                                            <Typography className='two_text_conteanana'>{props?.data?.payable?.document_id}</Typography>
                                        </div>
                                        <div className='row_text_conteann'>
                                            <Typography className='fast_text_lineen'>Invoice Date</Typography>
                                            <Typography className='two_text_conteanana'>
                                                {moment(props?.data?.payable?.issued_at).format('DD-MMM-YYYY')}</Typography>
                                        </div>
                                        <div className='row_text_conteann'>
                                            <Typography className='fast_text_lineen'>Due Date</Typography>
                                            <Typography className='two_text_conteanana'>{moment(props?.data?.payable?.due_date).format('DD-MMM-YYYY')}</Typography>
                                        </div>
                                        {props?.data?.payable?.line_items?.length == 0 ?
                                            <div className='row_text_conteann'>
                                                <Typography className='fast_text_lineen'>Description</Typography>
                                                <Typography className='two_text_conteanana'>{props?.data?.payable?.description}</Typography>
                                            </div> : ""}
                                        <div className='row_text_conteann'>
                                            <Typography className='fast_text_lineen'>Categories</Typography>
                                            <Typography className='two_text_conteanana'>
                                                {props?.data?.payable?.bulk_payment?.categories?.map((data: any, index: any) => {
                                                    console.log(index, "indexindex");

                                                    return (
                                                        <>
                                                            {`${data.name} ${index == props?.data?.payable?.bulk_payment?.categories?.length - 1 ? "" : ","} `}
                                                        </>
                                                    )
                                                })}
                                            </Typography>
                                        </div>

                                        {/* <div className='row_text_conteann'>
                                <Typography className='fast_text_lineen'>Submitted by</Typography>
                                <Typography className='two_text_conteanana'>{props?.data?.payable?.submitter?.name}</Typography>
                            </div> */}
                                    </div>
                                </div>
                            </div>
                            {props?.data?.payable?.recurring_job == null ? "" :
                                <div style={{ marginTop: "6px" }} className='box_div_conteanararaa'>
                                    <div>
                                        <Typography className='Typography_Documentdetails_text'>
                                            Recurring intervals
                                        </Typography>
                                        <div className='main_div_divfooter_Recurring'>
                                            <div className='row_text_conteann_hafsize'>
                                                <Typography className='fast_text_lineen'>Interval</Typography>
                                                <Typography className='two_text_conteanana' style={{ fontWeight: "bold" }}>{props?.data?.payable?.recurring_job?.type} on {props?.data?.payable?.recurring_job?.type == "Weekly" ? props?.data?.payable?.recurring_job?.day :
                                                    props?.data?.payable?.recurring_job?.type == "Yearly" ? `${props?.data?.payable?.recurring_job?.every} ${props?.data?.payable?.recurring_job?.month}` : props?.data?.payable?.recurring_job?.every}</Typography>
                                            </div>
                                            <div className='row_text_conteann_hafsize_End_date'>
                                                <Typography className='fast_text_lineen'>End date</Typography>
                                                <Typography className='two_text_conteanana' style={{ fontWeight: "bold" }}>{moment(props?.data?.payable?.recurring_job?.end_date).format('DD-MMMM-YYYY')}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            {props?.data?.payable?.line_items == null || props?.data?.payable?.line_items?.length == 0 ? "" :
                                <div style={{ marginTop: "6px" }} className='box_div_conteanararaa'>
                                    <div>
                                        <Typography className='Typography_Documentdetails_text'>
                                            Line items
                                        </Typography>
                                        <div className='main_lineitem'>
                                            <div className='main_div_other_div'>
                                                <div className='main_div_name' style={{ width: "20%" }}>
                                                    <div className='typo_topdivv'>
                                                        <Typography>Item name</Typography>
                                                    </div>
                                                </div>
                                                <div className='main_div_name' style={{ width: "14%", textAlign: "center" }}>
                                                    <div className='typo_topdivv'>
                                                        <Typography>Unit price</Typography>
                                                    </div>
                                                </div>
                                                <div className='main_div_name' style={{ width: "14%", textAlign: "center" }}>
                                                    <div className='typo_topdivv'>
                                                        <Typography>Quantity</Typography>
                                                    </div>
                                                </div>
                                                <div className='main_div_name' style={{ width: "20%" }}>
                                                    <div className='typo_topdivv'>
                                                        <Typography>Description</Typography>
                                                    </div>
                                                </div>
                                                <div className='main_div_name' style={{ width: "14%" }}>
                                                    <div className='typo_topdivv'>
                                                        <Typography>VAT</Typography>
                                                    </div>
                                                </div>
                                                <div className='main_div_name' style={{ width: "18%" }}>
                                                    <div className='typo_topdivv'>
                                                        <Typography>Amount</Typography>
                                                    </div>
                                                </div>
                                            </div>

                                            {props?.data?.payable?.line_items.map((data: any, index: any) => {
                                                {
                                                    console.log((((data.total / 100) * data.quantity) - (data.subtotal / 100)), data.total, data.subtotal, "sfszffzsffff");
                                                }
                                                return (
                                                    <div className='main_div_other_div'>
                                                        <div className='main_div_name' style={{ width: "20%" }}>
                                                            <div className='typo_des_datatata'>
                                                                <Typography>{data.name}</Typography>
                                                            </div>
                                                        </div>
                                                        <div className='main_div_name' style={{ width: "14%", textAlign: "center" }}>
                                                            <div className='typo_des_datatata'>
                                                                <Typography>{props?.data?.data?.currency} {data.total / 100}</Typography>
                                                            </div>
                                                        </div>
                                                        <div className='main_div_name' style={{ width: "14%", textAlign: "center" }}>
                                                            <div className='typo_des_datatata'>
                                                                <Typography>{data.quantity}</Typography>
                                                            </div>
                                                        </div>
                                                        <div className='main_div_name' style={{ width: "20%" }}>
                                                            <div className='typo_des_datatata'>
                                                                <Typography>{data.description}</Typography>
                                                            </div>
                                                        </div>
                                                        <div className='main_div_name' style={{ width: "14%" }}>
                                                            <div className='typo_des_datatata'>


                                                                <Typography>{data?.tax == 'Exempt' ? "Exempt" : `${data?.tax / 100}%`}</Typography>
                                                            </div>
                                                        </div>
                                                        <div className='main_div_name' style={{ width: "18%" }}>
                                                            <div className='typo_des_datatata'>
                                                                <Typography>{props?.data?.data?.currency} {data.subtotal / 100}</Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </div>

                                </div>}
                            <div style={{ marginTop: "6px" }} className='box_div_conteanararaa'>
                                <div>
                                    <Typography className='Typography_Documentdetails_text'>
                                        Payment details
                                    </Typography>
                                    <div className='main_div_divfooter'>
                                        <div className='row_text_conteann'>
                                            <Typography className='fast_text_lineen'>Subtotal</Typography>
                                            <Typography className='two_text_conteanana'>{props?.data?.data?.currency} {props?.data?.payable?.subtotal / 100}</Typography>
                                        </div>
                                        <div className='row_text_conteann'>
                                            <Typography className='fast_text_lineen'>VAT</Typography>
                                            <Typography className='two_text_conteanana'> {props?.data?.payable?.vat == 'Exempt' ? 'Exempt' : `${props?.data?.data?.currency} ${props?.data?.payable?.vat ? props?.data?.payable?.vat / 100 : 0}`}</Typography>
                                        </div>
                                        <div className='row_text_conteann'>
                                            <Typography className='fast_text_lineen'>Total</Typography>
                                            <Typography className='two_text_conteanana'>{props?.data?.data?.currency} {props?.data?.payable?.amount / 100}</Typography>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div style={{ marginTop: "6px" }} className='box_div_conteanararaa'>
                                <div>
                                    <Typography className='Typography_Documentdetails_text'>
                                        Bill lifecycle
                                    </Typography>

                                    <div className='Bill_lifecyclediv'>
                                        {props?.data?.payable?.creator?.name ?
                                            <>
                                                <div className='conteanBill_lifecycl'>
                                                    <div className='clsTypographydiv'>
                                                        <div className='Typography_icon'>
                                                            <DotIcon />
                                                            <Typography className='clsTypographyheding'>Created by</Typography>
                                                        </div>
                                                        <Typography className='clsTypographyname'>{props?.data?.payable?.creator?.name}</Typography></div>
                                                    <div className='Submitted_datedev'><Typography className='TypographySubmittedheding'>Creation date</Typography>
                                                        <Typography className='dateTypography'>{moment(props?.data?.payable?.created_at).format('DD-MMM-YYYY')}</Typography></div>
                                                </div>
                                                {!!props?.data?.payable?.submitter?.name || !!props?.data?.payable?.canceller?.name || !!props?.data?.payable?.rejector?.name || !!props?.data?.payable?.approver?.name || !!props?.data?.payable?.payer?.name ?
                                                    <div className='bottum_spees' style={{ top: "29px" }}></div> : ""}
                                            </>
                                            : ""}
                                        {props?.data?.payable?.submitter?.name ?
                                            <>
                                                <div className='conteanBill_lifecycl'>
                                                    <div className='clsTypographydiv'>
                                                        <div className='Typography_icon'>
                                                            <DotIcon />
                                                            <Typography className='clsTypographyheding'>
                                                                Submitted by</Typography>
                                                        </div>
                                                        <Typography className='clsTypographyname'>{props?.data?.payable?.submitter?.name}</Typography></div>
                                                    <div className='Submitted_datedev'><Typography className='TypographySubmittedheding'>Submitted date</Typography>
                                                        <Typography className='dateTypography'>{moment(props?.data?.payable?.submitted_at).format('DD-MMM-YYYY')}</Typography></div>
                                                </div>
                                                {!!props?.data?.payable?.canceller?.name || !!props?.data?.payable?.rejector?.name || !!props?.data?.payable?.approver?.name || !!props?.data?.payable?.payer?.name ?
                                                    <div className='bottum_spees' style={{ top: "58px" }}></div> : ""}</>
                                            : ""}
                                        {props?.data?.payable?.canceller?.name ?
                                            <>
                                                <div className='conteanBill_lifecycl'>
                                                    <div className='clsTypographydiv'>
                                                        <div className='Typography_icon'>
                                                            <DotIcon />
                                                            <Typography className='clsTypographyheding'>
                                                                Cancelled by</Typography>
                                                        </div>
                                                        <Typography className='clsTypographyname'>{props?.data?.payable?.canceller?.name}</Typography></div>
                                                    <div className='Submitted_datedev'>
                                                        <Typography className='TypographySubmittedheding'>Cancellation date</Typography>
                                                        <Typography className='dateTypography'>{moment(props?.data?.payable?.cancelled_at).format('DD-MMM-YYYY')}</Typography></div>
                                                </div>
                                                {!!props?.data?.payable?.rejector?.name || !!props?.data?.payable?.approver?.name || !!props?.data?.payable?.payer?.name ?
                                                    <div className='bottum_spees' style={{ top: "58px" }}></div> : ""}</> : ""}
                                        {props?.data?.payable?.rejector?.name ?
                                            <>
                                                <div className='conteanBill_lifecycl'>
                                                    <div className='clsTypographydiv'>
                                                        <div className='Typography_icon'>
                                                            <DotIcon />
                                                            <Typography className='clsTypographyheding'>
                                                                Rejected by</Typography>
                                                        </div>
                                                        <Typography className='clsTypographyname'>{props?.data?.payable?.rejector?.name}</Typography></div>
                                                    <div className='Submitted_datedev'>
                                                        <Typography className='TypographySubmittedheding'>Rejection date</Typography>
                                                        <Typography className='dateTypography'>{moment(props?.data?.payable?.rejected_at).format('DD-MMM-YYYY')}</Typography></div>
                                                </div>
                                                {props?.data?.payable?.approver?.name || !!props?.data?.payable?.payer?.name ?
                                                    <div className='bottum_spees' style={{ top: "94px" }}></div> : ""}</> : ""}

                                        {props?.data?.payable?.approver?.name ?
                                            <>
                                                <div className='conteanBill_lifecycl'>
                                                    <div className='clsTypographydiv'>
                                                        <div className='Typography_icon'>
                                                            <DotIcon />
                                                            <Typography className='clsTypographyheding'>
                                                                Approved by</Typography>
                                                        </div>
                                                        <Typography className='clsTypographyname'>{props?.data?.payable?.approver?.name}</Typography></div>
                                                    <div className='Submitted_datedev'>
                                                        <Typography className='TypographySubmittedheding'>Approval date</Typography>
                                                        <Typography className='dateTypography'>{moment(props?.data?.payable?.approved_at).format('DD-MMM-YYYY')}</Typography></div>
                                                </div>
                                                {!!props?.data?.payable?.payer?.name ?
                                                    <div className='bottum_spees' style={{ top: "94px" }}></div> : ""}</> : ""}
                                        {props?.data?.payable?.payer?.name ?
                                            <div className='conteanBill_lifecycl'>
                                                <div className='clsTypographydiv'>
                                                    <div className='Typography_icon'>
                                                        <DotIcon />
                                                        <Typography className='clsTypographyheding'>
                                                            Paid by</Typography>
                                                    </div>
                                                    <Typography className='clsTypographyname'>{props?.data?.payable?.payer?.name}</Typography></div>
                                                <div className='Submitted_datedev'>
                                                    <Typography className='TypographySubmittedheding'>Payout date</Typography>
                                                    <Typography className='dateTypography'>{moment(props?.data?.payable?.paid_at).format('DD-MMM-YYYY')}</Typography></div>
                                            </div> : ""}
                                    </div>
                                    {/* <div className="cls_main_div_bill">
                            {props?.data?.payable?.submitter?.name ?
                                <div className="cls_withe_porsann">
                                    <div className='main_porsan_adafafaf'>
                                        <div className='main_dixv_kdjdhdhd'>
                                            <Typography className='typo_text_kryrry'>Submitted by</Typography>
                                            <Typography className='main_div_conteananana'>{props?.data?.payable?.submitter?.name}</Typography>
                                        </div>
                                        <div className='main_dixv_kdjdhdhd'>
                                            <Typography className='typo_text_kryrry'>Submission date</Typography>
                                            <Typography className='main_div_conteananana'>{moment(props?.data?.payable?.submitted_at).format('YYYY-MM-DD')}</Typography>
                                        </div>
                                    </div>
                                </div> : ""}
                            {props?.data?.payable?.canceller?.name ?
                                <div className="cls_withe_porsann">

                                    <div className='main_porsan_adafafaf'>
                                        <div className='main_dixv_kdjdhdhd'>
                                            <Typography className='typo_text_kryrry'>Cancelled by</Typography>
                                            <Typography className='main_div_conteananana'>{props?.data?.payable?.canceller?.name}</Typography>
                                        </div>
                                        <div className='main_dixv_kdjdhdhd'>
                                            <Typography className='typo_text_kryrry'>Cancellation date</Typography>
                                            <Typography className='main_div_conteananana'>{moment(props?.data?.payable?.cancelled_at).format('YYYY-MM-DD')}</Typography>
                                        </div>
                                    </div>
                                </div> : ""}
                        </div>

                        <div style={{ marginTop: "7px" }} className="cls_main_div_bill">
                            {props?.data?.payable?.rejector?.name ?
                                <div className="cls_withe_porsann">
                                    <div className='main_porsan_adafafaf'>
                                        <div className='main_dixv_kdjdhdhd'>
                                            <Typography className='typo_text_kryrry'>Rejected by</Typography>
                                            <Typography className='main_div_conteananana'>{props?.data?.payable?.rejector?.name}</Typography>
                                        </div>
                                        <div className='main_dixv_kdjdhdhd'>
                                            <Typography className='typo_text_kryrry'>Rejection date</Typography>
                                            <Typography className='main_div_conteananana'>{moment(props?.data?.payable?.rejected_at).format('YYYY-MM-DD')}</Typography>
                                        </div>
                                    </div>
                                </div> : ""}
                            {props?.data?.payable?.creator?.name ?
                                <div className="cls_withe_porsann">
                                    <div className='main_porsan_adafafaf'>
                                        <div className='main_dixv_kdjdhdhd'>
                                            <Typography className='typo_text_kryrry'>Created by</Typography>
                                            <Typography className='main_div_conteananana'>{props?.data?.payable?.creator?.name}</Typography>
                                        </div>
                                        <div className='main_dixv_kdjdhdhd'>
                                            <Typography className='typo_text_kryrry'>Creation date</Typography>
                                            <Typography className='main_div_conteananana'>{moment(props?.data?.payable?.created_at).format('YYYY-MM-DD')}</Typography>
                                        </div>
                                    </div>
                                </div> : ""}
                        </div>
                        <div style={{ marginTop: "7px" }} className="cls_main_div_bill">
                            {props?.data?.payable?.approver?.name ?
                                <div className="cls_withe_porsann">
                                    <div className='main_porsan_adafafaf'>
                                        <div className='main_dixv_kdjdhdhd'>
                                            <Typography className='typo_text_kryrry'>Paid by</Typography>
                                            <Typography className='main_div_conteananana'>{props?.data?.payable?.approver?.name}</Typography>
                                        </div>
                                        <div className='main_dixv_kdjdhdhd'>
                                            <Typography className='typo_text_kryrry'>Approval date</Typography>
                                            <Typography className='main_div_conteananana'>{moment(props?.data?.payable?.approved_at).format('YYYY-MM-DD')}</Typography>
                                        </div>
                                    </div>
                                </div> : ""}
                            {props?.data?.payable?.payer?.name ?
                                <div className="cls_withe_porsann">
                                    <div className='main_porsan_adafafaf'>
                                        <div className='main_dixv_kdjdhdhd'>
                                            <Typography className='typo_text_kryrry'>Paid by</Typography>
                                            <Typography className='main_div_conteananana'>{props?.data?.payable?.payer?.name}</Typography>
                                        </div>

                                        <div className='main_dixv_kdjdhdhd'>
                                            <Typography className='typo_text_kryrry'>Payout date</Typography>
                                            <Typography className='main_div_conteananana'>{moment(props?.data?.payable?.paid_at).format('YYYY-MM-DD')}</Typography>
                                        </div>
                                    </div>
                                </div> : ""}
                        </div> */}
                                </div>
                            </div>
                        </>}></SkeletonDashboardV3>
            </div>
        </>
    )
}
export default Document_details;

const DotIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 5 5" fill="none">
        <circle cx="2.5" cy="2.5" r="2.5" fill="#025141" />
    </svg>
)