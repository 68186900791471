import styles from './budget.module.scss';
import { Button, Form, InputGroup, Table } from "react-bootstrap";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as React from 'react';
import { Typography, Menu, MenuItem, } from "@mui/material";
import { selectAllCounterpartsListData } from "../../../../_reducers/counterparts.reducer";
import { useDispatch, useSelector } from "react-redux";
import { getUser, isUserAuthenticated, authHeader } from "../../../../_helpers";
import { counterpartsActions } from "../../../../_actions/counterparts.actions";
import { paymentJourneyActions } from "../../../../_actions/paymentJourney.actions";
import moment from 'moment';
import { selectPaymentJourneyData } from "../../../../_reducers/paymentJourney.reducer";
import {
    getBalanceReducer,
    getBillReducer,
    selectAllData,
} from "../../../../_reducers/dashboard.reducer";
import { dashboardActions } from "../../../../_actions";
import { CssBaseline, CircularProgress } from '@mui/material';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { useNavigate, useLocation } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';




const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Dudget = () => {
    const user = getUser()?.data ?? null;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [balance, setBalance] = React.useState<any>();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const counterpartsListData = useSelector((state) => selectAllCounterpartsListData(state))
    const [renderUser, setRenderUser] = React.useState(true)
    const [userData, setUserData] = React.useState<any>([]);
    const [render, setRender] = React.useState(true);
    const [startDate, setStartDate] = React.useState();
    const [list_datta, setList_data] = React.useState()
    const [renadr_data, setReandar_data] = React.useState(true)
    const dataList = useSelector((state) => selectPaymentJourneyData(state))
    const [total, setTotal] = React.useState<any>()
    const [actual_expense, setActual_expense] = React.useState<any>()
    const [available_balance, setAvailable_balance] = React.useState<any>()
    const [balanceRender, setBalanceRender] = React.useState(false);
    const data = useSelector((state) => selectAllData(state));
    const [dataone_time, setData_one_time] = React.useState(true)
    const [loader_data, setLoade_data] = React.useState(false)
    const [rows, setRows] = React.useState([
    ])
    const [add_list, setAdd_list] = React.useState(false)
    const [day_name, setDay_name] = React.useState("")
    const [requestRender, setrequestRender] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    const [responseRender, setresponseRender] = React.useState(false);
    const [otpFailed, setotpFailed] = React.useState(false);
    const [id_list_contean, setId_list_contean] = React.useState("")
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [show, setShowlist] = React.useState(false);
    const [showModal, setShow] = React.useState(false);
    const [loader, setLoader] = React.useState(true)
    const[Balanceloader,setBalanceloader]=React.useState(false)
    const [dayname, setDayname] = React.useState([
        { name: "Monthly" },
        { name: "Weekly" },
        { name: "Yearly" },
    ])
    const [status, serStatus] = React.useState(false)

    React.useEffect(() => {
        if (dataone_time && counterpartsListData?.GETTAGS_BUDGET_SUCCESS !== undefined) {
            setData_one_time(false)
            setList_data(counterpartsListData.GETTAGS_BUDGET_SUCCESS[0].id)
        }

    }, [counterpartsListData]);
    const handleShow = () => {
        setShow(true);
        setShowlist(true);
    }
    const onClose = () => {
        setShow(false);
    };
    React.useEffect(() => {
        if (isUserAuthenticated() && render) {
            setRender(false);
            dispatch(counterpartsActions.getBudgetTags() as any)
            dispatch(dashboardActions.getBalance("/wallet/get-balance",setBalanceRender,setBalanceloader) as any);
        }
    }, [render, isUserAuthenticated]);
    const getCsvurl = async (datalist: any) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', ...authHeader() },
        }
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/budget/get-budget-list?id=${datalist.id}&date=${datalist.date}&type=${datalist.type}`, requestOptions)
            .then((response) => {
                if (response.data) {
                    var dataadd: any = [];
                    var dataitemarr = []
                    for (let index = 0; index < response.data.data.length; index++) {
                        const element = response.data.data[index];
                        for (let index = 0; index < element.items.length; index++) {
                            const element_data = element.items[index];

                            var datapushitem = {
                                "id": element_data.id,
                                "budget_id": element_data.budget_id,
                                "category_id": element_data.category_id,
                                "category_name": element_data.category_name,
                                "amount": element_data.amount / 100,
                                "desc": element_data.desc,
                                "created_at": element_data.created_at,
                                "updated_at": element_data.updated_at,
                                "expense": element_data.expense
                            }
                            dataitemarr.push(datapushitem)
                        }
                        var objectdata = {
                            actual_expense: element.actual_expense / 100,
                            assignee_id: element.assignee_id,
                            available_balance: element.available_balance / 100,
                            created_at: element.created_at,
                            created_by: element.created_by,
                            day: element.day,
                            desc: element.desc,
                            duration_id: element.duration_id,
                            entity_id: element.entity_id,
                            every: element.every,
                            id: element.id,
                            items: dataitemarr,
                            month: element.month,
                            repeat: element.repeat,
                            title: element.title,
                            total_balance: element.total_balance / 100,
                            type: element.type,
                            updated_at: element.updated_at
                        }
                        dataadd.push(objectdata)

                    }
                    setRows(dataadd);
                    serStatus(true)
                    setLoading(false)
                } else {
                    setRows([])
                    serStatus(true)
                    setLoading(false)
                    setAdd_list(true)
                }
                setLoading(false)
            })


    }
    React.useEffect(() => {
        const dateformeat_list = moment(startDate).format('YYYY-MM')
        if (counterpartsListData?.GETTAGS_BUDGET_SUCCESS !== undefined) {

            console.log(list_datta, startDate, 'list_dattalist_datta');
            if (!!list_datta) {
                const body = {
                    id: list_datta,
                    date: moment(startDate).format('YYYY-MM'),
                    type: day_name
                }
                getCsvurl(body)

            }
            else {
                const body = {
                    id: counterpartsListData.GETTAGS_BUDGET_SUCCESS[0].id,
                    date: moment(startDate).format('YYYY-MM'),
                    type: day_name
                }
                getCsvurl(body)
            }
        } else {

        }
    }, [startDate, list_datta, day_name, counterpartsListData?.GETTAGS_BUDGET_SUCCESS])

    React.useEffect(() => {
        let available_balance = 0;
        let actual_expense = 0;
        let total_balance = 0;
        if (!!rows) {
            for (let index = 0; index < rows.length; index++) {
                const element: any = rows[index];
                available_balance += +element.available_balance
                actual_expense += +element.actual_expense
                total_balance += +element.total_balance
            }
        }

        setTotal(total_balance)
        setActual_expense(actual_expense)
        setAvailable_balance(available_balance)
    }, [rows])
    React.useEffect(() => {
        if (balanceRender && data?.GET_DASHBOARD_BALANCE_SUCCESS !== undefined) {
            setBalanceRender(false);
            setBalance(data.GET_DASHBOARD_BALANCE_SUCCESS);
        }
    }, [data, balanceRender]);

    React.useEffect(() => {
        if (requestRender && dataList?.LIST_BUDGET_REQUEST == true) {
            setLoading(dataList.LIST_BUDGET_REQUEST);
            setresponseRender(true);
            setrequestRender(false);
            setReandar_data(true);
            setotpFailed(true);
        }
    }, [dataList, requestRender]);

    React.useEffect(() => {
        if (responseRender && dataList?.LIST_BUDGET_REQUEST == false) {
            setLoading(false);
            setrequestRender(true);
            setresponseRender(false);
        }
    }, [dataList, responseRender]);
    React.useEffect(() => {
        if (
            otpFailed &&
            dataList?.LIST_BUDGET_FAILURE !== undefined
        ) {
            setReandar_data(false);
            setotpFailed(false);
        }
    }, [dataList, otpFailed]);

    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const Parentdiv = {
        height: 3,
        width: '100%',
        margin: "8px 20px 10px 0px",
        backgroundColor: '#EDEDED',
        borderRadius: 40,
    }
    React.useEffect(() => {
        if (renderUser && counterpartsListData?.GETTAGS_BUDGET_SUCCESS !== undefined) {
            setRenderUser(false);
            setUserData(counterpartsListData.GETTAGS_BUDGET_SUCCESS);
        }
    }, [counterpartsListData, renderUser]);

    const deleteData = () => {
        dispatch(paymentJourneyActions.budget_delete_active(id_list_contean) as any)

    }
    return (
        <>
            <div className="balance">
                <div className="balance__header" style={{ marginBottom: '35px' }}>
                    <div className="balance__header__name">Budget</div>
                    <div>
                        {loading == true ? <ThemeProvider theme={theme}>
                            <Box sx={{ display: 'flex', position: 'absolute', top: 150, bottom: 0, right: 0, left: 0, justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                                <CircularProgress color="success" />
                            </Box>
                        </ThemeProvider> : ""}
                        {!!user && !!user.role_name && user.role_name == 'Viewer' || !!user && !!user.role_name && user.role_name == 'Approver' || !!user && !!user.role_name && user.role_name == 'Associate' ?
                            <>
                                <Button
                                    className="balance__save-btn"
                                    style={{ cursor: 'not-allowed' }}
                                >
                                    Create New
                                </Button>
                            </> :
                            <>
                                <Button
                                    className="balance__save-btn"
                                    onClick={() => {
                                        window.location.href = "/create_new_budget"
                                    }}
                                >
                                    Create New
                                </Button>
                            </>
                        }
                    </div>
                </div>

                {status == true ?
                    <div className="dhaHDHdhdddd">
                        <div >
                            <div className={styles.grid_container_list}>
                                <div className={styles.contean_beles_data}>
                                    <div className={styles.main_div_responss} style={{ display: "flex", alignItems: 'center', width: "45%", justifyContent: "space-between" }}>
                                        <div style={{ padding: "0px 12px 0px 0px" }}>
                                            <DatePicker
                                                placeholderText='Select month'
                                                className={styles.__datepicker}
                                                selected={startDate}
                                                onChange={(date: any) => {
                                                    setStartDate(date)
                                                    setReandar_data(true)
                                                }}
                                                dateFormat="MMMM yyyy"
                                                showMonthYearPicker
                                                showFullMonthYearPicker
                                                showTwoColumnMonthYearPicker
                                            />
                                        </div>
                                        <div className={styles.contean_data_withha}>
                                            <Form.Select name="email"
                                                onChange={(e: any) => {


                                                    setList_data(e.target.value)
                                                    setReandar_data(true)
                                                    setLoade_data(true)
                                                }}
                                                value={list_datta}
                                                className={styles.selecat_box_callss_data}
                                            >
                                                <option selected value="">select Name</option>
                                                {userData?.map((arrayElement: any, index: number) => {
                                                    return (

                                                        <option value={arrayElement.id} key={index + 1}>{arrayElement.name}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </div>
                                        <div style={{ padding: "0px 0px 0px 10px" }} className={styles.contean_data_withha}>
                                            <Form.Select
                                                name="name_day"
                                                onChange={(e: any) => { setDay_name(e.target.value) }}
                                                value={day_name}
                                                className={styles.selecat_box_callss_data}
                                            >
                                                <option selected value="">Select interval</option>
                                                {dayname?.map((arrayElement: any, index: number) => {
                                                    return (
                                                        <option value={arrayElement.name} key={index + 1}>{arrayElement.name}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </div>
                                    </div>
                                    <div className={styles.heding_two_box}>
                                        <div className={styles.heding_contean_descript}>
                                            <p className={styles.p_tage_cuntean}>KES {Number(parseFloat(total).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 })}
                                            </p>
                                            <p className={styles.total_heding_data}> Planned expenses</p>
                                        </div>
                                        <div className={styles.heding_contean_descript}>
                                            <p className={styles.p_tage_cuntean}>KES {Number(parseFloat(actual_expense).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 })}
                                            </p>
                                            <p className={styles.total_heding_data}>Actual expenses</p>
                                        </div>

                                        <div className={styles.heding_contean_descript}>
                                            <p className={styles.p_tage_cuntean}>KES {Number(parseFloat(available_balance).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 })}
                                            </p>
                                            <p className={styles.total_heding_data}>Available</p>
                                        </div>
                                        <div className={styles.heding_contean_descript}>
                                            <p className={styles.pay_amount_key_data}>KES {Number(parseFloat(available_balance).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 })}
                                            </p>
                                            <p style={{ textAlign: "end" }} className={styles.total_heding_data}>Savings</p>
                                        </div>
                                        <div className={styles.heding_contean_descript}>
                                            <p className={styles.pay_amount_key_data}>

                                                KES   {balance[0]?.total_balance ? Number((balance[0]?.total_balance / 100).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 }) : balance[0]?.total_balance / 100 ?? "0.00"}

                                            </p>
                                            <p style={{ textAlign: "end" }} className={styles.total_heding_data}>Balance</p>
                                        </div>
                                    </div>
                                </div>

                                <ThemeProvider theme={theme}>

                                    {loading && <Box sx={{ display: 'flex', position: 'absolute', top: 150, bottom: 0, right: 0, left: 0, justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                                        <CircularProgress color="success" />
                                    </Box>}
                                    {rows.length == 0 && status == true ?
                                        <div className="calss_tage_ajajahhahha" style={{ display: 'flex', height: '94vh', padding: '43px 43px' }}>

                                            <div id={styles.contenar_data_line} className={'create_intigretion'}>
                                                <p id={styles.add_new_tewxt_hiding_age} className='heading_create'>
                                                    Harnessing the Power of <span className={styles.add_clor_sead}>Budgeting</span>  for Business Success.

                                                </p>
                                                <p id={styles.text_dis_contean} className='description_create'>
                                                    Create a budget for different business activities, analyze and compare them with the actual expenses,
                                                    and evaluate the overall performance of your business.
                                                </p>
                                                <Button
                                                    onClick={() => {
                                                        window.location.href = "/create_new_budget"
                                                    }}
                                                    className="tags__save-btn" >
                                                    Create Budget
                                                </Button>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <Modal
                                                show={showModal}
                                                onHide={onClose}
                                                backdrop="static"
                                                keyboard={false}
                                                aria-labelledby='ModalHeader'
                                                centered
                                                size="sm"
                                            ><Modal.Body><Box className={styles.box_dialog_main}>
                                                <Typography className={styles.typo_text}>Are you sure you want to delete this budget?</Typography>
                                                <Box className={styles.box_btn_cencal}>
                                                    <Button className={styles.delete_btn} onClick={() => {
                                                        deleteData()
                                                        onClose()
                                                    }}>Yes, Delete</Button>
                                                    <Button className={styles.cancel_btn} onClick={onClose}>Cancel</Button>
                                                </Box>
                                            </Box></Modal.Body></Modal>
                                            <div>
                                                <div className={styles.add_contenar_heding_box}>

                                                    <div className={styles.display_datathiss}>
                                                        <div className={styles.maiaha_patisan_data}>
                                                            <p className={styles.dataloafgaffafdfa}>Budget name</p>
                                                            <p className={styles.text_p_data_contean} style={{ paddingLeft: "12px" }}>Category</p>
                                                        </div>


                                                    </div>
                                                    <div className={styles.active_contean_data}>
                                                        <p className={styles.text_p_data_contean}>Planned Expenses</p>
                                                    </div>
                                                    <div className={styles.active_contean_data}>
                                                        <p className={styles.text_p_data_contean}>Actual Expenses</p>
                                                    </div>
                                                    <div className={styles.active_contean_data}>
                                                        <p className={styles.text_p_data_contean}>Available</p>
                                                    </div>
                                                    <div>

                                                    </div>
                                                </div>
                                                <div>
                                                    <div className={styles.main_div_list_data}>
                                                        {!!rows && rows.map((element: any, index: number) => {
                                                            console.log(element, 'elementelement');

                                                            return (
                                                                <>
                                                                    <div className={styles.main_dese_buttom_data} style={{ display: "flex", padding: "0px 20px 10px 20px", borderBottom: "1px solid #DDD" }}>
                                                                        <div className={styles.calss_data_main_object} style={{ width: "50%", padding: "10px 30px 0px 0px" }}>
                                                                            <div
                                                                                className={styles.responsiv_div_data_keyyy}
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "space-between",
                                                                                }}>
                                                                                <div className={styles.maiaha_patisan_data_two}>


                                                                                    <p className={styles.maiahhshs_adata_logg}>{element.title} </p>

                                                                                    <div className={styles.add_chabox_and_p_tage}>
                                                                                        <p >
                                                                                            {element.items[0].category_name}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div ><p className={styles.p_tage_end_text_data}>
                                                                                    {element.actual_expense == 0 ?
                                                                                        "0% budget completion." :
                                                                                        element.actual_expense < element.total_balance ?
                                                                                            "progress of actual spend vs budget" :
                                                                                            element.actual_expense > element.total_balance ?
                                                                                                "over budget" : "100% utilization of budget"
                                                                                    }</p>
                                                                                </div>

                                                                            </div>


                                                                            {element.actual_expense == 0 ?
                                                                                <div style={Parentdiv}>

                                                                                    <div className={styles.porgareshbarline}></div>
                                                                                </div> :
                                                                                element.actual_expense < element.total_balance ?
                                                                                    <div style={Parentdiv}>

                                                                                        <div style={{
                                                                                            height: '100%',
                                                                                            width: `${element.actual_expense * 100 / element.total_balance}%`,
                                                                                            backgroundColor: `${'#FF9900'}`,
                                                                                            borderRadius: 40,
                                                                                            textAlign: 'right'
                                                                                        }}></div>
                                                                                    </div>
                                                                                    : element.actual_expense > element.total_balance ?
                                                                                        <div style={Parentdiv}>

                                                                                            <div style={{
                                                                                                height: '100%',
                                                                                                width: `${(element.actual_expense - element.total_balance) * 100 / element.actual_expense}%`,
                                                                                                backgroundColor: `${'#FF2E00'}`,
                                                                                                borderRadius: 40,
                                                                                                textAlign: 'right'
                                                                                            }}></div></div> : <div style={Parentdiv}>

                                                                                            <div style={{
                                                                                                height: '100%',
                                                                                                width: `${100}%`,
                                                                                                backgroundColor: `${element.actual_expense == element.total_balance ? "#025041" : "#000"}`,
                                                                                                borderRadius: 40,
                                                                                                textAlign: 'right'
                                                                                            }}></div>
                                                                                        </div>}
                                                                        </div>
                                                                        <div className={styles.active_contean_data}>
                                                                            <p className={styles.text_tebal_text_dis_data}>KES {Number(parseFloat(element.total_balance).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 })}</p>
                                                                        </div>
                                                                        <div className={styles.active_contean_data}>
                                                                            <p className={styles.text_tebal_text_dis_data}>KES {Number(parseFloat(element.actual_expense).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 })}</p>
                                                                        </div>
                                                                        <div className={styles.active_contean_data} style={{ justifyContent: "space-between" }}>
                                                                            <p className={styles.text_tebal_text_dis_data}>KES {Number(parseFloat(element.available_balance).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 })}</p>
                                                                            <div style={{ cursor: "pointer", display: "flex", alignItems: "center" }} onClick={(event: any) => {
                                                                                setId_list_contean(element.id)
                                                                                handleClick(event)
                                                                            }}>
                                                                                <MoreVertOutlinedIcon />
                                                                            </div>
                                                                        </div>

                                                                        <Menu
                                                                            id="basic-menu"
                                                                            anchorEl={anchorEl}
                                                                            open={open}
                                                                            onClose={handleClose}
                                                                            MenuListProps={{
                                                                                'aria-labelledby': 'basic-button',
                                                                            }}
                                                                        >
                                                                            <MenuItem
                                                                                onClick={() => {
                                                                                    navigate("/edit_budget?id=" +
                                                                                        id_list_contean, { replace: true, state: { id: id_list_contean } });
                                                                                }}
                                                                                className={styles.menuitem}>Update</MenuItem>
                                                                            <MenuItem onClick={() => {
                                                                                handleClose()
                                                                                handleShow()
                                                                            }} className={styles.menuitem} style={{ color: "#F87161" }}>Delete
                                                                            </MenuItem>
                                                                        </Menu>

                                                                    </div>
                                                                </>
                                                            );
                                                        })}

                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                </ThemeProvider>
                            </div>

                        </div>
                    </div > : rows.length == 0 && loading == false && status == false ?
                        <div className="calss_tage_ajajahhahha" style={{ display: 'flex', height: '94vh', padding: '43px 43px' }}>

                            <div id={styles.contenar_data_line} className={'create_intigretion'}>
                                <p id={styles.add_new_tewxt_hiding_age} className='heading_create text-center' style={{ lineHeight: '25px' }}>
                                    Harnessing the Power of <span className={styles.add_clor_sead}>Budgeting</span>  for Business Success.

                                </p>
                                <p id={styles.text_dis_contean} className='description_create'>
                                    Create a budget for different business activities, analyze and compare them with the actual expenses,
                                    and evaluate the overall performance of your business.
                                </p>
                                <Button
                                    onClick={() => {
                                        window.location.href = "/create_new_budget"
                                    }}
                                    className="tags__save-btn" >
                                    Create Budget
                                </Button>
                            </div>
                        </div>
                        : ""}
            </div>

        </>
    )
}
export default Dudget;