import React, {useState, useEffect } from 'react';
import CounterpartsTable from './components/CounterpartsTable/CounterpartsTable';
import { useSearchParams } from 'react-router-dom';
import PageHeader from '../PageHeader';
import { Dropdown } from '@team-monite/ui-kit-react';
import { CounterpartType } from '@team-monite/sdk-api';
import './Counterparts.scss';
import amplitude from 'amplitude-js';
import { Button } from 'react-bootstrap';
import '../../../i18n/index';
import {
  getProperties,
  isUserAuthenticated,
} from '../../../../_helpers';
import { useDispatch, useSelector } from 'react-redux';
import { entityActions } from '../../../../_actions/entity.actions';
import Csvdata from '../Counterparts/components/csvlist';
import EnhancedTable from '../Counterparts/components/addnewtebal';
import { ThemeProvider, createTheme } from '@mui/material';
import posthog from 'posthog-js';

export const Counterparts = () => {
  const [counterpartId, setId] = useState<any>(undefined);
  const [counterpartType, setType] = useState<any>(undefined);
  const [csvname, setCsvnamedata] = React.useState('');
  const dispatch = useDispatch();
  const [counterpartData, setCounterpartData] = useState<any>();
  var localUser: any = localStorage.getItem('user');
  const properties = getProperties();
  const PAYABLE_ID = 'id';
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get(PAYABLE_ID);
  const [listpopup, setPopup] = useState<any>(false);
  const [render, setRender] = useState(true);

  const changeTime = () => {
    var dateset = new Date();
    const tokentwo: any = localStorage.getItem('tokentwo');
    if (
      new Date().getTime() - new Date(JSON.parse(tokentwo)).getTime() >=
      1800000
    ) {
      console.log('here 30 minutes upper');
      setRender(false);
      dispatch(entityActions.getBilldata() as any);
    } else {
      console.log('here 30 minutes lower');
    }
    setTimeout(() => changeTime(), 1830000);
  };
  useEffect(() => {
    changeTime();
  }, [render, isUserAuthenticated]);

  useEffect(() => {
    const popup = searchParams.get('popup');
    const BILLSBULK: any = searchParams.get('BILLSBULK');
    setCsvnamedata(BILLSBULK);
    if (popup == '0') {
      setType(CounterpartType.ORGANIZATION);
    } else if (popup == '1') {
      setType(CounterpartType.INDIVIDUAL);
    }
  }, [searchParams]);

  const submitData = () => {
    const event = 'Payee Created';
    const eventProperties = {
      userId: !!properties && !!properties.user_id ? properties.user_id : null,
      userName: !!properties && !!properties.name ? properties.name : null,
      email: !!properties && !!properties.email ? properties.email : null,
      pageUrl: window.location.href,
    };
    amplitude.getInstance().logEvent(event, eventProperties);
    posthog.capture(event, eventProperties);
  };
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: { primary: { main: '#000' } },
  });
  return (
    <>
      {!!localUser && (
        <div className="counterparty">
          <PageHeader
            title="Payees"
            extra={
              JSON.parse(localUser).data.role_name != 'Viewer' ? (
                <>                      <Button
                        color="#025041"
                        onClick={() => {
                          window.location.href = '/create-counterparts';
                        }}
                        className="counterparty__save-btn"
                      >
                        Create New
                      </Button>
                   
                </>
              ) : (
               
                    <Button
                      color="#025041"
                      style={{ cursor: 'not-allowed' }}
                      className="counterparty__save-btn"
                    >
                      Create New
                    </Button>
                 
              )
            }
            extratwo={
              JSON.parse(localUser).data.role_name != 'Viewer' ? (
                    <Button
                      color="#025041"
                      className="counterparty__white-btn"
                      onClick={() => {
                        setPopup(true);
                        setCsvnamedata('');
                      }}
                    >
                      Import Payees
                    </Button>
              ) : (
           
                    <Button
                      color="#025041"
                      style={{ cursor: 'not-allowed' }}
                      className="counterparty__white-btn"
                    >
                      Import Payees
                    </Button>
                 
              )
            }
          />
          {listpopup && (
            <>
              <Csvdata
                show={listpopup}
                onClose={() => {
                  setPopup(false);
                  setCsvnamedata('');
                }}
                onSave={(el: boolean) => {
                  setPopup(false);
                  setCsvnamedata('bulkadd');
                }}
              />
            </>
          )}
          {csvname == 'bulkadd' ? (
            <div className={'height'}>
              <EnhancedTable />{' '}
            </div>
          ) : (
            <div className={'scroll_remove'}>
              <div
                className={'height'}
                style={{
                  height: 'auto',
                  display: 'block',
                }}
              >
                {' '}
                <ThemeProvider theme={theme}>
                  <CounterpartsTable
                  />
                </ThemeProvider>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
