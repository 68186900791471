import React, { useEffect, useState } from 'react';
import { Button, InputGroup, Form as BootstrapForm } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './VerifyIdentity.scss';
import { useDispatch, useSelector } from 'react-redux';
import { payablesActions } from '../../../../../../../../_actions/payables.actions';
import { choiceActions } from '../../../../../../../../_actions';
import { userActions } from '../../../../../../../../_actions';
import { useLocation, useNavigate } from 'react-router-dom';
import { settingActions } from '../../../../../../../../_actions/setting.actions';
import { selectAllPayablesListData } from '../../../../../../../../_reducers/payables.reducer';
import { getUser } from '../../../../../../../../_helpers';
// import { toast } from "@team-monite/ui-widgets-react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Define the validation schema using Yup
const validationSchema = Yup.object().shape({
  otp: Yup.string().required('OTP is required'),
});

interface Props {
  time: any;

  // paymentDeatils: any;
  // counterpart: any;
  // type: any;
  // loading: boolean;
}

const VerifyIdentity = ({ setSetpNumberAuth, updateStep, path }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [authorizeEnabled, setAuthorizeEnabled] = useState(false);
  const [otpRender, setOtpRender] = useState(false);
  const [loader, setLoader] = useState(false)

  let organizationType = location.state?.selectedType;
  let selectedCurrency = location.state?.selectedCurrency;
  const otpdata = useSelector((state) => selectAllPayablesListData(state));
  const user = getUser()?.data ?? '';

  const onboardingInfo: any = useSelector(
    (state: any) => state.getOnboardingInfoReducer.data
  );

  const [timeLeft, setTimeLeft] = useState(120);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // useEffect(() => {
  //   if (!onboardingInfo) {
  //     dispatch(choiceActions.getStoreOnboardingInfo(user?.entity?.id) as any);
  //   }
  // }, [dispatch, onboardingInfo, user]);

  const onSubmit = async (values: any, { setSubmitting }: any) => {
    // setAuthorizeEnabled(true);
    // if (selectedCurrency && selectedCurrency !== 'KES') {
    const request = {
      otpCode: `${values.otp}`,
      user_id: `${user?.user_id}`,
      business_id: `${user?.entity?.id}`,
      businessId: `${user?.entity?.id}`,
      id: `${user?.entity?.id}`,
    };
    dispatch(
      choiceActions.verifyOtp(request, () => {
        dispatch(settingActions.submitEntityDocuments() as any);
        toast.success('Account onboarding submitted!');
        // toast.success('Documents uploaded successfully', { duration: 5000 });
        if (path == '/v2/account-setup-completed') {
          updateStep(7, true, navigate(path));
          setSetpNumberAuth(7);
        }
        // setTimeout(() => {
        //   setOtpRender(true);
        // window.location.href = path;
        // }, 5000);
      },setLoader) as any
    );
    // } else {
    //   const request = {
    //     ...values,
    //     data: Date.now(),
    //   };
    //   dispatch(payablesActions.verifyOtpRequest(request) as any).then(
    //     (response: any) => {
    //       console.log('response', response);
    //     }
    //   );
    // }
    // setAuthorizeEnabled(false);
  };

  // console.log(otpdata.OTP_SUCCESS, otpdata, otpRender, 'otpdata');
  // useEffect(() => {
  //   if (otpdata?.OTP_SUCCESS) {
  //     dispatch(settingActions.submitEntityDocuments() as any);
  //     toast.success('OTP verified successfully', { duration: 5000 });
  //     toast.success('Documents uploaded successfully', { duration: 5000 });
  //     setTimeout(() => {
  //       window.location.href = '/';
  //     }, 5000);
  //   }
  // }, [otpdata.OTP_SUCCESS]);

  const onCancel = () => {
    navigate('/document-upload', { state: { fromCancel: true } });
  };

  const onResend = () => {
    dispatch(
      choiceActions.resendOtp(
        {
          businessId: `${user?.user_id}`,
          otpType: 'email',
        },
        () => console.log('working')
      ) as any
    );
  };

  return (
    <div className="auth-trans-container">
      <div className="auth-trans-container__heading">OTP Verification</div>
      <div className="auth-trans-container__desc">
      Kindly enter the OTP received on email via our partner bank.
      </div>

      {/* Formik integration */}
      <Formik
        initialValues={{ otp: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="auth-trans-container__label">Verification code</div>
            <InputGroup className="input-wrapper input">
              <Field
                as={BootstrapForm.Control}
                className="auth-trans-form-input"
                name="otp"
                aria-describedby="basic-addon3"
              />
            </InputGroup>
            <ErrorMessage
              className="error-message"
              name="otp"
              component="div"
            />

            <div
              id="div_authoridse_cls"
              style={{ justifyContent: 'space-between', alignItems: 'center' }}
              className="auth-trans-container__btn-row"
            >
              <div
                id="sumintbutton_autorise"
                className="auth-trans-container__btn-row"
                style={{ marginTop: '0px' }}
              >
                {/* <Button
                  className="auth-trans-container__btn-row__white"
                  onClick={onCancel}
                  type="button"
                >
                  Cancel
                </Button> */}

                {timeLeft > 0 && (
                  <Button
                    className="auth-trans-container__btn-row__green"
                    onClick={() => console.log('resend')}
                    type="button"
                  >
                    Resend OTP in {timeLeft} Sec
                  </Button>
                )}

                {timeLeft === 0 && (
                  <Button
                    className="auth-trans-container__btn-row__green"
                    onClick={onResend}
                    type="button"
                  >
                    Resend OTP
                  </Button>
                )}
                <Button
                  className="auth-trans-container__btn-row__green"
                  disabled={authorizeEnabled}
                  type="submit"
                >
                   {loader == true && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Submit
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VerifyIdentity;
