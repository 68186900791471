import { authHeader, handleResponse } from '../_helpers';

const getIndustries = async () => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/choice/get-industries`,
    requestOptions
  ).then(handleResponse);
};

const getGenders = async () => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/choice/get-gender`,
    requestOptions
  ).then(handleResponse);
};

const getOperatingModes = async () => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/choice/get-operating-modes`,
    requestOptions
  ).then(handleResponse);
};

const getBusinessTypes = async () => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/choice/get-types`,
    requestOptions
  ).then(handleResponse);
};

const createBusiness = (BusinessRequest) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(BusinessRequest),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/choice/create-business-account`,
    requestOptions
  ).then(handleResponse);
};

const saveBusiness = (BusinessRequest, payload) => {
  if (payload == 1) {
    delete BusinessRequest.selfie
    delete BusinessRequest.kra
    delete BusinessRequest.idFront
    delete BusinessRequest.idBack
    delete BusinessRequest.businessCertificate
  } else if (payload == 2) {
    delete BusinessRequest.businessCertificate
    delete BusinessRequest.cr
    delete BusinessRequest.kraPinCertificate
    delete BusinessRequest.memorandum
    delete BusinessRequest.resolution
  } else if (payload == 3) {
    delete BusinessRequest.businessCertificate
    delete BusinessRequest.cr
    delete BusinessRequest.kraPinCertificate
    delete BusinessRequest.memorandum
    delete BusinessRequest.resolution
    delete BusinessRequest.businessKraPinCertificate
    delete BusinessRequest.partnershipDeed
  }
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(BusinessRequest),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/choice/save-business-details`,
    requestOptions
  ).then(handleResponse);
};

const verifyOtp = (otpData) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(otpData),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/choice/verify-otp`,
    requestOptions
  ).then(handleResponse);
};

const resendOtp = (otpData) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(otpData),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/choice/resend-otp`,
    requestOptions
  ).then(handleResponse);
};

const saveShareholder = (otpData) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(otpData),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/choice/create-shareholder`,
    requestOptions
  ).then(handleResponse);
};

const getStoreOnboardingInfo = (businessId) => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/choice/get-store-onboarding-info/${businessId}`,
    requestOptions
  ).then(handleResponse);
};

const getBusinessDetails = (userId) => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/choice/get-business-details/${userId}`,
    requestOptions
  ).then(handleResponse);
};

const submitToBank = () => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/choice/v2/create-business-account`,
    requestOptions
  ).then(handleResponse);
};

const uploadMedia = (mediaData) => {
  console.log('media data in choice service', mediaData);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(mediaData),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/choice/upload-document`,
    requestOptions
  ).then(handleResponse);
};
const uploadchaoic = (mediaData) => {
  console.log('media data in choice service', mediaData);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(mediaData),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/choice/upload-file`,
    requestOptions
  ).then(handleResponse);
};
export const choiceBankService = {
  getIndustries,
  getBusinessTypes,
  getOperatingModes,
  getGenders,
  createBusiness,
  verifyOtp,
  resendOtp,
  getStoreOnboardingInfo,
  uploadMedia,
  saveBusiness,
  saveShareholder,
  getBusinessDetails,
  submitToBank,
  uploadchaoic
};
