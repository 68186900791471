import { Box, Grid, Typography } from '@mui/material';
import styles from './userpage.module.scss';
import React, { useEffect, useState } from 'react';
import { paymentJourneyActions } from "../../../../../../../_actions/paymentJourney.actions";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { selectPaymentJourneyData } from "../../../../../../../_reducers/paymentJourney.reducer";
import moment from 'moment';
import Tebal_Detels from '../tebal_detels/Tebal_Detels';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { CssBaseline, CircularProgress } from '@mui/material';

import { TransactionDetails } from '../../../../Balance/components/TransactionDetails/TransactionDetails';
import { SkeletonCom, SkeletonDashboardV3 } from '../../../../Skeleton/Skeleton';
const UserPage = () => {
    const dataList = useSelector((state) => selectPaymentJourneyData(state))
    const [showTransMoodal, setshowTransMoodal] = React.useState(false);
    const [transData, settransData] = React.useState({});
    const [data, setData] = useState<any>([])
    const dispatch = useDispatch();
    const [redusar, setReduser] = useState(false)
    const [Loader, setLoader] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const popup = searchParams.get('id');
        if (!!popup) {
            dispatch(paymentJourneyActions.PaymentLinkApiList(popup) as any)
            setTimeout(() => {
                setReduser(true)
            }, 4000)
            setLoader(true)
        }

    }, [searchParams])

    useEffect(() => {
        if (redusar && dataList.PAYMENTLINKAPILIST_SUCCESS != undefined) {
            setReduser(false)
            setData(dataList.PAYMENTLINKAPILIST_SUCCESS)
            setLoader(false)
        }
    }, [redusar, dataList])
    useEffect(() => {
        if (redusar && dataList.PAYMENTLINKAPILIST_FAILURE != undefined) {
            setReduser(false)
            setLoader(false)
        }
    }, [redusar, dataList])
    return (
        <>
            {/* {Loader && <Box sx={{ display: 'flex', position: 'absolute', top: 150, bottom: 0, right: 0, left: 0, justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
            <CircularProgress color="success" />
        </Box>} */}
            <Grid container className={styles.grid_detels_page}>

                <Grid item md={12} sm={12} xs={12}>
                    <Button className={styles.GOback_button} onClick={() => {
                        navigate("/settings/Payment_Link")
                    }}>
                        <KeyboardBackspaceIcon /> Go Back
                    </Button>
                </Grid>
                <SkeletonDashboardV3
                    width={'100%'}
                    height={'54px'}
                    loader={Loader}
                    element={
                        <>
                            <Grid item md={4} sm={6} xs={12}>

                                <Box className={styles.classNameBoxline}>
                                    <Typography className={styles.heding_typolinlk}>Payment Link</Typography>
                                    <Box className={styles.box_typolinkdataa}>
                                        <Typography>{data?.link}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={2.5} xs={5} sm={6} className={styles.Status_Grid}>
                                <Typography className={styles.heding_Status}>
                                    Status
                                </Typography>
                                <Typography className={data?.status == "active" ? styles.text_status_active : styles.text_status_inactive}>
                                    {data?.status}
                                </Typography>
                            </Grid>
                            <Grid item md={2.5} xs={7} sm={6} className={styles.Status_Grid}>
                                <Typography className={styles.heding_Status}>
                                    Last Updated
                                </Typography>
                                <Typography className={styles.normal_text_moment}>
                                    {moment(data.updated_at).format("DD/MM/YYYY")}
                                </Typography>
                            </Grid>
                            <Grid item md={2.5} sm={6} className={styles.Status_Grid}>
                                <Typography className={styles.heding_Status}>
                                    Payments Collected
                                </Typography>
                                <Typography className={styles.normal_text_moment}>
                                    {data?.total_transactions}
                                </Typography>
                            </Grid>
                        </>}></SkeletonDashboardV3>
                       
                    
                <Grid item md={12} sm={12} xs={12}>
                    <Tebal_Detels
                        Loader={Loader}
                        onSelect={(el: any) => {
                            setshowTransMoodal(true);
                            settransData(el);
                        }} data={data?.transactions} />
                </Grid>
               

            </Grid>
            {showTransMoodal && (
                <TransactionDetails
                    show={showTransMoodal}
                    onClose={() => {
                        setshowTransMoodal(false);
                        settransData('');
                    }}
                    data={transData}
                />
            )}
        </>
    )
}
export default UserPage;