import { Box, Grid, Tooltip, Typography } from '@mui/material';
import styles from './MyAccounts.module.scss';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

const MyAccounts = ({ user, balance, setVirefay_data, setCurrencySelect, setShowTopup }: any) => {
    const navigate = useNavigate();

    console.log("balance dash =>", balance);
    
    
    return (
        <>
            <Grid container className={styles.MyAccounts_container}>
                <Grid item md={12} xs={12} sm={12}>
                    <Box className={styles.boxheding}>
                        <div
                            className="header"
                            style={{ fontSize: '14px' }}
                            id="totalBalance_header"
                        >
                            My Accounts{' '}
                            <Tooltip title="Instantly view your overall account balance at a glance">
                                <Button>
                                    <InfoIcon></InfoIcon>
                                </Button>
                            </Tooltip>
                        </div>

                    </Box>
                    {!!user &&
                        user?.role_name &&
                        user?.role_name !== 'Associate' ? (
                        <div>
                            <div className={styles.boxLine}>
                                <div className={styles.boxLine_left}>
                                    Total Balance
                                </div>
                                <div className={styles.boxLine_right}>
                                    {/* {balance?.total_balance == 0 ?
                                        <a style={{ cursor: "pointer" }} onClick={() => {
                                            navigate('/settings/account-setup');
                                        }} className={styles.a_tage_styles}>
                                            Set up
                                        </a> : */}
                                        <div className={styles.boxLine_right}>
                                            <span>{balance?.currency}</span>{' '}
                                            {!!balance?.total_balance
                                                ? (balance?.total_balance / 100).toLocaleString(
                                                    'en',
                                                    { minimumFractionDigits: 2 }
                                                )
                                                : '0.00'}
                                        </div>
                                </div>
                            </div>
                          <div className={styles.boxLine}>
                                <div className={styles.boxLine_left}>
                                    Available Balance 
                                </div>
                                <div className={styles.boxLine_right}>
                                    <span>{balance?.currency}</span>{' '}
                                    {!!balance?.available_balance
                                        ? (balance?.available_balance / 100).toLocaleString(
                                            'en',
                                            { minimumFractionDigits: 2 }
                                        )
                                        : '0.00'}
                                </div>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    <Box className={styles.Box_contenar}>
                        {(!!user &&
                            user?.role_name &&
                            user?.role_name == 'Accountant') ||
                            user?.role_name == 'Admin' ||
                            user?.role_name == 'Director' ? (
                            <Box className={styles.box_bottam}>
                                <Button
                                 id = {"Receive_payments"}
                                 style={{margin:"0px 14px 0px 0px"}}
                                    onClick={() => {
                                        if (!!user && user?.entity?.status == 'UNVERIFIED') {
                                            setVirefay_data(true)
                                        } else if ((!!user &&
                                            user?.role_name &&
                                            user?.role_name == 'Accountant') ||
                                            user?.role_name == 'Admin' ||
                                            user?.role_name == 'Director') {
                                            setShowTopup(true)
                                        }
                                    }}
                                    className={styles.ReceivepaymentsButton}>
                                    <ArrowDounIcon />{' '}<Typography>Receive payments</Typography>
                                </Button>
                                <Button 
                                id = {"Send_payment"}
                                style={!!user &&
                                    !!user.role_name &&
                                    user.role_name == 'Developer'||user.role_name == 'Approver'||user.role_name == 'Viewer' ? {cursor:"no-drop"}:{}}
                                onClick={() => {
                                    if (!!user && user?.entity?.status == 'UNVERIFIED') {
                                        setVirefay_data(true)
                                    } else if ((!!user &&
                                        user?.role_name &&
                                        user?.role_name == 'Accountant') ||
                                        user?.role_name == 'Admin' ||
                                        user?.role_name == 'Director') {
                                        setCurrencySelect(true)
                                    }
                                }} className={styles.ReceivepaymentsButton}>
                                    <ArrowUpIcon />{' '}<Typography>Send payments</Typography>
                                </Button>
                            </Box>) : (
                                 <Box className={styles.box_bottam}>
                                     <Button
                                     
                                      style={{cursor:"no-drop",margin:"0px 14px 0px 0px"}}
                                    className={styles.ReceivepaymentsButton}>
                                    <ArrowDounIcon />{' '}<Typography>Receive payments</Typography>
                                </Button>
                                <Button 
                                
                                style={{cursor:"no-drop"}}
                                className={styles.ReceivepaymentsButton}>
                                    <ArrowUpIcon />{' '}<Typography>Send payments</Typography>
                                </Button>
                        </Box>)}
                    </Box>
                </Grid>
            </Grid >
        </>
    )
}
export default MyAccounts;
const InfoIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 11 11"
        fill="none"
    >
        <path d="M5.3333 7.50039V4.90039" stroke="#025041" stroke-linecap="round" />
        <path
            d="M5.33333 3.16654C5.57265 3.16654 5.76666 3.36055 5.76666 3.59987C5.76666 3.83919 5.57265 4.0332 5.33333 4.0332C5.094 4.0332 4.89999 3.83919 4.89999 3.59987C4.89999 3.36055 5.094 3.16654 5.33333 3.16654Z"
            fill="#025041"
        />
        <path
            d="M3.16666 1.5798C3.82512 1.19886 4.57261 0.998833 5.33333 1.00001C7.72662 1.00001 9.66665 2.94004 9.66665 5.33333C9.66665 7.72663 7.72662 9.66666 5.33333 9.66666C2.94003 9.66666 1 7.72663 1 5.33333C1 4.54423 1.21103 3.80367 1.5798 3.16667"
            stroke="#025041"
            stroke-linecap="round"
        />
    </svg>
);
const ArrowDounIcon = () => (
    <svg width="14" height="14" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.33325 2.92148L7.74575 2.33398L2.91659 7.16315V4.41732H2.08325V8.58398H6.24992V7.75065H3.50409" fill="white" />
    </svg>
)
const ArrowUpIcon = () => (
    <svg width="14" height="14" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.66699 8.4125L2.25449 9L7.08366 4.17083V6.91667H7.91699V2.75H3.75033V3.58333H6.49616" fill="white" />
    </svg>
)