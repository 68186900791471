import { getUser, authHeader, handleResponse } from "../_helpers";
// import { getUser, isUserAuthenticated, authHeader } from "../../../../_helpers";

const user = getUser()?.data ?? null;
export const dashboardService = {
  getBalance,
  getBills,
  getChartData,
  documentverification,
  getBalance_currency_rate,
  getRecent,
  getBudgetdetails,
  getUpdatedetails,
  getCategoryExpenses,
  getPayeeExpenses,
  getPaymentExpenses,
  getBilldetels,
  getPayableToApprove,
  getPayableToReject,
  getPayableToPending,
  getPayableToCancel,
  getPayableToUpdate,
  getPayableToPay,
  getPayins,
  getPaymentMethodPayins,
  updateUserTourCompleted,
  getDashboardCurrencyData,
  // getDashboardSelectedCurrencyData,
  getBankDetailData,
  getInvoicedetels
};
function getBalance(Link) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(`${process.env.REACT_APP_API_URL}${Link}`, requestOptions).then(
    handleResponse
  );
}
function getBalance_currency_rate(data) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/get-current-currency-rate?currency=${data}`,
    requestOptions
  ).then(handleResponse);
}
// get dashboard activities api
function getBills(data, currency) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}${
      user?.role_name == "Developer"
        ? "/wallet/get-unified-bills"
        : "/wallet/get-bills"
    }?type=${data}&currency=${currency}`,
    requestOptions
  ).then(handleResponse);
}

function getRecent(currency) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/wallet/get-recent-transactions?currency=${currency}`,
    requestOptions
  ).then(handleResponse);
}
function getBudgetdetails(data) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/wallet/get-budget-details`,
    requestOptions
  ).then(handleResponse);
}
function getUpdatedetails() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/wallet/get-update-details`,
    requestOptions
  ).then(handleResponse);
}
function getBilldetels(id) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/payables/get-payable-by-id/${id}`,
    requestOptions
  ).then(handleResponse);
}
function getInvoicedetels(id) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
    }
    return fetch(`${process.env.REACT_APP_API_URL}/invoice/get-by-id/${id}`, requestOptions).then(handleResponse)
}
function getPayableToApprove(id) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/payables/update-payable-to-approve/${id}`,
    requestOptions
  ).then(handleResponse);
}
function getPayableToReject(id) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/payables/update-payable-to-reject/${id}`,
    requestOptions
  ).then(handleResponse);
}
function getPayableToPending(id) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/payables/update-payable-to-pending/${id}`,
    requestOptions
  ).then(handleResponse);
}
function getPayableToPay(id) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/payables/update-payable-to-paid/${id}`,
    requestOptions
  ).then(handleResponse);
}
// getPayableToCancel
function getPayableToCancel(id) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/payables/update-payable-to-cancel/${id}`,
    requestOptions
  ).then(handleResponse);
}
function getPayableToUpdate(id, body) {
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/payables/update/${id}`,
    requestOptions
  ).then(handleResponse);
}

function updateUserTourCompleted() {
  const requestOptions = {
    method: "PATCH",
    // body: JSON.stringify(''),
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/update-user-tour-completed`,
    requestOptions
  ).then(handleResponse);
}
function getCategoryExpenses(data, currency) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/wallet/get-category-expenses?type=${data}&currency=${currency}`,
    requestOptions
  ).then(handleResponse);
}
function getPayins(data, currency) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/wallet/get-payins?type=${data}&currency=${currency}`,
    requestOptions
  ).then(handleResponse);
}
function getPayeeExpenses(data, currency) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/wallet/get-payee-expenses?type=${data}&currency=${currency}`,
    requestOptions
  ).then(handleResponse);
}
function getPaymentMethodPayins(data, currency) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/wallet/get-payment-method-payins?type=${data}&currency=${currency}`,
    requestOptions
  ).then(handleResponse);
}
function getPaymentExpenses(data, currency) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/wallet/get-payment-method-expenses?type=${data}&currency=${currency}`,
    requestOptions
  ).then(handleResponse);
}
// get dashboard charts api
function getChartData(startdate, enddate, currency) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/wallet/get-chart-data/${startdate}/${enddate}/${currency}`,
    requestOptions
  ).then(handleResponse);
}
function documentverification() {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
    // body: JSON.stringify(req)
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/user-document-verification`,
    requestOptions
  ).then(handleResponse);
}

// get dashboard currency api
function getDashboardCurrencyData() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/get-all-currencies-details-by-user`,
    requestOptions
  ).then(handleResponse);
}

// get-all-currencies-details;

// get dashboard selected-currency api
// function getDashboardSelectedCurrencyData() {
//   const requestOptions = {
//     method: "GET",
//     headers: { "Content-Type": "application/json", ...authHeader() },
//   };
//   return fetch(
//     `${process.env.REACT_APP_API_URL}/get-all-currencies-details-by-user`,
//     requestOptions
//   ).then(handleResponse);
// }

// get Bank detail api
function getBankDetailData(currency) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/bank/get-bank-details-by-currency/${currency}`,
    requestOptions
  ).then(handleResponse);
}
