import { alertActions } from '.';
import { payablesConstants } from '../_constants';
import { Unauthorized } from '../_constants/ErrorMessage';
import { payablesService } from '../_services';
import { counterpartsActions } from './counterparts.actions';
// import toast from 'react-hot-toast';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const payablesActions = {
  create,
  createPay,
  getAllPayables,
  setSelectedItem,
  createPayableRequest,
  verifyOtpRequest,
  initiatePayment,
  getTransaction,
  bulkpayables,
  getOtpverifay,
  bulkbills,
  getPayable_id,
  recurring_create_bill,
  recurring_submit_bill,
  initiate_bulk_Payment,
  Transaction_bulk_Payment,
  transactionExist
};
function verifyOtpRequest(req: any) {
  return (
    dispatch: (arg0: {
      type: string;
      otp?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(req));

    payablesService
      .verifyOtpRequest(req)
      .then((response) => {
        dispatch(alertActions.clear());
        return response.json();
      })
      .then(
        (otp) => {
          if (otp.success) {
            dispatch(success(otp));
            dispatch(alertActions.success('OTP verified successful'));
          } else {
            dispatch(failure(otp.message));
            dispatch(alertActions.error(otp.message));
            toast.error(otp.message);
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(otp: any) {
    return { type: payablesConstants.OTP_REQUEST, otp };
  }
  function success(otp: any) {
    return { type: payablesConstants.OTP_SUCCESS, otp };
  }
  function failure(error: any) {
    return { type: payablesConstants.OTP_FAILURE, error };
  }
}
function transactionExist(req: any) {
  return (
    dispatch: (arg0: {
      type: string;
      otp?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(req));

    payablesService
      .transactionExist(req)
      .then((response) => {
        dispatch(alertActions.clear());
        return response.json();
      })
      .then(
        (otp) => {
          if (otp.success) {
            dispatch(success(otp));
            dispatch(alertActions.success('OTP verified successful'));
          } else {
            dispatch(success(otp));
            dispatch(alertActions.success('OTP verified successful'));
            // toast.error(otp.message);
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(otp: any) {
    return { type: payablesConstants.GET_TRANSACTIONEXIST_REQUEST, otp };
  }
  function success(otp: any) {
    return { type: payablesConstants.GET_TRANSACTIONEXIST_SUCCESS, otp };
  }
  function failure(error: any) {
    return { type: payablesConstants.GET_TRANSACTIONEXIST_FAILURE, error };
  }
}
function createPayableRequest(payable: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(payable));

    payablesService
      .createPayableRequest(payable)
      .then((response) => {
        return response.json();
      })
      .then(
        (counterpart) => {
          if (counterpart.success) {
            dispatch(success(counterpart));
            dispatch(alertActions.success('Counterpart updated successful'));
            dispatch(counterpartsActions.getUserCounterparts() as any);
          } else {
            dispatch(failure(counterpart.message));
            dispatch(alertActions.error(counterpart.message));
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(counterpart: any) {
    return { type: payablesConstants.COUNTERPART_UPDATE_REQUEST, counterpart };
  }
  function success(counterpart: any) {
    return { type: payablesConstants.COUNTERPART_UPDATE_SUCCESS, counterpart };
  }
  function failure(error: any) {
    return { type: payablesConstants.COUNTERPART_UPDATE_FAILURE, error };
  }
}

function create(payables: any) {
  console.log('Payables: ', payables);
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(payables));

    payablesService
      .create(payables)
      .then((response) => {
        return response.json();
      })
      .then(
        (payables) => {
          if (payables.success) {
            dispatch(success(payables));
            dispatch(alertActions.success('New payable created successful'));
            toast.success(payables.message);
            window.location.reload();
          } else {
            dispatch(failure(payables.message));
            dispatch(alertActions.error(payables.message));
            toast.error(payables.message);
            window.location.reload();
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(payables: any) {
    return { type: payablesConstants.PAYABLES_REQUEST, payables };
  }
  function success(payables: any) {
    return { type: payablesConstants.PAYABLES_SUCCESS, payables };
  }
  function failure(error: any) {
    return { type: payablesConstants.PAYABLES_FAILURE, error };
  }
}
function recurring_create_bill(payables: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(payables));

    payablesService
      .recurring_create_bill(payables)
      .then((response) => {
        return response.json();
      })
      .then(
        (payables) => {
          if (payables.success) {
            dispatch(success(payables));
            dispatch(alertActions.success('New payable created successful'));
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          } else {
            dispatch(failure(payables.message));
            dispatch(alertActions.error(payables.message));
            toast.error(payables.message);
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(payables: any) {
    return {
      type: payablesConstants.PAYABLES_RECURRING_BILL_REQUEST,
      payables,
    };
  }
  function success(payables: any) {
    return {
      type: payablesConstants.PAYABLES_RECURRING_BILL_SUCCESS,
      payables,
    };
  }
  function failure(error: any) {
    return { type: payablesConstants.PAYABLES_RECURRING_BILL_FAILURE, error };
  }
}
function recurring_submit_bill(payables: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(payables));

    payablesService
      .recurring_submit_bill(payables)
      .then((response) => {
        return response.json();
      })
      .then(
        (payables) => {
          if (payables.success) {
            dispatch(success(payables));
            dispatch(alertActions.success('New payable created successful'));
            setTimeout(() => {
              window.location.reload();
            }, 8000);
          } else {
            dispatch(failure(payables.message));
            dispatch(alertActions.error(payables.message));
            toast.error(payables.message);
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(payables: any) {
    return {
      type: payablesConstants.PAYABLES_RECURRING_SUBMIT_BILL_REQUEST,
      payables,
    };
  }
  function success(payables: any) {
    return {
      type: payablesConstants.PAYABLES_RECURRING_SUBMIT_BILL_SUCCESS,
      payables,
    };
  }
  function failure(error: any) {
    return {
      type: payablesConstants.PAYABLES_RECURRING_SUBMIT_BILL_FAILURE,
      error,
    };
  }
}
function initiatePayment(payables: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(payables));

    payablesService
      .initiatePayment(payables)
      .then((response) => {
        dispatch(alertActions.clear());
        return response.json();
      })
      .then(
        (payables) => {
          if (payables.success) {
            dispatch(success(payables));
            dispatch(alertActions.success('Payment initailed'));
          } else {
            dispatch(failure(payables.message));
            dispatch(alertActions.error(payables.message));
            toast.error(payables.message);
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
  function request(payables: any) {
    return { type: payablesConstants.INITIATE_PAYMENT_REQUEST, payables };
  }
  function success(payables: any) {
    return { type: payablesConstants.INITIATE_PAYMENT_SUCCESS, payables };
  }
  function failure(error: any) {
    return { type: payablesConstants.INITIATE_PAYMENT_FAILURE, error };
  }
}
function initiate_bulk_Payment(payables: any,setDisplaydata:any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(payables));

    payablesService
      .initiate_bulk_Payment(payables)
      .then((response) => {
        dispatch(alertActions.clear());
        return response.json();
      })
      .then(
        (payables) => {
          if (payables.success) {
            dispatch(success(payables));
            dispatch(alertActions.success('Payment initailed'));
            setDisplaydata(true)
          } else {
            dispatch(failure(payables.message));
            dispatch(alertActions.error(payables.message));
            toast.error(payables.message);
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(payables: any) {
    return { type: payablesConstants.INITIATE_BULK_PAYMENT_REQUEST, payables };
  }
  function success(payables: any) {
    return { type: payablesConstants.INITIATE_BULK_PAYMENT_SUCCESS, payables };
  }
  function failure(error: any) {
    return { type: payablesConstants.INITIATE_BULK_PAYMENT_FAILURE, error };
  }
}
function getTransaction(req: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(req));

    payablesService
      .getTransaction(req)
      .then((response) => {
        console.log('initiatePayment response: ', response);
        return response.json();
      })
      .then(
        (transaction) => {
          if (transaction.success) {
            dispatch(success(transaction));
            dispatch(alertActions.success('Transactions Success'));
          } else {
            dispatch(failure(transaction.message));
            dispatch(alertActions.error(transaction.message));
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(payables: any) {
    return { type: payablesConstants.TRANSACTION_REQUEST, payables };
  }
  function success(payables: any) {
    return { type: payablesConstants.TRANSACTION_SUCCESS, payables };
  }
  function failure(error: any) {
    return { type: payablesConstants.TRANSACTION_FAILURE, error };
  }
}
function Transaction_bulk_Payment(payables: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(payables));

    payablesService
      .Transaction_bulk_Payment(payables)
      .then((response) => {
        dispatch(alertActions.clear());
        return response.json();
      })
      .then(
        (payables) => {
          if (payables.success) {
            dispatch(success(payables));
            dispatch(alertActions.success('Payment initailed'));
          } else {
            dispatch(failure(payables.message));
            dispatch(alertActions.error(payables.message));
            toast.error(payables.message);
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(payables: any) {
    return {
      type: payablesConstants.TRANSACTION_BULK_PAYMENT_REQUEST,
      payables,
    };
  }
  function success(payables: any) {
    return {
      type: payablesConstants.TRANSACTION_BULK_PAYMENT_SUCCESS,
      payables,
    };
  }
  function failure(error: any) {
    return { type: payablesConstants.TRANSACTION_BULK_PAYMENT_FAILURE, error };
  }
}
function getPayable_id(data: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    payablesService
      .getPayable_id_service(data)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          dispatch(success(response.payable));
          console.log(response, 'sadadaddddd');
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: payablesConstants.GETPAYABLES_ID_REQUEST };
  }
  function success(data: any) {
    return { type: payablesConstants.GETPAYABLES_ID_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: payablesConstants.GETPAYABLES_ID_FAILURE };
  }
}
function getOtpverifay() {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    payablesService
      .getOtpverifay()
      .then((response) => {
        return response.json();
      })
      .then(
        (transaction) => {
          if (transaction.success) {
            dispatch(success(transaction));
            dispatch(alertActions.success('Transactions Success'));
          } else {
            dispatch(failure(transaction.message));
            dispatch(alertActions.error(transaction.message));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(payables: any) {
    return { type: payablesConstants.OTPVIREFAT_REQUEST, payables };
  }
  function success(payables: any) {
    return { type: payablesConstants.OTPVIREFAT_SUCCESS, payables };
  }
  function failure(error: any) {
    return { type: payablesConstants.OTPVIREFAT_FAILURE, error };
  }
}
function setSelectedItem(payable: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payable?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    if (payable) {
      dispatch(
        success({ type: payablesConstants.GETPAYABLES_SUCCESS, undefined })
      );
      dispatch(success(payable));
    } else {
      dispatch(failure('not found!'));
    }
  };

  function request() {
    return { type: payablesConstants.GETSELECTEDPAYABLES_REQUEST };
  }
  function success(data: any) {
    return { type: payablesConstants.GETSELECTEDPAYABLES_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: payablesConstants.GETSELECTEDPAYABLES_FAILURE };
  }
}
function createPay(payables: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(payables));

    payablesService
      .create(payables)
      .then((response) => {
        return response.json();
      })
      .then(
        (payables) => {
          if (payables.success) {
            dispatch(success(payables));
            dispatch(alertActions.success('New payable created successful'));
          } else {
            dispatch(failure(payables.message));
            dispatch(alertActions.error(payables.message));
            toast.error(payables.message);
            window.location.reload();
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(payables: any) {
    return { type: payablesConstants.PAYABLES_REQUEST, payables };
  }
  function success(payables: any) {
    return { type: payablesConstants.PAYABLES_SUCCESS, payables };
  }
  function failure(error: any) {
    return { type: payablesConstants.PAYABLES_FAILURE, error };
  }
}
function bulkpayables(payables: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(payables));

    payablesService
      .BulkPayables(payables)
      .then((response) => {
        return response.json();
      })
      .then(
        (payables) => {
          if (payables.success) {
            dispatch(success(payables));
            dispatch(alertActions.success('New payable created successful'));
            toast.success(payables.message);
          } else {
            dispatch(failure(payables.message));
            dispatch(alertActions.error(payables.message));
            toast.error(payables.message);
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(payables: any) {
    return { type: payablesConstants.BULKPAYABLES_REQUEST, payables };
  }
  function success(payables: any) {
    return { type: payablesConstants.BULKPAYABLES_SUCCESS, payables };
  }
  function failure(error: any) {
    return { type: payablesConstants.BULKPAYABLES_FAILURE, error };
  }
}
function bulkbills(payables: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(payables));

    payablesService
      .bulkbills(payables)
      .then((response) => {
        return response.json();
      })
      .then(
        (payables) => {
          if (payables.success) {
            dispatch(success(payables));
            dispatch(alertActions.success('New payable created successful'));
            toast.success(payables.message);
            setTimeout(() => {
              window.location.href = '/Counterparts';
            }, 5000);
          } else {
            dispatch(failure(payables.message));
            dispatch(alertActions.error(payables.message));
            toast.error(payables.message);
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(payables: any) {
    return { type: payablesConstants.BULKBILLS_REQUEST, payables };
  }
  function success(payables: any) {
    return { type: payablesConstants.BULKBILLS_SUCCESS, payables };
  }
  function failure(error: any) {
    return { type: payablesConstants.BULKBILLS_FAILURE, error };
  }
}
function getAllPayables(data: any, setCounterpartsListRender_two?: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    if (typeof setCounterpartsListRender_two == 'function') {
      setCounterpartsListRender_two(true);
    }
    payablesService
      .getAllPayables(data)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          console.log(response, 'is_____datatatatatat');
          if (typeof setCounterpartsListRender_two == 'function') {
            setCounterpartsListRender_two(false);
          }
          if (response.success) {
            const updated = response.data.data.map(
              (item: any, index: number) => {
                item.amount = item.amount / 100;
                return item;
              }
            );
            dispatch(success(updated));
            dispatch(success_new(response.data));
          } else {
            const dataEmpty: any = [];
            dispatch(success(dataEmpty));
            dispatch(success_new(response.data));

            dispatch(failure('Payable not found!'));
            dispatch(alertActions.error('Payable not found!'));
          }
        },
        (error) => {
          if (typeof setCounterpartsListRender_two == 'function') {
            setCounterpartsListRender_two(false);
          }
          console.log(error, 'is+++++++++++++++error');
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: payablesConstants.GETPAYABLES_REQUEST };
  }
  function success(data: any) {
    return { type: payablesConstants.GETPAYABLES_SUCCESS, data };
  }
  function success_new(data: any) {
    return { type: payablesConstants.GETPAYABLES_NEW_SUCCESS, data };
  }

  function failure(s: string) {
    return { type: payablesConstants.GETPAYABLES_FAILURE, s };
  }
}
