import { Box, Grid, Typography } from "@mui/material";
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useSelector, useDispatch, TypedUseSelectorHook } from 'react-redux';
import { userActions } from '../../../_actions';
import { useState, useEffect } from 'react';
import { registerSelector } from '../../../_reducers/registration.reducer';
import { dispatchStore } from '../../../Store/store';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const NewVerifyEmail = (props: any) => {
    const [otp, setOtp] = useState<any>('');
    const dispatch = useDispatch();
    const [transRender, setTransRender] = useState(false);
    const [requestRender, setrequestRender] = useState(false);
    const [responseRender, setresponseRender] = useState(false);
    const [otpFailed, setotpFailed] = useState(false);
    const [loading, setLoading] = useState(false);

    const registerData = useSelector((state) => registerSelector(state));
    const location = useLocation();
console.log(registerData.LOGIN_OTP_VERIFY_SUCCESS,registerData,loading,otpFailed,"registerData151");

    const handleSubmit = (e: any) => {
        const { name, value }: any = e.target;
        setOtp({ ...otp, [name]: value });
    };
    const onSubmitted = () => {
        let request = {
            ...otp,
            type: props.type,
            email:props.email
        };
        dispatch(userActions.loginOtpVerify(request,props.path) as any);
        setLoading(true);
        setTimeout(() => {
            setTransRender(true)
        }, 4000)
    };
console.log(props?.type,"props?.loginType");

    useEffect(() => {
        if (
            transRender &&
            registerData?.LOGIN_OTP_VERIFY_SUCCESS !== undefined
        ) {
            setTransRender(false);
            setLoading(false)
            // const { from } = location.state || { from: { pathname: '/' } };
            // dispatchStore(
            //     userActions.login({ email: props.email, password: props.password }, from) as any
            // );
            //   setPaymentInitiated(true)
            //   setAuthoriseTransaction(false);
        }
    }, [registerData, transRender]);
    // useEffect(() => {
    //     if (requestRender && registerData?.LOGIN_OTP_VERIFY_REQUEST == true) {
    //         // setLoading(registerData?.LOGIN_OTP_VERIFY_REQUEST);
    //         setresponseRender(true);
    //         setrequestRender(false);
    //         setTransRender(true);
    //         setotpFailed(true);
    //     }
    // }, [registerData, requestRender]);

    // useEffect(() => {
    //     if (responseRender && registerData?.LOGIN_OTP_VERIFY_REQUEST == false) {
           
    //         setrequestRender(true);
    //         setresponseRender(false);
    //     }
    // }, [registerData, responseRender]);
console.log( registerData?.LOGIN_OTP_VERIFY_FAILURE," registerData?.LOGIN_OTP_VERIFY_FAILURE");

    useEffect(() => {
        if (
            transRender &&
            registerData?.LOGIN_OTP_VERIFY_FAILURE !== undefined
        ) {
            setTransRender(false);
            setLoading(false)
            //   setAuthoriseTransaction(true);
        }
    }, [registerData, transRender]);
    return (
        <>
          {/* {loading &&
               <div
               style={{
                 display: 'flex',
                 position: 'absolute',
                 top: 150,
                 bottom: 0,
                 right: 0,
                 left: 0,
                 justifyContent: 'center',
                 alignItems: 'center',
                 zIndex: 10,
               }}
             >
                            <img width={75} height={75} src="/Niobi Loading Animation-Cross.gif" />
                </div>} */}
            <Grid container>
                <Grid item md={12} sm={12} xs={12} className="grid_verifyemail">
                    <div className="auth-trans-container" style={{ width: "80%", boxShadow: "none", padding: "0px", margin: "0px" }}>
                        <div className="auth-trans-container__heading">OTP Verification</div>
                        <div className="auth-trans-container__desc">
                            {props?.setp == 1 ? "Please enter the 6-digit verification code sent to your phone" :
                                props?.setp == 2 ? "Please enter the 6-digit verification code sent to your email" : ""}
                        </div>

                        <div>
                            <div className="auth-trans-container__label">Verification code</div>
                            <InputGroup className="input-wrapper input">
                                <Form.Control
                                    className="auth-trans-form-input"
                                    placeholder="123456"
                                    value={otp?.otp}
                                    onChange={handleSubmit}
                                    name="otp"
                                    aria-describedby="basic-addon3"
                                />
                            </InputGroup>
                        </div>
                        <div
                            id="div_authoridse_cls"
                            style={{ justifyContent: 'space-between', alignItems: 'center' }}
                            className="auth-trans-container__btn-row"
                        >
                            <Button
                                className="auth-trans-container__btn-row__green"
                                style={{ width: "100%" }}
                                onClick={() => {
                                    // onSave();
                                    onSubmitted();
                                }}
                            >
                                {loading == true && (
                                    <span className="spinner-border spinner-border-sm mr-1"></span>
                                )}
                                Log In
                            </Button>

                        </div>
                        <div className="resend_conatenar">
                            <Button className="resend_button" onClick={()=>{props.handleSubmit_resend(props?.type)}}>Resend code</Button>
                        </div>
                        <div className="resend_conatenar">
                            <Button className="login_email_and_phone" onClick={() => {
                                if (props.setp == 1) {
                                    props.setStep(2)
                                    props.setLoginType("email")
                                    props.handleSubmit_resend("email")
                                } else if (props.setp == 2) {
                                    props.setStep(1)
                                    props.setLoginType("mobile")
                                    props.handleSubmit_resend("mobile")
                                }
                                
                            }}>
                                {props?.setp == 1 ? "Login using Email instead" : props?.setp == 2 ? "Verify using SMS instead" : ""}</Button>
                        </div>

                    </div>
                </Grid>
            </Grid>

        </>
    )
}
export default NewVerifyEmail;