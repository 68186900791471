import { Box, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Button } from 'react-bootstrap';
import styles from './invoice.module.scss';
import Invoice_tebal from './components/invoice_tebal';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
const PaymentInvoice = () => {
    const [id, setId] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const theme = createTheme({
        status: {
          danger: "#025041",
        },
      });
    useEffect(() => {
        const id: any = searchParams.get('id');
        setId(id)
    }, [searchParams]);
    return (
        <>
            <ThemeProvider theme={theme}>
                <>
                <Box className={styles.ContenarInvoice}>
                    <Box>
                        <Typography className={styles.typo_heding}>Connect Payment with Invoice</Typography>
                    </Box>
                    <Box>
                        {/* <Button
                        style={{padding:"12px 16px"}}
                            className="balance__save-btn"
                            onClick={() => {
                                navigate("/create-invoice")
                            }}
                        >
                            Create new
                        </Button> */}
                    </Box>
                </Box>
                <Box className={styles.ContenarTebal}>
                    <Invoice_tebal />
                </Box>
                </>
            </ThemeProvider>
        </>
    )
}
export default PaymentInvoice;